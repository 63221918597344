import React, { useContext } from "react";
import ProgramContainer from "../common/ProgramContainer";
import ProgramSlider from "../common/ProgramSlider";
import { ScheduleContext } from "@/context/ScheduleContext";
import Loader from "@/components/Loader";
import SessionContainer from "@/components/SessionCard/SessionContainer";
import ProgramIcons from "../ProgramIcons";

const OnlineProgram: React.FC = () => {
    const { onlineProgram } = useContext(ScheduleContext);

    if (onlineProgram.length === 0 || !onlineProgram) {
        return <Loader />
    }

    return (
        <div className="OnlineProgram">
            {/* opening  */}
            <SessionContainer
                title={onlineProgram[0].TimeString}
                icon={onlineProgram[0].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="start"/>}
                streamingStatus={onlineProgram[0].streamingStatus}>
                    <ProgramContainer
                        data={onlineProgram[0]}
                        speakersTitle="Ведущие"
                    />
            </SessionContainer>

            {/* discussion  */}
            <SessionContainer
                title={onlineProgram[1].TimeString}
                icon={onlineProgram[1].streamingStatus === 'active' && <ProgramIcons type="liveStream"/>}
                streamingStatus={onlineProgram[1].streamingStatus}>
                    <ProgramContainer
                        data={onlineProgram[1]}
                        mainStage
                        withLink
                    />
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer
                title={onlineProgram[2].TimeString}
                icon={onlineProgram[2].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="break"/>}
                streamingStatus={onlineProgram[2].streamingStatus}>
                    <ProgramContainer
                        data={onlineProgram[2]}
                        cardColor="gray"
                        speakersTitle="Ведущие"
                    />
            </SessionContainer>

            {/* multiple_sessions 01  */}
            <SessionContainer
                title={onlineProgram[3].TimeString}
                icon={onlineProgram[3].streamingStatus === 'active' && <ProgramIcons type="liveStream"/>}
                streamingStatus={onlineProgram[3].streamingStatus}>
                    <div className="OnCampusProgram__Multiple_sessions">
                        <ProgramSlider swiperId="online_multiple_sessions_01" data={[onlineProgram[3], onlineProgram[4], onlineProgram[5]]} />
                    </div>
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer
                title={onlineProgram[6].TimeString}
                icon={onlineProgram[6].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="break"/>}
                streamingStatus={onlineProgram[6].streamingStatus}>
                    <ProgramContainer
                        data={onlineProgram[6]}
                        cardColor="gray"
                        speakersTitle="Ведущие"
                    />
            </SessionContainer>

            {/* multiple_sessions 02  */}
            <SessionContainer
                title={onlineProgram[7].TimeString}
                icon={onlineProgram[7].streamingStatus === 'active' && <ProgramIcons type="liveStream"/>}
                streamingStatus={onlineProgram[7].streamingStatus}>
                    <div className="OnCampusProgram__Multiple_sessions">
                        <ProgramSlider swiperId="online_multiple_sessions_02" data={[onlineProgram[7], onlineProgram[8], onlineProgram[9]]} />
                    </div>
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer
                title={onlineProgram[10].TimeString}
                icon={onlineProgram[10].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="break"/>}
                streamingStatus={onlineProgram[10].streamingStatus}>
                    <ProgramContainer
                        data={onlineProgram[10]}
                        cardColor="gray"
                        speakersTitle="Ведущие"
                    />
            </SessionContainer>

            {/* multiple_sessions 03  */}
            <SessionContainer
                title={onlineProgram[11].TimeString}
                icon={onlineProgram[11].streamingStatus === 'active' && <ProgramIcons type="liveStream"/>}
                streamingStatus={onlineProgram[11].streamingStatus}>
                    <div className="OnCampusProgram__Multiple_sessions">
                        <ProgramSlider swiperId="online_multiple_sessions_03" data={[onlineProgram[11], onlineProgram[12], onlineProgram[13]]} />
                    </div>
            </SessionContainer>

            {/* finish  */}
            <SessionContainer
                title={onlineProgram[14].TimeString}
                icon={onlineProgram[14].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="finish"/>}
                streamingStatus={onlineProgram[14].streamingStatus}>
                    <ProgramContainer
                        data={onlineProgram[14]}
                        bgColor="white"
                        cardColor="none"
                        speakersTitle="Ведущие"
                    />
            </SessionContainer>
        </div>
    )
}

export default OnlineProgram;