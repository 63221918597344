import React from "react";
import Popup from "components/Popup";
import { useToggle } from "react-use";
import SessionCard from "components/SessionCard";
import { SessionDataType } from "types/interfaces";
import useMedia from "hooks/useMedia";
import "./index.scss";

type Props = {
    data: SessionDataType;
    children?: React.ReactNode;
    className?: string;
    mainStage?: boolean;
    vertical?: boolean;
    withLink?: boolean;
    gradientSubtitle?: boolean;
    speakersTitle?: string;
    bgColor?: "white" | "green";
    cardColor?: "gray" | "green" | "none";
};

const ProgramContainer: React.FC<Props> = ({
    data,
    className,
    mainStage,
    withLink,
    vertical,
    gradientSubtitle,
    speakersTitle,
    children,
    bgColor = "white",
    cardColor = "green",
}) => {
    const [isActive, toggle] = useToggle(false);
    const { isMobile } = useMedia();

    return (
        <>
            <SessionCard
                className={className}
                data={data}
                mainStage={mainStage}
                withLink={withLink}
                vertical={vertical}
                togglePopup={() => toggle()}
                showSpeakers={!isMobile}
                gradientSubtitle={gradientSubtitle}
                speakersTitle={speakersTitle}
                bgColor={bgColor}
                cardColor={cardColor}
            />
            <Popup
                className="ProgramContainer__PopupBox"
                closePopup={toggle}
                isPopupActive={isActive}
                position={isMobile ? "bottom" : "right"}
            >
                <div className="ProgramContainer__Wrapper">
                    <SessionCard
                        className="ProgramContainer__PopupContent"
                        data={data}
                        mainStage={mainStage}
                        avatarSize="big"
                        cardColor="none"
                        showSpeakerdescription
                        isShowTime
                    >
                        {data.sessionDescription && (
                            <div className="ProgramContainer__PopupText">
                                    <div
                                        className="ProgramContainer__PopupTextMain"
                                        dangerouslySetInnerHTML={{
                                            __html: data.sessionDescription,
                                        }}
                                    />
                                {/* disabled */}
                                {/* <p className="ProgramContainer__Reference">
                                    {data?.sessionReference}
                                    </p> */}
                                {children}
                            </div>
                        )}
                    </SessionCard>
                </div>
            </Popup>
        </>
    );
};

export default ProgramContainer;
