import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconGoOut } from "./assets/go-out.svg";
import { routesNavigationProfile } from "@/constants/navigations";
import { useSignOut } from "react-auth-kit";
import Typography from "@/components/Typography";
import "./index.scss";

const ProfileNav: React.FC = () => {
    const signOut = useSignOut();
    const handleSignOut = () => {
        signOut();
    };

    const renderNav = routesNavigationProfile.map((link) => {
        return (
            <li key={link.id} className="ProfileNav__Item">
                <NavLink
                    className="ProfileNav__Link"
                    to={link.route}
                    end
                >
                    <link.icon className="ProfileNav__Linkcon" />
                    <Typography component="span" size="sm" weight="semiBold" color="inherit">
                        {link.label}
                    </Typography>
                </NavLink>
            </li>
        );
    });

    return (
        <ul className="ProfileNav">
            {renderNav}
            <li className="ProfileNav__Item ProfileNav__Item--Logout">
                <button className="ProfileNav__Link ProfileNav__Link--Logout" onClick={handleSignOut}>
                    <IconGoOut className="ProfileNav__LinkIcon" />
                    <Typography component="span" size="sm" weight="semiBold" color="inherit">
                        Выйти
                    </Typography>
                </button>
            </li>
        </ul>
    );
};

export default ProfileNav;
