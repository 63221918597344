import React from "react";
import { NavLink } from "react-router-dom";
import { routesNavigationProfile } from "@/constants/navigations";
import "./index.scss";

const TabsNavigation: React.FC = () => {
    const renderElements = routesNavigationProfile.map((link) => {
        return (
            <li className="TabsNavigation__Item" key={link.id}>
                <NavLink
                    className="TabsNavigation__Link"
                    to={link.route}
                    end
                >
                    {link.labelDeviceTouch}
                </NavLink>
            </li>
        );
    });

    return (
        <nav className="TabsNavigation">
            <ul className="TabsNavigation__List">
                {renderElements}
                <li className="TabsNavigationItem">
                    <button
                        className={
                            "TabsNavigation__Link TabsNavigation__Link--Logout"
                        }
                    >
                        Выйти
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default TabsNavigation;
