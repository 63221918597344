import React, { useEffect, useState } from "react";
import { SelectInput } from "@/components/RegistrationSelect";
import transformObjectsList from "@/utils/transformObjectsList";
import { getCountries } from "@/api";

const keyMapping = {
    id: "value",
    title: "label",
};

type Props = {
    className?: string;
    name?: string;
    id?: string;
    label?: string;
    value?: any;
    placeholder?: string;
    error?: boolean;
    onSelect?: (countryId: any) => void;
    onBlur: () => void;
};

const InputSelectCountries: React.FC<Props> = ({
    className,
    name,
    id,
    label,
    value,
    placeholder,
    onSelect,
    onBlur,
    error,
}) => {
    const [countriesData, setCountriesData] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        const fetchCountriesData = async () => {
            try {
                const response = await getCountries();
                const countries = response.data.data;

                const formattedList = transformObjectsList(
                    countries,
                    keyMapping,
                );

                // id = 1 => россия
                const defaultOption = formattedList.filter(
                    (el: any) => el.value === 1,
                );
                const withoutDefault = formattedList.filter(
                    (el: any) => el.value !== 1,
                );
                const readyList = [...defaultOption, ...withoutDefault];

                setCountriesData(readyList);
            } catch (error) {
                console.error(error);
                setCountriesData([]);
            }
        };

        fetchCountriesData();
    }, []);

    const handleChange = (option: any) => {
        setSelectedValue(option);
        onSelect?.(option.value);
    };

    useEffect(() => {
        const newSelectedValue = countriesData.find(
            (item) => item.value === value,
        );
        if (newSelectedValue) {
            setSelectedValue(newSelectedValue);
            onSelect?.(value);
        }
    }, [value, countriesData]);

    return (
        <SelectInput
            className={className}
            id={id}
            name={name}
            placeholder={placeholder}
            label={label}
            value={selectedValue}
            isError={error}
            options={countriesData}
            onChange={handleChange}
            onBlur={onBlur}
        />
    );
};

export default InputSelectCountries;
