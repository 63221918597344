import React from "react";
import { Outlet } from "react-router-dom";
import Container from "@/components/Container";
import ProfileNav from "@/components/ProfileNav";
import TabsNavigation from "@/components/TabsNavigation";
import useMedia from "@/hooks/useMedia";
import "./index.scss";

const ProfileLayout: React.FC = () => {
    const { isDesktop } = useMedia();

    return (
        <div className="ProfileLayout">
            <Container className="ProfileLayout__Container">
                {isDesktop ? (
                    <ProfileNav />
                ):(
                    <TabsNavigation />
                )}
                <div className="ProfileLayout__Content">
                    <Outlet />
                </div>
            </Container>
        </div>
    );
};

export default ProfileLayout;
