import { clientApi } from "./client";
import { CancelToken } from "axios";
import { UserData } from "@/types/interfaces";
import { RegistrationData } from "@/types/registration";
import { generatePath } from "react-router";
import { objectToFormData } from "@/utils/objectToFormData";
import { ChatType } from "@/types/chatTypes";

export const startRegistrationNotify = ({ email }: {email: string}) => {
    return clientApi.post("/start-registration-notification", {
        email,
        personal_data_processing_agreement: true,
    });
};

export const getExperts = async (params: {page: number}) => {
    return clientApi.get('/experts', { params }).then((res) => res.data);
}

export const getMethodologists = (params: any) => {
    return clientApi.get("/methodologists", { params });
};

export const getFaq = () => {
    return clientApi.get("/faq");
};

export const getProgrammPDF = () => {
    return clientApi.get("/program-pdf");
};

export const getCountries = () => {
    return clientApi.get("/countries");
};

export const searchCities = (country_id: string, searchInput: string, cancelToken: CancelToken) => {
    return clientApi.get(
        generatePath("/cities/search/:country_id/:searchInput", {
            country_id,
            searchInput,
        }),
        {
            cancelToken,
        },
    );
};

export const signUp = async (data: Omit<RegistrationData, "activity">) => {
    return clientApi.post<ApiResponseWithToken<UserData>>(`/auth/register`, data).then((resp) => resp.data);
};

export const logIn = (data: any) => {
    return clientApi.post(`/auth/login`, data);
};

export const recoverPasswordByEmail = (data: { email: string }) => {
    return clientApi.post(`/auth/send-password-recovery-email`, data);
};

export const changePassword = (data: any) => {
    return clientApi.patch(`/auth/change-password`, data);
};

export const validatePasswordRecoveryToken = (params: { recovery_token: string; password?: string }) => {
    return clientApi.get(`/auth/validate-password-recovery-token`, { params });
};

export const checkPromoCode = (params: { code: string; tariff: string }) => {
    return clientApi.get(`/auth/check-promo-code`, { params });
};

export const makePayment = () => {
    return clientApi.get<
        ApiResponse<{
            acquiring_form_url: string;
            amount: number;
            status: string;
            message: null | string;
        }>
    >(`/payment/create`, {
        // для локаьного тестирования
        params: window.location.hostname === "localhost" ? { local: 1 } : undefined,
    });
};

export const userInformations = async () => {
    return clientApi.get<ApiResponseWithToken<UserData>>(`/user`).then((resp) => resp.data.data);
};

export const updateUserInformations = async (data: {
    name: string;
    surname: string;
    email: string;
    country_id: number | string;
    city: string;
    place_of_study?: string | null;
    speciality?: string | null;
    place_of_work?: string | null;
    position?: string | null;
    avatar?: File | null;
}) => {
    const formData = objectToFormData(data);
    return clientApi.post<ApiResponseWithToken<UserData>>(`/user`, formData).then((resp) => resp.data.data);
};

export const changeUserPassword = (data: {
    old_password: string;
    new_password: string;
    new_password_confirmation: string;
}) => {
    return clientApi.post("/user/update-password", data);
};

export const checkEmail = (params: { email: string }) => {
    return clientApi.get<
        ApiResponse<{
            is_exists: boolean;
        }>
    >(`/auth/check-email`, { params });
};

export const refundRequest = (reason: string) => {
    return clientApi.post<ApiResponse<{ status: boolean }>>("/refund-request", { reason });
};

export const checkPaymentStatus = async () => {
    const resp = await clientApi.get<ApiResponse<{ status: boolean }>>("/payment/status");
    return resp.data;
};

export const downloadCertificateApi = () => {
    return clientApi.get(`/user/certificate`, {
        responseType: "blob",
    });
};
export const streams = () => {
    return clientApi.get(`/streams`);
};

export const sendStreamMessage = (data: { stream_id: number; message: string; type: ChatType }) => {
    return clientApi.post(`/stream-chat/new-message`, data);
};

export const deleteStreamMessage = (params: { stream_chat_message_id: number }) => {
    return clientApi.delete(`/stream-chat/delete-message/${params.stream_chat_message_id}`);
};

export const addlikeStreamMessage = (params: { stream_chat_message_id: number }) => {
    return clientApi.post(`/stream-chat/rate-message/${params.stream_chat_message_id}`);
};

export const removelikeStreamMessage = (params: { stream_chat_message_id: number }) => {
    return clientApi.delete(`/stream-chat/cancel-rate-message/${params.stream_chat_message_id}`);
};

export const getAllStreamMessages = (
    chatType: ChatType,
    stream_id: string,
    params: {
        time_from: string;
        time_to: string;
        by_rating: string;
    },
) => {
    return clientApi.get(
        generatePath("/stream-chat/:chatType/:stream_id", {
            chatType,
            stream_id,
        }),
        {
            params,
        },
    );
};

export const getAllQuestionsForModerators = (
    stream_id: string,
    params: {
        by_rating: string;
        type: ChatType;
    },
) => {
    return clientApi.get(
        generatePath("/stream-chat/moderation/messages-for-moderation/stream/:stream_id", {
            stream_id,
        }),
        {
            params,
        },
    );
};

export const getAllMessagesForModerators = (
    stream_id: string,
    params: {
        by_rating: string;
        type: ChatType;
    },
) => {
    return clientApi.get(
        generatePath("/stream-chat/moderation/messages-for-moderation/stream/:stream_id", {
            stream_id,
        }),
        {
            params,
        },
    );
};

export const approveMeessageApi = (params: { stream_chat_message_id: number }) => {
    return clientApi.post(`/stream-chat/moderation/approve-message/${params.stream_chat_message_id}`);
};

export const rejectMeessageApi = (params: { stream_chat_message_id: number }) => {
    return clientApi.post(`/stream-chat/moderation/reject-message/${params.stream_chat_message_id}`);
};

export const pinMeessageApi = (params: { stream_chat_message_id: number }) => {
    return clientApi.post(`/stream-chat/moderation/pin-message/${params.stream_chat_message_id}`);
};

export const unpinMeessageApi = (params: { stream_chat_message_id: number }) => {
    return clientApi.post(`/stream-chat/moderation/unpin-message/${params.stream_chat_message_id}`);
};

export const banUserFromChat = (params: { user_id: number }) => {
    return clientApi.post(`/stream-chat/moderation/ban-user/${params.user_id}`);
};

export const clearStreamMessages = (params: { stream_id: number; message_type: ChatType }) => {
    return clientApi.post(`/stream-chat/moderation/stream/${params.stream_id}/clear-messages`, {
        message_type: params.message_type,
    });
};
