import { useState } from "react";

const useStepper = (
    chain_step: any[],
    options?: {
        onNext?: () => void;
        onPrev?: () => void;
    },
) => {
    const [currStep, setCurrStep] = useState(chain_step[0]);

    const nextStep = () => {
        const currStepIndex = chain_step.indexOf(currStep);
        if (currStepIndex >= 0 && currStepIndex < chain_step.length - 1) {
            setCurrStep(chain_step[currStepIndex + 1]);
            options?.onNext?.();
        }
    };

    const prevStep = () => {
        const currStepIndex = chain_step.indexOf(currStep);
        if (currStepIndex > 0 && currStepIndex < chain_step.length) {
            setCurrStep(chain_step[currStepIndex - 1]);
            options?.onPrev?.();
        }
    };

    return { currStep, nextStep, prevStep };
};

export default useStepper;
