import React from "react";
import Headline from "components/Headline";
import "./index.scss";

{/* disabled in stage #4 */}
// import Button from "components/Button";
// import classNames from "classnames";
// import { ReactComponent as DownloadIcon } from "./assets/download-icon.svg";
// import { CONF_PROGRAM_PDF } from "@/constants/links";

type Props = {
    toggleProgram: (program: "online" | "onCampus") => void;
    programFormat: "online" | "onCampus";
};

const ProgramHeading: React.FC<Props> = ({ toggleProgram, programFormat }) => {
    // const isOnline = programFormat === "online";
    // const isOnCampus = programFormat === "onCampus";

    return (
        <div className="ProgramHeading">
            {/* disabled in stage #4 */}
            {/* <div className="ProgramHeading__Btns">
                <Button
                    className={classNames("ProgramHeading__Btn", {
                        "ProgramHeading__Btn--not-active": !isOnCampus,
                    })}
                    tag="button"
                    text="Очно"
                    BtnType="button"
                    onClick={() => toggleProgram("onCampus")}
                    gradient
                />
                <Button
                    className={classNames("ProgramHeading__Btn", {
                        "ProgramHeading__Btn--not-active": !isOnline,
                    })}
                    tag="button"
                    text="Онлайн"
                    BtnType="button"
                    onClick={() => toggleProgram("online")}
                    gradient
                />
            </div> */}
            <Headline level="h2" size="xlg" theme="light" isCentered>
                Конференция в записи
            </Headline>
            {/* disabled in stage #4 */}
            {/* <a
                className="ProgramHeading__Download"
                href={CONF_PROGRAM_PDF}
                target="_blank"
                rel="noreferrer">
                    Скачать PDF
                    <DownloadIcon />
            </a> */}
        </div>
    );
};

export default ProgramHeading;
