import React from "react";
import ShareItem from "./ShareItem";
import Container from "@/components/Container";
import { ReactComponent as VK } from "./assets/vk.svg";
import { ReactComponent as VKMobile } from "./assets/vk-mobile.svg";
import { ReactComponent as TG } from "./assets/tg.svg";
import { ReactComponent as TGMobile } from "./assets/tg-mobile.svg";
import { ReactComponent as Copy } from "./assets/copy.svg";
import { ReactComponent as CopyMobile } from "./assets/copy-mobile.svg";
import useMedia from "@/hooks/useMedia";
import Headline from "@/components/Headline";
import useShare from "@/hooks/useShare";
import "./index.scss";

const ShareBlock: React.FC = () => {
    const { isDesktop } = useMedia();
    const { copyURL, shareTG, shareVK } = useShare();

    const ShareData = [
        {
            icon: isDesktop ? <VK /> : <VKMobile />,
            action: shareVK,
        },
        {
            icon: isDesktop ? <TG /> : <TGMobile />,
            action: shareTG,
        },
        {
            icon: isDesktop ? <Copy /> : <CopyMobile />,
            action: copyURL,
        },
    ];

    const renderElements = ShareData.map(({ icon, action }, index) => (
        <ShareItem key={index} icon={icon} onClick={action} />
    ));

    return (
        <section className="ShareBlock">
            <Headline level="h2" size="xlg" theme="light" isCentered>
                Поделиться
            </Headline>
            <Container>
                <div className="ShareBlock__Items">{renderElements}</div>
            </Container>
        </section>
    );
};

export default ShareBlock;
