import React from 'react';
import { useNavigate } from 'react-router-dom';
import Headline from '@/components/Headline'
import useMedia from '@/hooks/useMedia';
import { ReactComponent as CloseIcon } from './assets/ic_outlined_cross_large.svg';
import routes from '@/routes';
import './index.scss'

type Props = {
    textDesktop: string;
    textMobile?: string;
}

const TitlePageAdmin: React.FC<Props> = ({ textDesktop, textMobile }) => {
    const { isDesktop } = useMedia();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(routes.conference)
    };

    return (
        <div className='TitlePageAdmin__Wrapper'>
            <Headline
                level="h1"
                theme="dark"
                size="md">
                {isDesktop ? textDesktop : (textMobile || textDesktop)}
            </Headline>
            {!isDesktop && <CloseIcon onClick={handleClick}/>}
        </div>
    )
}

export default TitlePageAdmin