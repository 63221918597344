import { useQuery } from '@tanstack/react-query';
import { checkPaymentStatus } from "@/api";

export const useCheckPaymentStatus = () => {
    return useQuery({
        queryFn: checkPaymentStatus,
        queryKey: ['check-status'],
        select: ({ data }) => {
            return !data.status;
        },
    });
};
