import React from "react";
import Modal from "react-modal";
import { ReactComponent as LinkIcon } from "./icons/link.svg";
import { ReactComponent as CloseIcon } from "./icons/close-btn.svg";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import { Winner } from "../../types";
import "./index.scss";

interface Props {
    isOpen: boolean;
    onRequestClose: () => void;
    winner: Winner;
}

const ModalWinner: React.FC<Props> = ({ isOpen, onRequestClose, winner }) => {
    return (
        <Modal
            overlayClassName={"ModalWinner"}
            className={"ModalWinner__Container"}
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            preventScroll={false}
            shouldFocusAfterRender={false}
        >
            <div className="ModalWinner__Mask" onClick={onRequestClose}></div>
            <div className="ModalWinner__Content">
                <button className="ModalWinner_CLoseBtn" onClick={onRequestClose}>
                    <CloseIcon />
                </button>
                <div className="ModalWinner__Image">
                    <img src={winner.image} alt={winner.name} loading="lazy" />
                </div>
                <div className="ModalWinner__Name">
                    {winner.name}
                    {winner.isTop && (
                        <div className="ModalWinner__Top">
                            <Typography component="span" color="light" size="sm" weight="semiBold">1 место</Typography>
                        </div>
                    )}
                </div>
                <div className="ModalWinner__Info">{winner.description}</div>
                {winner.link && (
                    <Button
                        className="ModalWinner__Link"
                        tag="a"
                        iconRight={<LinkIcon />}
                        gradient={true}
                        size="lg"
                        target="_blank"
                        href={winner.link}
                        text="Перейти на сайт"
                    />
                )}
            </div>
        </Modal>
    );
};

export default ModalWinner;
