export const MESSAGES = {
    ERROR: `Что-то пошло не так, попробуйте ещё раз`,
    SUCCESS: `Отлично! В день старта регистрации отправим Вам напоминание на почту`,
    LINK_COPIED: `Ссылка скопирована`,
    NOT_CORRECT_EMAIL: `Заполните поле почты по примеру mail@sber.ru`,
    NO_USER: `Данные пользователя не найдены`,
} as const;


export const errors = {
    cyrilic: "Допустимы только кириллические символы и знак дефис, недопустимы иные символы, цифры, пробелы",
    email: "Неверный формат электронной почты",
    required: "Поле обязательное для заполнения",
    email_exist: "Адрес электронной почты уже зарегистрирован",
    password: "Пароль не соответствует требованиям",
    password_confirmation: "Пароли не совпадают",
    oneof: "Необходимо выбрать один из вариантов",
};
