import React from "react";
import { ReactComponent as ErrorIcon } from "./assets/error-icon.svg";
import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string;
    text: string;
};

const ErrorMessage: React.FC<Props> = ({ text, className }) => {
    return (
        <div className={classNames(className, "ErrorMessage")}>
            <ErrorIcon className="ErrorMessage__Icon"/>
            <p>{text}</p>
        </div>
    );
};

export default ErrorMessage;
