import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { createBrowserRouter, createRoutesFromChildren, useLocation, matchRoutes, useNavigationType } from "react-router-dom";

let isInited = false;

const TracingIntegrationOptions: any = {
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
}

if (process.env.REACT_APP_SENTRY_DSN) {
    isInited = true;
    Sentry.init({
        environment: process.env.REACT_APP_ENV,
        enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
        dsn: process.env.REACT_APP_SENTRY_DSN,
        tracesSampleRate: 0.3,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration(TracingIntegrationOptions),
            // Sentry.replayIntegration(),
        ],
    });
}

export const sentryCreateBrowserRouter = process.env.REACT_APP_SENTRY_DSN
    ? Sentry.wrapCreateBrowserRouter(
        createBrowserRouter,
    )
    : createBrowserRouter;

export const captureException = (e: any) => {
    isInited && Sentry.captureException(e);
};
