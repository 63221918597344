import React from "react";
import ConferenceHero from "./ConferenceHero";
import ShareBlock from "@/components/ShareBlock";
import MediaPartners from "@/components/MediaPartners";
import Faq from "@/components/Faq";
import ProgramSchedule from "@/components/ProgramSchedule";
import ConferenceIntroduction from "./ConferenceIntroduction";
import ConferencePotential from "./ConferencePotential";
import VideoArchive from "@/components/VideoArchive";
import PhotoArchive from "@/components/PhotoArchive";
import "./index.scss";

// closed in stage #2
// import ConferenceFocus from "./ConferenceFocus";
// import ConferenceQuestions from "./ConferenceQuestions";
// import ConferenceContent from "./ConferenceContent";
// import ConferenceHistory from "./ConferenceHistory";
// import ConferenceTargetedAudience from "./ConferenceTargetedAudience";
// import ConferenceFeatures from "./ConferenceFeatures";
// import ConferenceForm from "./ConferenceForm";

// closed in stage #3
// import ConferenceInfo from "./ConferenceInfo";
// import ConferenceStatistics from "./ConferenceStatistics";

// closed in stage #4
// import Broadcast from "@/pages//Broadcast";
// import ChooseTariff from "./ChooseTariff";
// import ConferenceSpeakers from "./ConferenceSpeakers";

const Conference: React.FC = () => {
    return (
        <>
            <main className="Conference">
                <ConferenceHero />
                <div className="Conference__Box">
                    {/* disabled in stage #4 */}
                {/* <Broadcast className="Conference__Broadcast" withBackwordBtn={false}/> */}
                    {/* disabled in stage #3 */}
                    {/* <div className="Conference__InnerBox">
                        <ConferenceInfo />
                        <ConferenceStatistics />
                    </div> */}
                    <ConferenceIntroduction />
                    <ConferencePotential />

                    {/* disabled in stage #2 */}
                    {/* <div className="Conference__InnerBox ">
                        <ConferenceFocus />
                        <ConferenceQuestions />
                    </div> */}
                    <div className="Conference__GradiantBox">
                        <ProgramSchedule />
                        <VideoArchive />
                        <PhotoArchive />
                        {/* disabled in stage #4 */}
                        {/* <ChooseTariff /> */}
                        {/* <ConferenceSpeakers /> */}
                    </div>
                    <Faq />
                    <MediaPartners />
                </div>
                <ShareBlock />
            </main>
        </>
    );
};

export default Conference;
