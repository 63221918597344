import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Typography from "@/components/Typography";
import "./index.scss";

export type AvatarProps = {
    className?: string;
    src?: string;
    name?: string;
    size?: number;
};

export const Avatar: React.FC<AvatarProps> = ({ className, src, name, size }) => {
    const [isErrorAvatar, setIsErrorAvatar] = useState(false);

    const initials = useMemo(() => {
        return (name ?? "").charAt(0) || "";
    }, [name]);

    useEffect(() => {
        setIsErrorAvatar(false);
    }, [src]);

    const handleErrorAvatar = () => {
        setIsErrorAvatar(true);
    };

    const isUndefined = name?.includes("undefined");

    return (
        <div className={classNames(className, "Avatar")} style={{ width: size, height: size}} title={name}>
            {src && !isErrorAvatar ? (
                <img className="Avatar__Image" src={src} alt={name} onError={handleErrorAvatar} />
            ) : (
                !isUndefined && (
                    <Typography component="span" size="md" color="light" weight="semiBold">
                        {initials}
                    </Typography>
                )
            )}
        </div>
    );
};

export default Avatar;
