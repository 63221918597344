import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { toastNotifications } from "@/components/Toastify";
import {
    ChatFilterType,
    ChatType,
    FilterType,
    NewMessageType,
    StreamDataType,
    StreamMessagesType
} from "@/types/chatTypes";

import {
    deleteStreamMessage,
    getAllStreamMessages,
    addlikeStreamMessage,
    streams,
    removelikeStreamMessage,
    sendStreamMessage,
    approveMeessageApi,
    rejectMeessageApi,
    pinMeessageApi,
    banUserFromChat,
    getAllMessagesForModerators,
    clearStreamMessages,
    unpinMeessageApi
} from "@/api";
import sortMessages from "@/utils/sortMessages";
import { UserContext } from "./UserContext";
import echo from "@/config/websocket-echo";
import { useIsAuthenticated } from "react-auth-kit";

type BroadcastContextType = {
    availableBroadcasts: StreamDataType[],
    activeBroadcast: StreamDataType | null,
    commonBroadcastMessages: StreamMessagesType[],
    forSpeakerBroadcastMessages: StreamMessagesType[],
    questionMessagesForModerators: StreamMessagesType[],
    chatMessagesForModerators: StreamMessagesType[],
    toggleBroadcast: ( streamNum: number) => void,
    deleteMessage: (stream_chat_message_id: number) => void,
    addLikeToMessage: (stream_chat_message_id: number, chatType: ChatType) => void,
    removeLikeFromMessage: (stream_chat_message_id: number, chatType: ChatType) => void,
    sendMessage: (newMessage: NewMessageType) => void,
    isFetchingMessages: boolean,
    filters: FilterType[],
    activeFilter: ChatFilterType,
    toggleFilterMessages: (filterType: ChatFilterType) => void,
    pinnedMessage: StreamMessagesType | null,
    isUserBlocked: boolean,
    streamNum: number,
    approveMessage: (stream_chat_message_id: number) => void,
    rejectMessage: (stream_chat_message_id: number) => void,
    pinMessage: (stream_chat_message_id: number) => void,
    unpinMessage: (stream_chat_message_id: number) => void,
    banUser: (user_id: number) => Promise<boolean>,
    chatType: ChatType,
    toggleChatType: (chatType: ChatType) => void
    clearAllMessages: (stream_id: number, message_type: ChatType) => void
}

const BroadcastContext = createContext<BroadcastContextType>({
    availableBroadcasts: [],
    activeBroadcast: null,
    commonBroadcastMessages: [],
    forSpeakerBroadcastMessages: [],
    questionMessagesForModerators: [],
    chatMessagesForModerators: [],
    toggleBroadcast: () => { },
    deleteMessage: () => { },
    addLikeToMessage: () => { },
    removeLikeFromMessage: () => { },
    sendMessage: () => { },
    isFetchingMessages: false,
    filters: [],
    activeFilter: "new",
    toggleFilterMessages: () => { },
    pinnedMessage: null,
    isUserBlocked: false,
    streamNum: 1,
    approveMessage: () => { },
    rejectMessage: () => { },
    pinMessage: () => { },
    unpinMessage: () => { },
    banUser: async () => { return false },
    chatType: "common",
    toggleChatType: () => { },
    clearAllMessages: () => { },
});

const BroadcastProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const authentication = useIsAuthenticated();
    const isAuth = authentication();

    const { userInfo } = useContext(UserContext);

    const [availableBroadcasts, setAvailableBroadcast] = useState<StreamDataType[]>([]);
    const [activeBroadcast, setActiveBroadcast] = useState<StreamDataType | null>(null);
    const [chatType, setChatType] = useState<ChatType>("common");
    const [streamNum, setStreamNum] = useState<number>(1);

    const [commonBroadcastMessages, setCommonBroadcastMessages] = useState<StreamMessagesType[]>([]);
    const [forSpeakerBroadcastMessages, setForSpeakerBroadcastMessages] = useState<StreamMessagesType[]>([]);
    const [questionMessagesForModerators, setQuestionMessagesForModerators] = useState<StreamMessagesType[]>([]);
    const [chatMessagesForModerators, setChatMessagesForModerators] = useState<StreamMessagesType[]>([]);

    const [isFetchingMessages, setIsFetchingMessages] = useState<boolean>(false);

    const [filters] = useState<FilterType[]>([
        {
            text: "Сначала новые",
            type: "new"
        },
        {
            text: "Сначала старые",
            type: "old"
        },
        {
            text: "Сначала популярные",
            type: "popular"
        },
    ]);

    const [activeFilter, setIsActiveFilter] = useState<ChatFilterType>("new");

    const [pinnedMessage, setPinnedMessage] = useState<StreamMessagesType | null>(null);

    const [isUserBlocked, setIsUserBlocked] = useState<boolean>(false);

    const toggleBroadcast = (streamNum: number) => {
        // const findBroadcast = availableBroadcasts.find(el => el.id === streamId);
        const findBroadcast = availableBroadcasts[streamNum - 1];
        if (findBroadcast) {
            setActiveBroadcast(findBroadcast);
            setStreamNum(streamNum);
        }
    };

    const toggleChatType = (chatType: ChatType) => {
        setChatType(chatType)
    }

    const deleteMessage = async (stream_chat_message_id: number) => {
        try {
            const params = {
                stream_chat_message_id,
            }

            const { data } = await deleteStreamMessage(params);

            if (data.success) {
                toastNotifications.success("Сообщение удалено");
            }

        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const addLikeToMessage = async (stream_chat_message_id: number, chatType: ChatType) => {
        try {
            const params = {
                stream_chat_message_id,
            }

            const res = await addlikeStreamMessage(params);

            if (res.data.success) {
                updatLikes(stream_chat_message_id, chatType);
            }

        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const removeLikeFromMessage = async (stream_chat_message_id: number, chatType: ChatType) => {
        try {
            const params = {
                stream_chat_message_id,
            }

            const res = await removelikeStreamMessage(params);

            if (res.data.success) {
                updatLikes(stream_chat_message_id, chatType);
            }

        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const updatLikes = (message_id: number, chatType: ChatType) => {
        switch (chatType) {
            case "common":
                setCommonBroadcastMessages(prev => {
                    return prev.map(el => {
                        if (el.id === message_id) {
                            return {
                                ...el,
                                has_rate_for_message: !el.has_rate_for_message,
                            }
                        } else {
                            return el;
                        }
                    })
                })
                break;
            case "for_speaker":
                setForSpeakerBroadcastMessages(prev => {
                    return prev.map(el => {
                        if (el.id === message_id) {
                            return {
                                ...el,
                                has_rate_for_message: !el.has_rate_for_message,
                            }
                        } else {
                            return el;
                        }
                    })
                })
                break;
            default:
                break;
        }
    }

    const sendMessage = async (newMessage: NewMessageType) => {
        try {
            await sendStreamMessage(newMessage);

        } catch {
            toastNotifications.error("Сообщение не отправлено. Повторите попытку позже");
        }
    }

    const toggleFilterMessages = async (filterType: ChatFilterType) => {
        switch (filterType) {
            case "old":
                { const forSpeakerToDescending: any[] = sortMessages(forSpeakerBroadcastMessages, "descending");
                const forModeratorsToDescending: any[] = sortMessages(questionMessagesForModerators, "descending");
                setForSpeakerBroadcastMessages(forSpeakerToDescending)
                setQuestionMessagesForModerators(forModeratorsToDescending)
                break; }
            case "popular":
                if (activeBroadcast) fetchMessages(activeBroadcast.id.toString(), true);
                break;
            case "new":
                { const forSpeakerToAscending = sortMessages(forSpeakerBroadcastMessages, "ascending")
                const forModeratorsToAscending: any[] = sortMessages(questionMessagesForModerators, "ascending");
                setForSpeakerBroadcastMessages(forSpeakerToAscending)
                setQuestionMessagesForModerators(forModeratorsToAscending)
                break; }
            default:
                break;
        }

        setIsActiveFilter(filterType);
    }

    const approveMessage = async (stream_chat_message_id: number) => {
        try {
            const params = {
                stream_chat_message_id,
            }

            await approveMeessageApi(params);
        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const rejectMessage = async (stream_chat_message_id: number) => {
        try {
            const params = {
                stream_chat_message_id,
            }

            await rejectMeessageApi(params);
        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const pinMessage = async (stream_chat_message_id: number) => {
        try {
            const params = {
                stream_chat_message_id,
            }

            await pinMeessageApi(params);
        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const unpinMessage = async (stream_chat_message_id: number) => {
        try {
            const params = {
                stream_chat_message_id,
            }

            await unpinMeessageApi(params);
        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const banUser = async (user_id: number): Promise<boolean> => {
        if (!userInfo || userInfo.role !== "moderator") {
            return Promise.resolve(false);
        }

        try {
            const params = {
                user_id,
            }

            const res = await banUserFromChat(params);

            if (res.status === 200) {
                return true;
            } else {
                return false;
            }

        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
            return Promise.resolve(false);
        }
    }

    const clearAllMessages = async (stream_id: number, message_type: ChatType) => {
        try {
            const params = {
                stream_id,
                message_type,
            }

            await clearStreamMessages(params)

        } catch (err: any) {
            console.error(err)
        }
    }

    // get streams list
    useEffect(() => {
        const getStreams = async () => {
            try {
                const { data } = await streams();
                setAvailableBroadcast(data.data);
                setActiveBroadcast(data.data[0]);

            } catch (err: any) {
                console.error(err.message)
            }
        }

        getStreams();
    }, []);

    // fetch messages
    const fetchMessages = async (streamId: string, byRating: boolean) => {
        try {
            setIsFetchingMessages(true);

            const by_rating = byRating ? "1" : "0";
            const withModerator = userInfo?.role === "moderator";

            const params = {
                time_from: "00:01",
                time_to: "23:59",
                by_rating,
            };

            const commonPromise = getAllStreamMessages("common", streamId, params);
            const forSpeakerPromise = getAllStreamMessages("for_speaker", streamId, params);

            let chatforModeratorsPromise;
            let questionsforModeratorsPromise;

            if (withModerator) {
                chatforModeratorsPromise = getAllMessagesForModerators(streamId, { by_rating, type: "common" });
                questionsforModeratorsPromise = getAllMessagesForModerators(streamId, { by_rating, type: "for_speaker" });
            }

            const [commonResponse, forSpeakerResponse, questionsforModeratorsResponse, chatforModeratorsResponse] = await Promise.all([
                commonPromise,
                forSpeakerPromise,
                questionsforModeratorsPromise,
                chatforModeratorsPromise,
            ])

            const findPinned = forSpeakerResponse.data.data.filter((el: any) => el.is_pinned === true && el.moderation_status === "approved");

            if (findPinned.length > 0) {
                setPinnedMessage(findPinned[findPinned.length - 1])
            } else {
                setPinnedMessage(null)
            }

            setCommonBroadcastMessages(commonResponse.data.data);
            setForSpeakerBroadcastMessages(forSpeakerResponse.data.data);

            if (questionsforModeratorsResponse) {
                setQuestionMessagesForModerators(questionsforModeratorsResponse.data.data)
            }

            if (chatforModeratorsResponse) {
                setChatMessagesForModerators(chatforModeratorsResponse.data.data)
            }

            setIsFetchingMessages(false);
        } catch (err: any) {
            console.error(err.message);
        }
    }

    // get all messages for the first time
    useEffect(() => {
        if (!activeBroadcast) return () => {};
        fetchMessages(activeBroadcast.id.toString(), false);
    }, [activeBroadcast, userInfo, chatType]);

    // new message websocket
    useEffect(() => {
        if (!activeBroadcast) return;
        const streamId = activeBroadcast.id;
        const channel = echo.channel(`new-message-stream-chat-${streamId}`);

        channel.listen(`.new-message-event-stream-${streamId}`, (data: StreamMessagesType) => {
            if (data.type === "common") {
                setCommonBroadcastMessages(prev => [...prev, data]);
                setChatMessagesForModerators(prev => [...prev, data]);
            } else if (data.type === "for_speaker") {
                setForSpeakerBroadcastMessages(prev => [...prev, data]);
                setQuestionMessagesForModerators(prev => [...prev, data]);
            }
        });

        return () => {
            channel.stopListening(`.new-message-event-stream-${streamId}`);
        };
    }, [activeBroadcast]);

    // new like websocket
    useEffect(() => {
        if (!activeBroadcast) return;
        const streamId = activeBroadcast.id;
        const channel = echo.channel(`rate-message-stream-chat-${streamId}`);

        channel.listen(`.rate-message-event-stream-${streamId}`, (data: any) => {
            const res: StreamMessagesType = data?.rated_message;
            if (!res) return;

            switch (res.type) {
                case "common":
                    setCommonBroadcastMessages(prev => {
                        return prev.map(el => {
                            if (el.id === res.id) {
                                return {
                                    ...el,
                                    rating: res.rating,
                                };
                            } else {
                                return el;
                            }
                        });
                    });
                    setChatMessagesForModerators(prev => {
                        return prev.map(el => {
                            if (el.id === res.id) {
                                return {
                                    ...el,
                                    rating: res.rating,
                                };
                            } else {
                                return el;
                            }
                        });
                    });
                    break;
                case "for_speaker":
                    setForSpeakerBroadcastMessages(prev => {
                        return prev.map(el => {
                            if (el.id === res.id) {
                                return {
                                    ...el,
                                    rating: res.rating,
                                };
                            } else {
                                return el;
                            }
                        });
                    });
                    setQuestionMessagesForModerators(prev => {
                        return prev.map(el => {
                            if (el.id === res.id) {
                                return {
                                    ...el,
                                    rating: res.rating,
                                };
                            } else {
                                return el;
                            }
                        });
                    });
                    break;
                default:
                    break;
            };
        });
        return () => {
            channel.stopListening(`.rate-message-event-stream-${streamId}`);
        };
    }, [activeBroadcast]);

    // remove like websocket
    useEffect(() => {
        if (!activeBroadcast) return;
        const streamId = activeBroadcast.id;
        const channel = echo.channel(`cancel-rate-message-stream-chat-${streamId}`);

        channel.listen(`.cancel-rate-message-event-stream-${streamId}`, (data: any) => {
            const res: StreamMessagesType = data?.canceled_rated_message;
            if (!res) return;

            switch (res.type) {
                case "common":
                    setCommonBroadcastMessages(prev => {
                        return prev.map(el => {
                            if (el.id === res.id) {
                                return {
                                    ...el,
                                    rating: res.rating,
                                };
                            } else {
                                return el;
                            }
                        });
                    });
                    setChatMessagesForModerators(prev => {
                        return prev.map(el => {
                            if (el.id === res.id) {
                                return {
                                    ...el,
                                    rating: res.rating,
                                };
                            } else {
                                return el;
                            }
                        });
                    });
                    break;
                case "for_speaker":
                    setForSpeakerBroadcastMessages(prev => {
                        return prev.map(el => {
                            if (el.id === res.id) {
                                return {
                                    ...el,
                                    rating: res.rating,
                                };
                            } else {
                                return el;
                            }
                        });
                    });
                    setQuestionMessagesForModerators(prev => {
                        return prev.map(el => {
                            if (el.id === res.id) {
                                return {
                                    ...el,
                                    rating: res.rating,
                                };
                            } else {
                                return el;
                            }
                        });
                    });
                    break;
                default:
                    break;
            }
        });

        return () => {
            channel.stopListening(`.cancel-rate-message-event-stream-${streamId}`);
        };
    }, [activeBroadcast]);

    // delete message websocket
    useEffect(() => {
        if (!activeBroadcast) return;
        const streamId = activeBroadcast.id;
        const channel = echo.channel(`delete-message-stream-chat-${streamId}`);

        channel.listen(`.delete-message-event-stream-${streamId}`, (data: any) => {
            const messageId: number = data.deleted_message_id;

            if (!messageId) return;

            setForSpeakerBroadcastMessages(prev => {
                return prev.filter(el => el.id !== messageId)
            });

            setCommonBroadcastMessages(prev => {
                return prev.filter(el => el.id !== messageId)
            });

            setChatMessagesForModerators(prev => {
                return prev.filter(el => el.id !== messageId)
            });

            setQuestionMessagesForModerators(prev => {
                return prev.filter(el => el.id !== messageId)
            });

        });

        return () => {
            channel.stopListening(`.delete-message-event-stream-${streamId}`);
        };
    }, [activeBroadcast]);

    // approve message by moderator websocket
    useEffect(() => {
        if (!activeBroadcast) return;
        const streamId = activeBroadcast.id;
        const channel = echo.channel(`approve-message-stream-chat-${streamId}`);

        channel.listen(`.approve-message-event-stream-${streamId}`, (data: any) => {
            const res: StreamMessagesType = data;

            if (!res.moderation_status) return;
            setForSpeakerBroadcastMessages(prev => {
                return prev.map(el => {
                    if (el.id === res.id) {
                        return {
                            ...el,
                            moderation_status: res.moderation_status,
                        }
                    } else {
                        return el;
                    }
                });
            });
            setCommonBroadcastMessages(prev => {
                return prev.map(el => {
                    if (el.id === res.id) {
                        return {
                            ...el,
                            moderation_status: res.moderation_status,
                        }
                    } else {
                        return el;
                    }
                });
            });
            setQuestionMessagesForModerators(prev => {
                return prev.map(el => {
                    if (el.id === res.id) {
                        return {
                            ...el,
                            moderation_status: res.moderation_status,
                        }
                    } else {
                        return el;
                    }
                });
            });
            setChatMessagesForModerators(prev => {
                return prev.map(el => {
                    if (el.id === res.id) {
                        return {
                            ...el,
                            moderation_status: res.moderation_status,
                        }
                    } else {
                        return el;
                    }
                });
            });
        });

        return () => {
            channel.stopListening(`.approve-message-event-stream-${streamId}`);
        };
    }, [activeBroadcast]);

    // reject message by moderator websocket
    useEffect(() => {
        if (!activeBroadcast || !isAuth) return;
        const streamId = activeBroadcast.id;
        const channel = echo.private(`reject-message-stream-chat-${streamId}`);
        channel.listen(`.reject-message-event-stream-${streamId}`, (data: any) => {
            const res: StreamMessagesType = data;

            if (!res.moderation_status) return;
            setForSpeakerBroadcastMessages(prev => {
                return prev.map(el => {
                    if (el.id === res.id) {
                        return {
                            ...el,
                            moderation_status: res.moderation_status
                        };
                    } else {
                        return el;
                    }
                });
            });
            setCommonBroadcastMessages(prev => {
                return prev.map(el => {
                    if (el.id === res.id) {
                        return {
                            ...el,
                            moderation_status: res.moderation_status
                        };
                    } else {
                        return el;
                    }
                });
            });
            setQuestionMessagesForModerators(prev => {
                return prev.map(el => {
                    if (el.id === res.id) {
                        return {
                            ...el,
                            moderation_status: res.moderation_status
                        };
                    } else {
                        return el;
                    }
                });
            });
            setChatMessagesForModerators(prev => {
                return prev.map(el => {
                    if (el.id === res.id) {
                        return {
                            ...el,
                            moderation_status: res.moderation_status
                        };
                    } else {
                        return el;
                    }
                });
            });
        });

        return () => {
            channel.stopListening(`.reject-message-event-stream-${streamId}`);
        };
    }, [activeBroadcast, isAuth]);

    // pin message by moderator websocket
    useEffect(() => {
        if (!activeBroadcast) return;
        const streamId = activeBroadcast.id;
        const channel = echo.channel(`pin-message-stream-chat-${streamId}`);

        channel.listen(`.pin-message-event-stream-${streamId}`, (data: any) => {
            const messageId: number = data.pinned_chat_message_id;
            const findMessage = forSpeakerBroadcastMessages.find(el => el.id === messageId);

            if (!messageId || !findMessage) return;

            setPinnedMessage(findMessage);
            setForSpeakerBroadcastMessages(prev => {
                return prev.map(el => {
                    if (el.id === messageId) {
                        return {
                            ...el,
                            is_pinned: true
                        };
                    } else {
                        return {
                            ...el,
                            is_pinned: false
                        };
                    }
                });
            });
            setQuestionMessagesForModerators(prev => {
                return prev.map(el => {
                    if (el.id === messageId) {
                        return {
                            ...el,
                            is_pinned: true
                        };
                    } else {
                        return {
                            ...el,
                            is_pinned: false
                        };
                    }
                });
            });
            setChatMessagesForModerators(prev => {
                return prev.map(el => {
                    if (el.id === messageId) {
                        return {
                            ...el,
                            is_pinned: true
                        };
                    } else {
                        return {
                            ...el,
                            is_pinned: false
                        };
                    }
                });
            });
        });

        return () => {
            channel.stopListening(`.pin-message-event-stream-${streamId}`);
        };
    }, [activeBroadcast, forSpeakerBroadcastMessages]);

    // unpin message by moderator websocket
    useEffect(() => {
        if (!activeBroadcast) return;
        const streamId = activeBroadcast.id;
        const channel = echo.channel(`unpin-message-stream-chat-${streamId}`);

        channel.listen(`.unpin-message-event-stream-${streamId}`, (data: any) => {
            const messageId: number = data.unpinned_chat_message_id;
            const findMessage = forSpeakerBroadcastMessages.find(el => el.id === messageId);

            if (!messageId || !findMessage) return;

            setPinnedMessage(null);
            setForSpeakerBroadcastMessages(prev => {
                return prev.map(el => {
                    if (el.id === messageId) {
                        return {
                            ...el,
                            is_pinned: false
                        };
                    } else {
                        return el;
                    }
                });
            });
            setQuestionMessagesForModerators(prev => {
                return prev.map(el => {
                    if (el.id === messageId) {
                        return {
                            ...el,
                            is_pinned: false
                        };
                    } else {
                        return el;
                    }
                });
            });
            setChatMessagesForModerators(prev => {
                return prev.map(el => {
                    if (el.id === messageId) {
                        return {
                            ...el,
                            is_pinned: false
                        };
                    } else {
                        return el;
                    }
                });
            });


        });

        return () => {
            channel.stopListening(`.unpin-message-event-stream-${streamId}`);
        };
    }, [activeBroadcast, forSpeakerBroadcastMessages]);

    // clear all messages by moderator websocket
    useEffect(() => {
        if (!activeBroadcast) return;
        const streamId = activeBroadcast.id;
        const channel = echo.channel(`clear-messages-stream-chat-${streamId}`);

        channel.listen(`.clear-stream-messages-event-stream-${streamId}`, (data: any) => {
            const streamId = data.id;

            if (!streamId) return;

            setPinnedMessage(null);
            fetchMessages(streamId, false);
        })
        return () => {
            channel.stopListening(`.clear-stream-messages-event-stream-${streamId}`);
        };
    }, [activeBroadcast]);

    // change stream info socket
    useEffect(() => {
        if (!availableBroadcasts) return;

        const channel = echo.channel(`change-stream-texts`);

        channel.listen(`.change-stream-texts-event`, (data: any) => {
            const streamData = data;

            if (!streamData) return;

            setAvailableBroadcast(prev => {
                return prev.map(stream => {
                    if (stream.id === streamData.id) {
                        return streamData;

                    } else {
                        return stream;
                    }
                })
            })

            setActiveBroadcast(prev => prev?.id === streamData.id ? streamData : prev)

        })
        return () => {
            channel.stopListening(`.change-stream-texts-event`);
        };
    }, [availableBroadcasts]);

    // enable-or-disable stream  socket
    useEffect(() => {
        if (!availableBroadcasts) return;
        const channel = echo.channel(`enable-or-disable-stream`);

        channel.listen(`.enable-or-disable-stream-event`, (data: any) => {
            const streamData: StreamDataType = data.stream;

            if (!streamData) return;

            setAvailableBroadcast(prev => {
                return prev.map(stream => {
                    if (stream.id === streamData.id) {
                        return {
                            ...stream,
                            is_active: streamData.is_active
                        };

                    } else {
                        return stream;
                    }
                })
            });

            setActiveBroadcast(prev => {
                if (prev?.id === streamData.id) {
                    return {
                        ...prev,
                        is_active: streamData.is_active
                    }
                } else {
                    return prev;
                }
            })

        })
        return () => {
            channel.stopListening(`.enable-or-disable-stream-event`);
        };
    }, [availableBroadcasts]);

    // change-stream-source socket
    useEffect(() => {
        if (!availableBroadcasts) return;
        const channel = echo.channel(`change-stream-source`);

        channel.listen(`.change-stream-source-event`, (data: any) => {
            const streamData: StreamDataType = data.stream;

            if (!streamData) return;

            setAvailableBroadcast(prev => {
                return prev.map(stream => {
                    if (stream.id === streamData.id) {
                        return {
                            ...stream,
                            is_use_fallback: streamData.is_use_fallback
                        };

                    } else {
                        return stream;
                    }
                })
            });

            setActiveBroadcast(prev => {
                if (prev?.id === streamData.id) {
                    return {
                        ...prev,
                        is_use_fallback: streamData.is_use_fallback
                    }
                } else {
                    return prev;
                }
            })

        })
        return () => {
            channel.stopListening(`.change-stream-source-event`);
        };
    }, [availableBroadcasts]);

    // ban user by moderator websocket
    useEffect(() => {
        if (!availableBroadcasts) return;
        const channel = echo.channel(`ban-user`);

        channel.listen(`.ban-user-event`, (data: any) => {
            const userId: number = data.banned_user_id;

            if (!userId) return;

            setForSpeakerBroadcastMessages(prev => {
                return prev.filter(message => message.user.id !== userId)
            });

            setCommonBroadcastMessages(prev => {
                return prev.filter(message => message.user.id !== userId)
            });

            setQuestionMessagesForModerators(prev => {
                return prev.filter(message => message.user.id !== userId)
            });

            if (userInfo && userId === userInfo.id) {
                setIsUserBlocked(true);
            }
        });

        return () => {
            channel.stopListening(`.ban-user-event`);
        };
    }, [availableBroadcasts, userInfo]);

    // find blocked users for first time render
    useEffect(() => {
        if (!userInfo) return;

        if (userInfo.is_banned) {
            setIsUserBlocked(true);
        }
    }, [userInfo]);

    return (
        <BroadcastContext.Provider value={{
            availableBroadcasts,
            activeBroadcast,
            toggleBroadcast,
            commonBroadcastMessages,
            forSpeakerBroadcastMessages,
            questionMessagesForModerators,
            chatMessagesForModerators,
            deleteMessage,
            addLikeToMessage,
            removeLikeFromMessage,
            sendMessage,
            isFetchingMessages,
            filters,
            activeFilter,
            toggleFilterMessages,
            pinnedMessage,
            isUserBlocked,
            streamNum,
            approveMessage,
            rejectMessage,
            pinMessage,
            banUser,
            chatType,
            toggleChatType,
            clearAllMessages,
            unpinMessage
        }}>
            {children}
        </BroadcastContext.Provider>
    )
}

export { BroadcastContext, BroadcastProvider }
