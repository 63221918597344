import React from "react";
import classNames from "classnames";
import { ReactComponent as DiscountIcon } from "./assets/discount-icon.svg";
import { DISCOUNT_PRICE, GENERAL_PRICE } from "@/constants/tariffs";
import "./index.scss";

type Props = {
    className?: string;
    showTag?: boolean;
    withDiscount?: any;
    price?: any;
    color?: "green" | "black" | "white";
};

const PlanPriceTag: React.FC<Props> = ({
    withDiscount,
    showTag = true,
    className,
    color = "green",
}) => {
    const formatPrice = (price?: any) => {
        const formattedPrice = Intl.NumberFormat("ru", {
            style: "currency",
            currency: "RUB",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(price);

        return formattedPrice;
    };

    return (
        <div className={classNames(className, "PlanPriceTag")}>
            <div className="PlanPriceTag__Price">
                {withDiscount ? (
                    <>
                        <div className="PlanPriceTag__PriceDiscount">
                            {showTag && formatPrice(GENERAL_PRICE)}

                            {+withDiscount !== 100 && <DiscountIcon />}
                        </div>
                        <p
                            className={classNames("PlanPriceTag__Free", {
                                [`PlanPriceTag__Free--${color}`]: color,
                            })}
                        >
                        {+withDiscount !== 100 ? (
                            formatPrice(DISCOUNT_PRICE)
                        ) : (
                                "Бесплатно"
                            )}
                        </p>
                    </>
                ) : (
                    <p
                        className={classNames("PlanPriceTag__Free", {
                            [`PlanPriceTag__Free--${color}`]: color,
                        })}
                    >
                        {formatPrice(GENERAL_PRICE)}
                    </p>
                )}
            </div>
            {/* <p className="PlanPriceTag__PriceNote">
                Стоимость за одного
                <br />
                участника
            </p> */}
        </div>
    );
};

export default PlanPriceTag;
