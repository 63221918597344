import * as yup from "yup";
import { basicSchema, locationSchema, activitySchema, transportSchema } from "@/Schema/signUpSchema";
import { yupTestScheme } from "@/utils/yup";
import { errors } from "@/constants/messages";
import { TARIFF_WITH_REQUIRED_PHONE } from "@/constants/validations";

const { agree_processing_of_personal_data, agree_receive_promotional_and_informational_mailings, email, ...restBasInfo } = basicSchema;

export const profileEmail = (initialValues: any) =>
    yup.string().test(async (newVal: any, context) => {
        if (newVal === initialValues.email) return true;
        await yupTestScheme(email, newVal, { context });
        return true;
    });

export const profileSchema = (initialValues: any) =>
    yup.object({
        ...restBasInfo,
        email: profileEmail(initialValues),
        phone: yup.string().when("tariff", ([tariff], schema) => {
            if (TARIFF_WITH_REQUIRED_PHONE.includes(tariff)) {
                return schema.required(errors.required);
            }
            return schema;
        }),
        ...activitySchema,
        ...locationSchema,
        ...transportSchema,
    });
