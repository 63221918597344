import useMedia from '@/hooks/useMedia';
import classNames from 'classnames';
import { PuffLoader } from 'react-spinners';

type Props = {
    className?: string;
    color?: string;
    size?: number;
};

export const LoadingSpinner: React.FC<Props> = ({ className, color, size }) => {
    const { mediaValue } = useMedia();

    const loaderSize = size ?? mediaValue(60, 80);

    return (
        <div className={classNames(className, "SpinnerWrapper")}>
            <PuffLoader
                color={color || "#169B50"}
                size={loaderSize}
            />
        </div>
    );
};
