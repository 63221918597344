import React, { PropsWithChildren } from "react";
import { BannerProvider } from "@/context/BannerContext";
import { NavProvider } from "@/context/NavContext";
import { ScheduleProvider } from "@/context/ScheduleContext";
import { UserProvider } from "@/context/UserContext";
import { BroadcastProvider } from "@/context/BroadcastContext";
import { RegistrationProvider } from "@/context/RegistrationContext";

const ContextProviders: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <UserProvider>
            <NavProvider>
                <BannerProvider>
                    <RegistrationProvider>
                        <BroadcastProvider>
                            <ScheduleProvider>{children}</ScheduleProvider>
                        </BroadcastProvider>
                    </RegistrationProvider>
                </BannerProvider>
            </NavProvider>
        </UserProvider>
    );
};

export default ContextProviders;
