import React from "react";
import { HashLink } from "react-router-hash-link";
import useMedia from "@/hooks/useMedia";
import scrollToSection from "@/utils/scrollToSection";
import "./index.scss";

type Props = {
    hashLinks: {
        text: string,
        link: string,
    }[];
    baseUrl: string,
}

const FooterHashlinks: React.FC<Props> = ({
    hashLinks,
    baseUrl,
}) => {
    const { isMobile } = useMedia();

    return (
        <div className="FooterHashlinks">
            {hashLinks.map((el, index) => (
                <HashLink
                    key={index}
                    className="FooterHashlinks__Item"
                    to={`${baseUrl}#${el.link}`}
                    scroll={() =>
                        scrollToSection(el.link, isMobile ? 100 : 200)
                    }
                >
                    {el.text}
                </HashLink>
            ))}
        </div>
    );
};

export default FooterHashlinks;
