import React, { useMemo } from "react";
import { FormikData } from "@/types/formik";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import Checkbox from "@/components/Checkbox";
import { FormInput, PhoneInput } from "@/components/Inputs";
import { FormikErrorMessage } from "@/components/FormikErrorMessage";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { useFormikDebounceEmail } from "@/hooks/useFormikDebounceEmail";
import { PROCESSING_PERSONAL_AGREEMENT, RECEIVE_PROMOTIONAL_AGREEMENT, UNIVERSITY_POLICY } from "@/constants/links";
import Typography from "@/components/Typography";
import "./index.scss";

export type BasicInformationProps = {
    formik: FormikData<any>;
    disableEmail?: boolean;
    nextStep?: () => void;
    withPhoneNumber?: boolean;
};

const BasicInformation: React.FC<BasicInformationProps> = ({
    formik,
    disableEmail,
    nextStep,
    withPhoneNumber,
}) => {
    const isValid = useMemo(() => {
        const basicValid = !(
            hasError(formik, "name", false) ||
            hasError(formik, "surname", false) ||
            hasError(formik, "email", false) ||
            hasError(formik, "agree_processing_of_personal_data", false) ||
            hasError(formik, "agree_receive_promotional_and_informational_mailings", false)
        );
        return (
            !(withPhoneNumber && hasError(formik, "phone", false)) && basicValid
        );
    }, [formik]);

    const [email, setEmail] = useFormikDebounceEmail(formik);

    return (
        <div className="BasicInformation">
            <FormikErrorWrap
                className="BasicInformation__InputWrap"
                formik={formik}
                name="name"
            >
                <FormInput
                    className="BasicInformation__Input"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Введите имя"
                    label="Имя"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={hasError(formik, "name")}
                />
            </FormikErrorWrap>

            <FormikErrorWrap
                className="BasicInformation__InputWrap"
                formik={formik}
                name="surname"
            >
                <FormInput
                    className="BasicInformation__Input"
                    type="text"
                    id="surname"
                    name="surname"
                    placeholder="Введите фамилию"
                    label="Фамилия"
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={hasError(formik, "surname")}
                />
            </FormikErrorWrap>

            <FormikErrorWrap
                className="BasicInformation__InputWrap"
                formik={formik}
                name="email"
            >
                <FormInput
                    className="BasicInformation__Input"
                    type="email"
                    id="email"
                    name="email"
                    placeholder={
                        formik.values.tariff === "from_sber"
                            ? "Введите почту Сбера"
                            : "Введите свою почту"
                    }
                    label={
                        formik.values.tariff === "from_sber"
                            ? "Почта Сбера"
                            : "Электронная почта"
                    }
                    value={email}
                    disabled={disableEmail}
                    error={hasError(formik, "email")}
                    onBlur={formik.handleBlur}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormikErrorWrap>

            {withPhoneNumber && (
                <FormikErrorWrap
                    className="BasicInformation__InputWrap"
                    formik={formik}
                    name="phone"
                >
                    <PhoneInput
                        className="BasicInformation__Input"
                        id="phone"
                        name="phone"
                        placeholder="Введите номер телефона"
                        label="Телефон"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isError={hasError(formik, "phone")}
                    />
                </FormikErrorWrap>
            )}

            <div className="BasicInformation__Links">
                <Checkbox
                    className="BasicInformation__Checkbox"
                    name="agree_processing_of_personal_data"
                    id="agree_processing_of_personal_data"
                    size="sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.agree_processing_of_personal_data}
                    isError={hasError(
                        formik,
                        "agree_processing_of_personal_data",
                    )}
                >
                    <Typography
                        size="xs"
                        weight="regular"
                        color="inherit"
                    >
                        Я ознакомлен(а) с&nbsp;
                        <a
                            href={UNIVERSITY_POLICY}
                            className="BasicInformation__CheckboxLink"
                            target="_blank"
                            rel="noreferrer"
                        >
                            политикой СберУниверситета
                        </a>
                        &nbsp;и согласен(а) на&nbsp;
                        <a
                            href={PROCESSING_PERSONAL_AGREEMENT}
                            className="BasicInformation__CheckboxLink"
                            target="_blank"
                            rel="noreferrer"
                        >
                            обработку персональных данных
                        </a>
                    </Typography>
                </Checkbox>
                <FormikErrorMessage
                    formik={formik}
                    name="agree_processing_of_personal_data"
                />
                <Checkbox
                    className="BasicInformation__Checkbox"
                    name="agree_receive_promotional_and_informational_mailings"
                    id="agree_receive_promotional_and_informational_mailings"
                    size="sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.agree_receive_promotional_and_informational_mailings}
                    isError={hasError(
                        formik,
                        "agree_receive_promotional_and_informational_mailings",
                    )}
                >
                    <Typography
                        size="xs"
                        weight="regular"
                        color="inherit"
                    >
                        Я согласен(а) на получение<br />
                        <a
                            href={RECEIVE_PROMOTIONAL_AGREEMENT}
                            className="BasicInformation__CheckboxLink"
                            target="_blank"
                            rel="noreferrer"
                        >
                            рекламно-информационных рассылок
                        </a>
                    </Typography>
                </Checkbox>
                <FormikErrorMessage
                    formik={formik}
                    name="agree_receive_promotional_and_informational_mailings"
                />
            </div>

            <Tooltip
                className="BasicInformation__Tooltip"
                tooltip={
                    "Необходимо заполнить все поля\nи/или дать согласие на обработку\nперсональных данных и получение рекламно-информационных рассылок"
                }
                disabled={isValid}
                maxWidth="348"
            >
                <Button
                    className="BasicInformation__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    TextSize="sm"
                    disabled={!isValid}
                    isWide
                    gradient
                />
            </Tooltip>
        </div>
    );
};

export default BasicInformation;
