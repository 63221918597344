import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { FaqItemAccordion } from "../types";
import "./index.scss";

type Props = {
    questions_and_answers: FaqItemAccordion[];
};

const FaqAccordion: React.FC<Props> = ({
    questions_and_answers,
}) => {
    return (
        <Accordion.Root type="single" collapsible>
            {questions_and_answers &&
                questions_and_answers.map((item, index) => {
                    return (
                        <Accordion.Item
                            key={index}
                            value={`value-${index}`}
                            className="FaqAccordion__Item"
                        >
                            <Accordion.Header asChild>
                                <Accordion.Trigger>
                                    {item.question}
                                    <ArrowIcon className="FaqAccordion__Arrow" />
                                </Accordion.Trigger>
                            </Accordion.Header>
                            <Accordion.Content className="FaqAccordion__Content">
                                <div
                                    className="FaqAccordion__ContentInner"
                                    dangerouslySetInnerHTML={{
                                        __html: item.answer,
                                    }}
                                />
                            </Accordion.Content>
                        </Accordion.Item>
                    );
                })}
        </Accordion.Root>
    );
};

export default FaqAccordion;
