import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from './assets/close-icon.svg';
import * as Popover from "@radix-ui/react-popover";
import Typography from "@/components/Typography";
import "./index.scss";

const PASSWORD_RULES = [
    "Не менее 8 символов",
    "Только латинские буквы",
    "Минимум 1 заглавная буква",
    "Минимум 1 цифра",
    "Минимум 1 специальный символ",
];

type Props = {
    className?: string;
};

const FormPasswordRules: React.FC<PropsWithChildren<Props>> = ({
    children,
    className,
}) => {
    const renderElements = PASSWORD_RULES.map((el, index) => (
        <li key={index} className="FormPasswordRules__Item">
            {el}
        </li>
    ));

    return (
        <div className="FormPasswordRules">
            <Popover.Root>
                <Popover.Trigger
                    className={classNames(
                        className,
                        "FormPasswordRules__Trigger",
                    )}
                >
                    <Typography
                        component="span"
                        size="xs"
                        color="dark"
                        weight="regular"
                    >
                        Требования к паролю
                    </Typography>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content sideOffset={8} collisionPadding={16}>
                        <Popover.Close className="FormPasswordRules__CloseBtn">
                            <CloseIcon />
                        </Popover.Close>
                        <div className="FormPasswordRules__Content">
                            <Typography
                                component="span"
                                size="sm"
                                color="dark"
                                weight="regular"
                            >
                                Требования к паролю:
                            </Typography>
                            <ul className="FormPasswordRules__List">
                                {renderElements}
                            </ul>
                        </div>
                    </Popover.Content>
                </Popover.Portal>
                {children}
            </Popover.Root>
        </div>
    );
};

export default FormPasswordRules;
