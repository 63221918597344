import React, { PropsWithChildren } from "react";
import "./index.scss";

type Props = {
    title: string;
};

const FooterBlock: React.FC<PropsWithChildren<Props>> = ({
    title,
    children,
}) => {
    return (
        <div className="FooterBlock">
            <div className="FooterBlock__Title">{title}</div>
            <div className="FooterBlock__Content">{children}</div>
        </div>
    );
};

export default FooterBlock;
