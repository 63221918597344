import React from "react";
import Container from "@/components/Container";
import Typography from "@/components/Typography";
import Headline from "@/components/Headline";
import AiIcon from "./assets/ai-icon.png";
import AiIconMobile from "./assets/ai-icon-mobile.png";
import useMedia from "@/hooks/useMedia";
import { CONFERENCE_THEME_HASH_ID } from "@/constants";
import "./index.scss";

const ConferenceIntroduction: React.FC = () => {
    const { isMobile } = useMedia();

    return (
        <section id={CONFERENCE_THEME_HASH_ID} className="ConferenceIntroduction">
            <Container className="ConferenceIntroduction__Wrapper">
                <div className="ConferenceIntroduction__Header">
                    <Typography
                        className="ConferenceIntroduction__PreTitle"
                        size="md"
                        weight="regular"
                        color="light"
                        isCentered
                    >
                        {isMobile
                            ? "Конференция — место,\nгде возможности настоящего\nпревращаются в образы будущего"
                            : "Конференция — место, где возможности настоящего\nпревращаются в образы будущего"
                        }
                    </Typography>
                    <Headline
                        className="ConferenceIntroduction__Title"
                        level="h2"
                        size="xlg"
                        theme="light"
                        isCentered
                    >
                        {isMobile
                            ? "Будущее\nобразования:\n10 тысяч лет\nза тысячу дней"
                            : "Будущее образования:\n10 тысяч лет за тысячу дней"
                            }
                    </Headline>
                    <Typography
                        className="ConferenceIntroduction__SubTitle"
                        size="lg"
                        weight="regular"
                        color="light"
                        gradient="green"
                        isCentered
                    >
                        {isMobile
                            ? "Образование прошло долгий\nпуть от устных традиций\nпередачи знаний к обучению\nв цифровых пространствах\nи дополненной реальности"
                            : "Образование прошло долгий путь от устных традиций\nпередачи знаний к обучению в цифровых пространствах\nи дополненной реальности"
                        }
                    </Typography>
                </div>
                <div className="ConferenceIntroduction__Body">
                    <div className="ConferenceIntroduction__BodyText">
                        <Typography
                            component="p"
                            size="lg"
                            weight="semiBold"
                            color="light"
                        >
                            Теперь человечеству доступен{" "}
                            <Typography
                                component="span"
                                size="lg"
                                weight="semiBold"
                                color="light"
                                gradient="green"
                            >
                                искусственный интеллект&nbsp;
                            </Typography>
                            — инструмент, который, кажется, может изменить всё.
                        </Typography>
                        <Typography
                            className="ConferenceIntroduction__Subtext"
                            size="md"
                            weight="regular"
                            color="gray"
                            opacity={0.6}
                        >
                            Способен ли человек с помощью технологий превзойти
                            за три года прогресс предыдущих тысячелетий? Каким
                            будет новое будущее? Как должны измениться люди и
                            общество?
                        </Typography>
                    </div>
                    <div className="ConferenceIntroduction__BodyImg">
                        <picture>
                            <source
                                srcSet={AiIconMobile}
                                media="(max-width: 575px)"
                            />
                            <source
                                srcSet={AiIcon}
                                media="(min-width: 576px)"
                            />
                            <img src={AiIcon} alt="Ai Icon" loading="lazy" />
                        </picture>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default ConferenceIntroduction;
