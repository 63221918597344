import React from "react";
import PlanPriceTag from "@/components/PlanPriceTag";
import PaidPlanForm from "@/components/PaidPlanForm";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import { FormatType } from "@/types/registration";
import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string;
    showOnlineBtn?: boolean;
    participationFormat: FormatType;
}

const PlanPrice: React.FC<Props> = ({ showOnlineBtn, participationFormat, className }) => {
    return (
        <div className={classNames("PlanPrice", className)}>
            <div className="PlanPrice__Container">
                {participationFormat === "online" ? (
                    <div className="PlanPrice__Online">
                        {showOnlineBtn && (
                            <div className="PlanPrice__OnlineAction">
                                <Tooltip
                                    tooltip={
                                        "Чтобы изменить тариф на «Онлайн», заполните\nформу в разделе «Вернуть денежные средства»"
                                    }
                                >
                                    <Button className="PlanPrice__OnlineBtn" text="Сменить тариф" disabled />
                                </Tooltip>
                            </div>
                        )}
                        <div className="PlanPrice__OnlineInfo">
                            <PlanPriceTag showTag={false} withDiscount="100" />
                        </div>
                    </div>
                ) : (
                    <PaidPlanForm buttonText="Сменить тариф" tooltipText="Выберите тариф" showNotes />
                )}
            </div>
        </div>
    );
};

export default PlanPrice;
