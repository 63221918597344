import React, { useEffect, useState } from "react";
import { SpeakerType } from "types/interfaces";
import Popup from "@/components/Popup";
import { useToggle } from "react-use";
import SpeakerPopupContent from "@/components/SpeakerPopupContent";
import { ReactComponent as BackwordArrow } from "../assets/backwords-arrow.svg";
import classNames from "classnames";
import useMedia from "@/hooks/useMedia";
import Typography from "components/Typography";
import "./index.scss";

type Props = {
    data: SpeakerType;
    showSpeakerdescription?: boolean;
    isModerator?: boolean;
    avatarSize?: "big" | "small";
};

const SessionSpeaker: React.FC<Props> = ({
    data,
    showSpeakerdescription = false,
    isModerator = false,
    avatarSize = "small",
}) => {
    const [isActive, togglePopup] = useToggle(false);
    const [backwordBtn, setBackwordBtn] = useState<boolean>(false);
    const { isMobile, isDesktop } = useMedia();

    useEffect(() => {
        if (isActive) {
            const countPopups =
                document.getElementById("popupPortal")?.childNodes.length;

            if (countPopups && countPopups > 1) {
                setBackwordBtn(true);
            }
        }
    }, [isActive]);

    const handleClick = () => {
        if (!data.photo_url) return;

        togglePopup();
    }

    return (
        <>
            <div
                className={classNames("SessionSpeaker__Container", {
                    "SessionSpeaker__Container--moderator":
                        showSpeakerdescription && isModerator,
                })}
                onClick={handleClick}
            >
                {isModerator && showSpeakerdescription && (
                    <Typography
                        className="SessionSpeaker__Moderator"
                        color="gray"
                        component="span"
                        size="sm"
                        weight="bold"
                    >
                        {data.title}
                    </Typography>
                )}
                <div
                    className={classNames("SessionSpeaker", {
                        "SessionSpeaker--diabled": !data.photo_url,
                        "SessionSpeaker--big": avatarSize === "big",
                    })}
                >
                    <div className="SessionSpeaker__AvatarContainer">
                        <img
                            className="SessionSpeaker__Avatar"
                            src={data.avatar}
                            alt={data.fullName}
                        />
                    </div>
                    <div className="SessionSpeaker__SpeakerInfo">
                        <p className={classNames("SessionSpeaker__SpeakerName", {
                            "SessionSpeaker__SpeakerName--Gray": !data.photo_url,
                        })}>
                            {data.name}{" "}
                            <br />
                            {data.surname}
                        </p>
                        {showSpeakerdescription && (
                            <p className="SessionSpeaker__SpeakerTitle">
                                {data.description}
                            </p>
                        )}
                    </div>
                </div>
            </div>

            <Popup
                className="SessionSpeaker__Popup"
                closePopup={togglePopup}
                isPopupActive={isActive}
                withCloseBtn={!backwordBtn || isMobile}
                position={isMobile ? "bottom" : "right"}
                isSubPopup
            >
                {backwordBtn && (
                    <button
                        className="SessionSpeaker__BackwordBtn"
                        onClick={togglePopup}
                    >
                        <BackwordArrow />
                        {!isDesktop && ("назад")}
                    </button>
                )}
                <SpeakerPopupContent data={data} />
            </Popup>
        </>
    );
};

export default SessionSpeaker;
