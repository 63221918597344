import { useMedia as useMediaHook } from 'use-media';

const SCREEN_SIZES = {
    MOBILE: "(max-width: 575px)",
    ONLY_TABLET: "(min-width: 576px) and (max-width: 991px)",
    DESKTOP: "(min-width: 992px)"
};

const useMedia = () => {
    const isMobile = useMediaHook(SCREEN_SIZES.MOBILE);

    const isTablet = useMediaHook(SCREEN_SIZES.ONLY_TABLET);

    const isDesktop = useMediaHook(SCREEN_SIZES.DESKTOP);

    const mediaValue = <T>(propMobile: T, propTable: T, propDesktop?: T): T => {
        if (isMobile) {
            return propMobile;
        }
        if (isTablet) {
            return propTable || propMobile;
        }
        if (isDesktop) {
            return propDesktop || propTable || propMobile;
        }
        return propMobile;
    };

    return {
        isMobile,
        isTablet,
        isDesktop,
        mediaValue,
    };
};

export default useMedia;