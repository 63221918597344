import React from 'react';
import Container from "components/Container"
import Headline from "components/Headline"
import FaqTabs from "./FaqTabs"
import { useFaq } from '@/hooks/useFaq';
import { FAQ_HASH_ID } from '@/constants';
import './index.scss'

const Faq: React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess,
    } = useFaq();

    return (
        <section className="Faq" id={FAQ_HASH_ID}>
            <Container className="Faq__Container">
                <Headline level="h2" size="xlg" theme="light" isCentered>
                    Остались вопросы?
                </Headline>
                {!isLoading && isSuccess && <FaqTabs data={data} />}
            </Container>
        </section>
    )
}

export default Faq