import { PicturesArchiveType } from "@/types/interfaces";

import Img01_original from "@/assets/photoArchive/original/001-min.jpg";
import Img01_original_webp from "@/assets/photoArchive/slides/001.webp";
import Img01_thumb from "@/assets/photoArchive/thumb/001-min.jpg";
import Img01_mobile from "@/assets/photoArchive/mobile/001-min.jpg";

import Img02_original from "@/assets/photoArchive/original/002-min.jpg";
import Img02_original_webp from "@/assets/photoArchive/slides/002.webp";
import Img02_thumb from "@/assets/photoArchive/thumb/002-min.jpg";
import Img02_mobile from "@/assets/photoArchive/mobile/002-min.jpg";

import Img03_original from "@/assets/photoArchive/original/003-min.jpg";
import Img03_original_webp from "@/assets/photoArchive/slides/003.webp";
import Img03_thumb from "@/assets/photoArchive/thumb/003-min.jpg";
import Img03_mobile from "@/assets/photoArchive/mobile/003-min.jpg";

import Img04_original from "@/assets/photoArchive/original/004-min.jpg";
import Img04_original_webp from "@/assets/photoArchive/slides/004.webp";
import Img04_thumb from "@/assets/photoArchive/thumb/004-min.jpg";
import Img04_mobile from "@/assets/photoArchive/mobile/004-min.jpg";

import Img05_original from "@/assets/photoArchive/original/005-min.jpg";
import Img05_original_webp from "@/assets/photoArchive/slides/005.webp";
import Img05_thumb from "@/assets/photoArchive/thumb/005-min.jpg";
import Img05_mobile from "@/assets/photoArchive/mobile/005-min.jpg";

import Img122_original from "@/assets/photoArchive/original/122-min.jpg";
import Img122_original_webp from "@/assets/photoArchive/slides/122.webp";
import Img122_thumb from "@/assets/photoArchive/thumb/122-min.jpg";
import Img122_mobile from "@/assets/photoArchive/mobile/122-min.jpg";

import Img160_original from "@/assets/photoArchive/original/160-min.jpg";
import Img160_original_webp from "@/assets/photoArchive/slides/160.webp";
import Img160_thumb from "@/assets/photoArchive/thumb/160-min.jpg";
import Img160_mobile from "@/assets/photoArchive/mobile/160-min.jpg";

import Img203_original from "@/assets/photoArchive/original/203-min.jpg";
import Img203_original_webp from "@/assets/photoArchive/slides/203.webp";
import Img203_thumb from "@/assets/photoArchive/thumb/203-min.jpg";
import Img203_mobile from "@/assets/photoArchive/mobile/203-min.jpg";

import Img209_original from "@/assets/photoArchive/original/209-min.jpg";
import Img209_original_webp from "@/assets/photoArchive/slides/209.webp";
import Img209_thumb from "@/assets/photoArchive/thumb/209-min.jpg";
import Img209_mobile from "@/assets/photoArchive/mobile/209-min.jpg";

import Img233_original from "@/assets/photoArchive/original/233-min.jpg";
import Img233_original_webp from "@/assets/photoArchive/slides/233.webp";
import Img233_thumb from "@/assets/photoArchive/thumb/233-min.jpg";
import Img233_mobile from "@/assets/photoArchive/mobile/233-min.jpg";

import Img235_original from "@/assets/photoArchive/original/235-min.jpg";
import Img235_original_webp from "@/assets/photoArchive/slides/235.webp";
import Img235_thumb from "@/assets/photoArchive/thumb/235-min.jpg";
import Img235_mobile from "@/assets/photoArchive/mobile/235-min.jpg";

import Img255_original from "@/assets/photoArchive/original/255-min.jpg";
import Img255_original_webp from "@/assets/photoArchive/slides/255.webp";
import Img255_thumb from "@/assets/photoArchive/thumb/255-min.jpg";
import Img255_mobile from "@/assets/photoArchive/mobile/255-min.jpg";

import Img294_original from "@/assets/photoArchive/original/294-min.jpg";
import Img294_original_webp from "@/assets/photoArchive/slides/294.webp";
import Img294_thumb from "@/assets/photoArchive/thumb/294-min.jpg";
import Img294_mobile from "@/assets/photoArchive/mobile/294-min.jpg";

import Img345_original from "@/assets/photoArchive/original/345-min.jpg";
import Img345_original_webp from "@/assets/photoArchive/slides/345.webp";
import Img345_thumb from "@/assets/photoArchive/thumb/345-min.jpg";
import Img345_mobile from "@/assets/photoArchive/mobile/345-min.jpg";

import Img373_original from "@/assets/photoArchive/original/373-min.jpg";
import Img373_original_webp from "@/assets/photoArchive/slides/373.webp";
import Img373_thumb from "@/assets/photoArchive/thumb/373-min.jpg";
import Img373_mobile from "@/assets/photoArchive/mobile/373-min.jpg";

import Img377_original from "@/assets/photoArchive/original/377-min.jpg";
import Img377_original_webp from "@/assets/photoArchive/slides/377.webp";
import Img377_thumb from "@/assets/photoArchive/thumb/377-min.jpg";
import Img377_mobile from "@/assets/photoArchive/mobile/377-min.jpg";

import Img451_original from "@/assets/photoArchive/original/451-min.jpg";
import Img451_original_webp from "@/assets/photoArchive/slides/451.webp";
import Img451_thumb from "@/assets/photoArchive/thumb/451-min.jpg";
import Img451_mobile from "@/assets/photoArchive/mobile/451-min.jpg";

import Img475_original from "@/assets/photoArchive/original/475-min.jpg";
import Img475_original_webp from "@/assets/photoArchive/slides/475.webp";
import Img475_thumb from "@/assets/photoArchive/thumb/475-min.jpg";
import Img475_mobile from "@/assets/photoArchive/mobile/475-min.jpg";

import Img476_original from "@/assets/photoArchive/original/476-min.jpg";
import Img476_original_webp from "@/assets/photoArchive/slides/476.webp";
import Img476_thumb from "@/assets/photoArchive/thumb/476-min.jpg";
import Img476_mobile from "@/assets/photoArchive/mobile/476-min.jpg";

import Img533_original from "@/assets/photoArchive/original/533-min.jpg";
import Img533_original_webp from "@/assets/photoArchive/slides/533.webp";
import Img533_thumb from "@/assets/photoArchive/thumb/533-min.jpg";
import Img533_mobile from "@/assets/photoArchive/mobile/533-min.jpg";

import Img534_original from "@/assets/photoArchive/original/534-min.jpg";
import Img534_original_webp from "@/assets/photoArchive/slides/534.webp";
import Img534_thumb from "@/assets/photoArchive/thumb/534-min.jpg";
import Img534_mobile from "@/assets/photoArchive/mobile/534-min.jpg";

import Img578_original from "@/assets/photoArchive/original/578-min.jpg";
import Img578_original_webp from "@/assets/photoArchive/slides/578.webp";
import Img578_thumb from "@/assets/photoArchive/thumb/578-min.jpg";
import Img578_mobile from "@/assets/photoArchive/mobile/578-min.jpg";

import Img593_original from "@/assets/photoArchive/original/593-min.jpg";
import Img593_original_webp from "@/assets/photoArchive/slides/593.webp";
import Img593_thumb from "@/assets/photoArchive/thumb/593-min.jpg";
import Img593_mobile from "@/assets/photoArchive/mobile/593-min.jpg";

import Img656_original from "@/assets/photoArchive/original/656-min.jpg";
import Img656_original_webp from "@/assets/photoArchive/slides/656.webp";
import Img656_thumb from "@/assets/photoArchive/thumb/656-min.jpg";
import Img656_mobile from "@/assets/photoArchive/mobile/656-min.jpg";

import Img711_original from "@/assets/photoArchive/original/711-min.jpg";
import Img711_original_webp from "@/assets/photoArchive/slides/711.webp";
import Img711_thumb from "@/assets/photoArchive/thumb/711-min.jpg";
import Img711_mobile from "@/assets/photoArchive/mobile/711-min.jpg";

import Img725_original from "@/assets/photoArchive/original/725-min.jpg";
import Img725_original_webp from "@/assets/photoArchive/slides/725.webp";
import Img725_thumb from "@/assets/photoArchive/thumb/725-min.jpg";
import Img725_mobile from "@/assets/photoArchive/mobile/725-min.jpg";

import Img729_original from "@/assets/photoArchive/original/729-min.jpg";
import Img729_original_webp from "@/assets/photoArchive/slides/729.webp";
import Img729_thumb from "@/assets/photoArchive/thumb/729-min.jpg";
import Img729_mobile from "@/assets/photoArchive/mobile/729-min.jpg";

export const PICTURES_ARCHIVE: PicturesArchiveType[] = [
    {
        src_original: Img01_original,
        src_original_webp: Img01_original_webp,
        src_thumb: Img01_thumb,
        src_mobile: Img01_mobile,
    },
    {
        src_original: Img02_original,
        src_original_webp: Img02_original_webp,
        src_thumb: Img02_thumb,
        src_mobile: Img02_mobile,
    },
    {
        src_original: Img03_original,
        src_original_webp: Img03_original_webp,
        src_thumb: Img03_thumb,
        src_mobile: Img03_mobile,
    },
    {
        src_original: Img04_original,
        src_original_webp: Img04_original_webp,
        src_thumb: Img04_thumb,
        src_mobile: Img04_mobile,
    },
    {
        src_original: Img05_original,
        src_original_webp: Img05_original_webp,
        src_thumb: Img05_thumb,
        src_mobile: Img05_mobile,
    },
    {
        src_original: Img122_original,
        src_original_webp: Img122_original_webp,
        src_thumb: Img122_thumb,
        src_mobile: Img122_mobile,
    },
    {
        src_original: Img160_original,
        src_original_webp: Img160_original_webp,
        src_thumb: Img160_thumb,
        src_mobile: Img160_mobile,
    },
    {
        src_original: Img203_original,
        src_original_webp: Img203_original_webp,
        src_thumb: Img203_thumb,
        src_mobile: Img203_mobile,
    },
    {
        src_original: Img209_original,
        src_original_webp: Img209_original_webp,
        src_thumb: Img209_thumb,
        src_mobile: Img209_mobile,
    },
    {
        src_original: Img233_original,
        src_original_webp: Img233_original_webp,
        src_thumb: Img233_thumb,
        src_mobile: Img233_mobile,
    },
    {
        src_original: Img235_original,
        src_original_webp: Img235_original_webp,
        src_thumb: Img235_thumb,
        src_mobile: Img235_mobile,
    },
    {
        src_original: Img255_original,
        src_original_webp: Img255_original_webp,
        src_thumb: Img255_thumb,
        src_mobile: Img255_mobile,
    },
    {
        src_original: Img294_original,
        src_original_webp: Img294_original_webp,
        src_thumb: Img294_thumb,
        src_mobile: Img294_mobile,
    },
    {
        src_original: Img345_original,
        src_original_webp: Img345_original_webp,
        src_thumb: Img345_thumb,
        src_mobile: Img345_mobile,
    },
    {
        src_original: Img373_original,
        src_original_webp: Img373_original_webp,
        src_thumb: Img373_thumb,
        src_mobile: Img373_mobile,
    },
    {
        src_original: Img377_original,
        src_original_webp: Img377_original_webp,
        src_thumb: Img377_thumb,
        src_mobile: Img377_mobile,
    },
    {
        src_original: Img451_original,
        src_original_webp: Img451_original_webp,
        src_thumb: Img451_thumb,
        src_mobile: Img451_mobile,
    },
    {
        src_original: Img475_original,
        src_original_webp: Img475_original_webp,
        src_thumb: Img475_thumb,
        src_mobile: Img475_mobile,
    },
    {
        src_original: Img476_original,
        src_original_webp: Img476_original_webp,
        src_thumb: Img476_thumb,
        src_mobile: Img476_mobile,
    },
    {
        src_original: Img533_original,
        src_original_webp: Img533_original_webp,
        src_thumb: Img533_thumb,
        src_mobile: Img533_mobile,
    },
    {
        src_original: Img534_original,
        src_original_webp: Img534_original_webp,
        src_thumb: Img534_thumb,
        src_mobile: Img534_mobile,
    },
    {
        src_original: Img578_original,
        src_original_webp: Img578_original_webp,
        src_thumb: Img578_thumb,
        src_mobile: Img578_mobile,
    },
    {
        src_original: Img593_original,
        src_original_webp: Img593_original_webp,
        src_thumb: Img593_thumb,
        src_mobile: Img593_mobile,
    },
    {
        src_original: Img656_original,
        src_original_webp: Img656_original_webp,
        src_thumb: Img656_thumb,
        src_mobile: Img656_mobile,
    },
    {
        src_original: Img711_original,
        src_original_webp: Img711_original_webp,
        src_thumb: Img711_thumb,
        src_mobile: Img711_mobile,
    },
    {
        src_original: Img725_original,
        src_original_webp: Img725_original_webp,
        src_thumb: Img725_thumb,
        src_mobile: Img725_mobile,
    },
    {
        src_original: Img729_original,
        src_original_webp: Img729_original_webp,
        src_thumb: Img729_thumb,
        src_mobile: Img729_mobile,
    },
]