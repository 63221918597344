import React, { createContext, PropsWithChildren } from "react";
import { TariffType } from "@/types/registration";

type RegistrationContextType = {
    isRegistrationActive: boolean,
    isTariffAllowed: (tariff: TariffType) => boolean,
}

const RegistrationContext = createContext<RegistrationContextType>({
    isRegistrationActive: false,
    isTariffAllowed: () => false,
})

const ALLOWED_TARIFFS: TariffType[] = ["common", "from_education"];

const RegistrationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const isRegistrationActive = false;

    const isTariffAllowed = (tariff: TariffType | undefined) => {
        if (tariff && ALLOWED_TARIFFS.includes(tariff) && isRegistrationActive) {
            return true;
        }

        return false;
    };

    return (
        <RegistrationContext.Provider value={{ isRegistrationActive, isTariffAllowed }}>
            {children}
        </RegistrationContext.Provider>
    )
}

export { RegistrationContext, RegistrationProvider }