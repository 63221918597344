import React from "react";
import FormErrorMessage from "@/components/FormErrorMessage";
import { FormikData } from "@/types/formik";
import { hasError } from "@/utils/formik";

export type FormikErrorMessageProps = {
    className?: string;
    formik: FormikData<any>;
    name: string;
    touched?: boolean;
};

export const FormikErrorMessage: React.FC<FormikErrorMessageProps> = (props) => {
    const { className, formik, name, touched } = props;
    if (hasError(formik, name, touched)) {
        const error = formik.getFieldMeta(name).error;
        // @ts-ignore
        return <FormErrorMessage className={className} text={error} />;
    }
    return null;
};
