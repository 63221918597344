import React from "react";
import classNames from "classnames";
import "./index.scss";

const PlanDescription = ({ className = "", text, theme = "light" }) => {
    return (
        <p
            className={classNames(className, "PlanDescription", {
                "PlanDescription--light": theme === "light",
                "PlanDescription--dark": theme === "dark",
            })}
        >
            {text}
        </p>
    );
};

export default PlanDescription;
