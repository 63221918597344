import React from "react";
import "./index.scss";

type Props = {
    srcJpg: string,
    srcWebp: string,
    onClick?: () => void;
}

const altText = "Атмосфера мероприятия"

const PhotoCard: React.FC<Props> = ({ srcJpg, srcWebp, onClick }) => {
    return (
        <picture>
            <source srcSet={srcWebp} type="image/webp" />
            <img
                className="PhotoCard__Img"
                src={srcJpg}
                alt={altText}
                loading="lazy"
                onClick={onClick}
            />
        </picture>
    )
}

export default PhotoCard;