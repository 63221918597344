import { ActivityType, FormatType, LegalType, TariffType } from "@/types/registration";

export const CYRILLIC_REQUIREMENT = /^[А-ЯЁа-яё\s]+$/;
export const CYRILLIC_SURNAME_REQUIREMENT = /^[А-ЯЁа-яё\s]+$/;
export const PASSWORD_REQUIREMENT = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!\"#$%&'()*+,-.\/:;<=>?@\[\]^_`{|}~]{8,}$/;
export const TARIFF_TYPES: Array<TariffType> = ["free", "common", "vip", "from_sber", "from_education"];
export const FORMAT_TYPES: Array<FormatType> = ["online", "offline"];
export const ACTIVITY_TYPES: Array<ActivityType> = ["study", "work", "no_activity"];
export const LEGAL_TYPES: Array<LegalType> = ["individual", "legal"];
export const MESSAGE_REFUND_LENGTH = 100;
export const TARIFF_WITH_REQUIRED_TRANSPORT: Array<TariffType> = ["common", "from_education", "vip"];
export const TARIFF_WITH_REQUIRED_PHONE: Array<TariffType> = ["common", "from_sber"];
export const TARIFF_WITH_PAYMENT: Array<TariffType> = ["common", "from_education"];
