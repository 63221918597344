import React, { useEffect, useState } from "react";
import Container from "@/components/Container";
import NavBar from "./components/NavBar";
import Logo from "@/components/Logo";
import ShareBtn from "@/components/ShareBtn";
import classNames from "classnames";
import { debounce } from "@/utils/debounce";
import useMedia from "@/hooks/useMedia";
import useBanner from "@/hooks/useBanner";
import { useIsAuthenticated } from "react-auth-kit";
import ProfileBtn from "@/components/ProfileBtn";
import AuthorizationBtn from "@/components/AuthorizationBtn";
import MobileNav from "@/components/MobileNav";
import "./index.scss";

const Header: React.FC = ( ) => {
    const { isBannerActive } = useBanner();
    const isAuth = useIsAuthenticated()
    const [initialHeader, setInitialHeader] = useState(true);
    const { isDesktop } = useMedia();

    const listenScrollEvent = () => {
        setInitialHeader(window.scrollY < 100);
    };

    useEffect(() => {
        const debouncedScrollHandler = debounce(listenScrollEvent, 10);
        window.addEventListener("scroll", debouncedScrollHandler);
        return () => window.removeEventListener("scroll", debouncedScrollHandler);
    }, []);

    return (
        <header
            className={classNames("Header", {
                "Header--Scroll": !initialHeader,
                "Header--isBannerActive": isBannerActive,
            })}
        >
            <div className="Header__Wrapper">
                <Container className="Header__Container">
                    <div className="Header__Inner">
                        <Logo />
                        {isDesktop && <NavBar />}
                    <div className="Header__btns">
                        {isDesktop ? <ShareBtn /> : <MobileNav />}
                        {isAuth() ? <ProfileBtn /> : <AuthorizationBtn />}
                    </div>
                    </div>
                </Container>
            </div>
        </header>
    );
};

export default Header;
