import Skyeng from "./assets/skyeng.png";
import Igron from "./assets/igron.png";
import Visaver from "./assets/visaver.png";
import Kampus from "./assets/kampus.png";
import Reezonly from "./assets/reezonly.png";
import Akvio from "./assets/akvio.png";
import Leoni from "./assets/leoni.png";
import AsimovLab from "./assets/asimov-lab.png";
import Moblen from "./assets/moblen.png";
import Tbu from "./assets/tbu.png";
import Heo from "./assets/heo.png";
import Idpo from "./assets/idpo.png";
import { Winner } from "./types";

export const winners_nomination_01: Winner[] = [
    {
        name: "Игрон-сервис",
        isTop: true,
        link: "https://psygame-service.online",
        image: Igron,
        description: "Платформа для разработки и проведения игрового обучения",
    },
    {
        name: "AI Tutor",
        isTop: false,
        link: "https://skysmart.ru",
        image: Skyeng,
        description: "Инновационный тренажер для изучения английского языка",
    },
    {
        name: "ViSaver",
        isTop: false,
        link: "https://visaver.online",
        image: Visaver,
        description: "Сервис мгновенного поиска внутри видео, генерации таймкодов и тестов",
    },
];

export const winners_nomination_02: Winner[] = [
    {
        name: "Reezonly LMS",
        isTop: true,
        link: "https://reezonly.com",
        image: Reezonly,
        description: "Образовательная онлайн-платформа, оснащенная конструктором курсов и интегрированными инструментами AI-ассистента",
    },
    {
        name: "Kampus",
        isTop: false,
        link: "https://kampus.team",
        image: Kampus,
        description: "Платформа для адаптации, обучения, оценки и развития сотрудников",
    },
    {
        name: "Эквио",
        isTop: false,
        link: "https://e-queo.com",
        image: Akvio,
        description: "HR-платформа для обучения персонала",
    },
]

export const winners_nomination_03: Winner[] = [
    {
        name: "Leoni",
        isTop: true,
        link: "https://leoni.land",
        image: Leoni,
        description: "AI-инстурмент для создания персональных траекторий развития",
    },
    {
        name: "Asimov Lab",
        isTop: false,
        link: "https://asimovlab.ru",
        image: AsimovLab,
        description: "AI-платформа для генерации тестов и заданий",
    },
    {
        name: "Moblen",
        isTop: false,
        link: "https://www.moblen.ru/platform",
        image: Moblen,
        description: "AI-решение для автоматической проверки ответов",
    },
];

export const winners_nomination_04: Winner[] = [
    {
        name: "Тру",
        isTop: true,
        link: "https://trueducation.ru",
        image: Tbu,
        description: "Креативный провайдер корпоративного обучения",
    },
    {
        name: "НЕО",
        isTop: false,
        link: "https://neoproject.su",
        image: Heo,
        description: "«НЕО» — образовательный проект, направленный на получение опыта и инструментов для построения модели себя, как отдельно взятого человека",
    },
    {
        name: "ИДПО",
        isTop: false,
        link: "https://idposocial.ru",
        image: Idpo,
        description: "Программа «Московское долголетие. Технологии обучения старшего поколения»",
    },
];
