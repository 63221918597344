import { flattenObject } from "@/utils/flattedObject";

export const objectToFormData = (obj: { [key: string]: any }) => {
    const formData = new FormData();
    const flatObj = flattenObject(obj);
    Object.entries(flatObj).forEach(([key, val]) => {
        if (val instanceof Blob) {
            formData.append(key, val, (val as File).name);
        } else if (typeof val === "boolean") {
            formData.append(key, val ? "1" : "0");
        } else {
            formData.append(key, val ?? "");
        }
    });
    return formData;
};
