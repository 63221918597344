import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { ReactComponent as PlayIcon } from "./assets/ic_outlined_play.svg";
import VideoPlayer from "@/components/VideoPlayer";
import "./index.scss";

type Props = {
    mediaLink: string;
};

const SessionVideo: React.FC<Props> = ({ mediaLink }) => {
    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>
                <button className="SessionVideo__Trigger" type="button">
                    <PlayIcon />
                    Смотреть видео
                </button>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="SessionVideo__Overlay"/>
                <Dialog.Content className="SessionVideo__Content">
                    <VisuallyHidden.Root>
                        <Dialog.Title />
                    </VisuallyHidden.Root>
                    <VideoPlayer
                        className="SessionVideo__Iframe"
                        videoLink={mediaLink}
                        isWithHash
                    />
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default SessionVideo;
