import React, { useState } from "react";
import { ReactComponent as NotVisibleIcon } from "./assets/not-visible.svg";
import { ReactComponent as VisibleIcon } from "./assets/visible.svg";
import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string;
    type?: string;
    name?: string;
    id?: string;
    value?: string;
    placeholder?: string;
    autoComplete?: string;
    label?: string;
    error?: boolean;
    disabled?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FormInput: React.FC<Props> = ({
    type,
    className,
    name,
    id,
    value,
    placeholder,
    label,
    error = false,
    disabled = false,
    autoComplete,
    onBlur,
    onChange,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleChangePasswordVisible = (e: React.MouseEvent<HTMLButtonElement> ) => {
        e.stopPropagation();
        setShowPassword((prev) => !prev);
    };

    const inputClass = classNames("Inputs__Input", {
        "Inputs__Input--Error": error,
    });

    return (
        <div className={classNames(className, "Inputs")}>
            {label && (<label htmlFor={id} className="Inputs__Label">
                {label}
            </label>)}
            <div className="Inputs__Wrapper">
                {type !== "password" ? (
                    <input
                        className={inputClass}
                        type={type}
                        name={name}
                        id={id}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        disabled={disabled}
                        autoComplete={autoComplete}
                        data-lpingore="ignore"
                    />
                ) : (
                    <>
                        <input
                            className={inputClass}
                            type={showPassword ? "text" : "password"}
                            name={name}
                            id={id}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            disabled={disabled}
                            data-lpignore="true"
                            role="note"
                        />
                        <button
                            className="Inputs__ShowPassword"
                            type="button"
                            onClick={handleChangePasswordVisible}
                            tabIndex={-1}
                        >
                            {showPassword ? <VisibleIcon /> : <NotVisibleIcon />}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
