import React, { useContext, useState } from "react";
import ProgramHeading from "./ProgramHeading";
import OnCampusProgram from "./OnCampusProgram";
import OnlineProgram from "./OnlineProgram";
import { NavContext } from "context/NavContext";
import { useInView } from 'react-intersection-observer';
import { PROGRAM_HASH_ID } from "@/constants";
import './index.scss';

const ProgramSchedule: React.FC = () => {
    const [programFormat, setIsProgramFormat] = useState<"online" | "onCampus">("online");
    const { handleActiveSection } = useContext(NavContext);

    const { ref } = useInView({
        threshold: 0,
        onChange(inView) {
            if (inView) {
                handleActiveSection({ isProgram: true })
            } else {
                handleActiveSection({ isProgram: false })
            }
        },
    });


    return (
        <section className="ProgramSchedule" id={PROGRAM_HASH_ID} ref={ref}>
                <ProgramHeading
                    toggleProgram={setIsProgramFormat}
                    programFormat={programFormat}
                />
                {
                    programFormat === "onCampus" ?
                        <OnCampusProgram />
                        :
                        <OnlineProgram />
                }
        </section>
    )
}

export default ProgramSchedule;