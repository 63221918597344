import React, { useEffect, useRef } from "react";
import Inputmask from "inputmask";
import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string;
    id: string;
    name: string;
    label: string;
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isError?: boolean;
};

export const PhoneInput: React.FC<Props> = ({
    className,
    id,
    name,
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    isError = false,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!inputRef.current) return;

        const maskOptions = {
            mask: "+7 (999) 999-99-99",
        };

        Inputmask(maskOptions).mask(inputRef.current);
    }, []);

    return (
        <div className={classNames(className, "Inputs")}>
            <label htmlFor={id} className="Inputs__Label">
                {label}
            </label>
            <input
                className={classNames("Inputs__Input", {
                    "Inputs__Input--Error": isError,
                })}
                ref={inputRef}
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                data-lpignore="true"
            />
        </div>
    );
};
