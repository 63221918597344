import React from "react";
import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string,
    total: number,
    current: number,
}

const Counter: React.FC<Props> = ({
    className,
    total,
    current,
}) => {
    return (
        <div className={classNames("Counter", className)}>
            {current}<span className="Counter__Total">/{total}</span>
        </div>
    )
}

export default Counter;