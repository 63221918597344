import React from "react";
import classNames from "classnames";

import "./index.scss";

export type TextareaProps = {
    id?: string;
    name?: string;
    label: string;
    className?: string;
    placeholder?: string;
    error?: boolean;
    value?: string;
    disabled?: boolean;
    minLength: number;
    length: number;
    messageError?: string;
    onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const Textarea = (props: TextareaProps) => {
    const { id, name, label, className, placeholder, error, value, onBlur, onChange, disabled, minLength, length } =
        props;
    return (
        <div
            className={classNames(className, "Textarea", {
                "Textarea--Error": error,
                "Textarea--Disabled": disabled,
            })}
        >
            {label && (<label htmlFor={id} className="Textarea__Label">
                <span>{label}</span>
            </label>)}
            <div className="Textarea__WrapBlock">
                <textarea
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    minLength={minLength}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                ></textarea>

                {length < minLength && length > 0 && (
                    <div className="Textarea__Length-Message">
                        ({length} / {minLength})
                    </div>
                )}
            </div>
        </div>
    );
};

export default Textarea;
