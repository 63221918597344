import { NavLink } from "react-router-dom";
import { ReactComponent as LogoIcon } from "./icons/logo.svg";
import { ReactComponent as LogoMobile } from "./icons/logo-mobile.svg";
import useMedia from "@/hooks/useMedia";
import "./index.scss";

const Logo = () => {
    const { isMobile } = useMedia();

    return (
        <NavLink className="Logo" to="https://sberuniversity.ru/">
            {isMobile ? <LogoMobile className="Logo__icon" /> : <LogoIcon className="Logo__icon" />}
        </NavLink>
    );
};

export default Logo;
