import React from 'react';
import { ReactComponent as PrevIcon } from './assets/prev-icon.svg';
import './index.scss';

type Props = {
    toPrev: () => void;
}

const RegistrationPrevBtn: React.FC<Props> = ({ toPrev }) => {
    return (
        <span className='RegistrationPrevBtn'>
            <button
                className='RegistrationPrevBtn__Btn'
                onClick={toPrev}
            >
                <PrevIcon />
            </button>
        </span>
    )
}

export default RegistrationPrevBtn;