import { BannerContext } from "@/context/BannerContext";
import { useContext } from "react";


const useBanner = () => {
    const bannerContext = useContext(BannerContext);

    return {
        ...bannerContext
    }
}

export default useBanner;