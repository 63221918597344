import React from "react";
import { FormikErrorMessage, FormikErrorMessageProps } from "@/components/FormikErrorMessage";
import classNames from "classnames";

import "./FormikErrorWrap.scss";

export type FormikErrorWrap = React.PropsWithChildren<FormikErrorMessageProps>;

export const FormikErrorWrap: React.FC<FormikErrorWrap> = (props) => {
    const { children, className, ...restProps } = props;
    return (
        <div className={classNames(className, "FormikErrorWrap")}>
            <div className="FormikErrorWrap__Field">{children}</div>
            <FormikErrorMessage className="FormikErrorWrap__Error" {...restProps} />
        </div>
    );
};
