const delimiter = ".";

function isBuffer(obj: any) {
    return obj && obj.constructor && typeof obj.constructor.isBuffer === "function" && obj.constructor.isBuffer(obj);
}

export function flattenObject(target: any) {
    const output: { [key: string]: any } = {};

    function step(object: any, prev?: string) {
        Object.keys(object).forEach(function (key) {
            const value = object[key];
            const isarray = Array.isArray(value);
            const type = Object.prototype.toString.call(value);
            const isbuffer = isBuffer(value);
            const isobject = type === "[object Object]" || type === "[object Array]";

            const newKey = prev ? prev + delimiter + key : key;

            if (!isarray && !isbuffer && isobject && Object.keys(value).length) {
                return step(value, newKey);
            }

            output[newKey] = value;
        });
    }

    step(target);

    return output;
}
