import React, { useState } from "react";
import { recoverPasswordByEmail } from "@/api";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import Popup from "@/components/Popup";
import { toastNotifications } from "@/components/Toastify";
import RecoverPasword from "./components/RecoverPasword";
import RecoverPaswordTimer from "./components/RecoverPaswordTimer";
import "./index.scss";

export type PopupPasswordRecoveryProps = { isOpen: boolean; onClose: () => void; userEmail: string };

const PopupPasswordRecovery: React.FC<PopupPasswordRecoveryProps> = ({ isOpen, userEmail, onClose }) => {
    const [isShowTimer, setIsShowTimer] = useState(false);
    const [isSendingRefreshPassword, setIsSendingRefreshPassword] = useState(false);

    const [timerNow, setTimerNow] = useState(Date.now());

    const handleRecoveryPassword = async () => {
        try {
            setIsSendingRefreshPassword(true);
            await recoverPasswordByEmail({
                email: userEmail,
            });
            setIsShowTimer(true);
            setTimerNow(Date.now());
        } catch (e: any) {
            toastNotifications.error("Что-то пошло не так. Попробуйте еще раз");
        } finally {
            setIsSendingRefreshPassword(false);
        }
    };

    return (
        <Popup className="PopupPasswordRecovery" isPopupActive={isOpen} closePopup={onClose}>
            <div className="PopupPasswordRecovery__Wrap">
                <div className="PopupPasswordRecovery__Content" onClick={(evt) => evt.stopPropagation()}>
                    <button className="PopupPasswordRecovery__Close" onClick={onClose}>
                        <CloseIcon />
                    </button>
                    {isShowTimer ? (
                        <RecoverPaswordTimer
                            key={timerNow}
                            now={timerNow}
                            userEmail={userEmail}
                            disabled={isSendingRefreshPassword}
                            onResend={handleRecoveryPassword}
                        />
                    ) : (
                        <RecoverPasword
                            disabled={isSendingRefreshPassword}
                            onRecoveryPassword={handleRecoveryPassword}
                        />
                    )}
                </div>
            </div>
        </Popup>
    );
};

export default PopupPasswordRecovery;
