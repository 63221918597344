import React from "react";
import { ReactComponent as StubIcon } from './assets/stubIcon.svg';
import TitlePageAdmin from "@/components/TitlePageAdmin";
import Typography from "@/components/Typography";
import { format } from "date-fns";
import { ACCESS_TO_CERTIFICATE } from "@/constants/dates";
import { ru } from "date-fns/locale";
import DownloadCertificate from "./DownloadCertificate";
import useDownloadCertificate from "@/hooks/useDownloadCertificate";
import "./index.scss";

export const Certificates: React.FC = () => {
    const { isDownloadActive } = useDownloadCertificate();
    const formattedDate = format(ACCESS_TO_CERTIFICATE, 'dd MMMM', { locale: ru });

    return (
        <div className="Certificates">
            <TitlePageAdmin textDesktop="Сертификат участника" />
            <div className="Certificates__Content">
                {isDownloadActive ? (
                    <DownloadCertificate />
                ):(
                    <>
                        <StubIcon />
                        <Typography className="Certificates__Text" component="span" size="sm" weight="semiBold" color="dark">
                            Доступ к сертификату<br />откроется {formattedDate}
                        </Typography>
                    </>
                )}
            </div>
        </div>
    );
};
