import React from "react";
import Popup from "@/components/Popup";
import { ReactComponent as IconCameraGreen } from "./assets/camera-green.svg";
import { ReactComponent as IconGallery } from "./assets/gallery.svg";
import { ReactComponent as IconArrow } from "./assets/arrow.svg";
import "./index.scss";
import Typography from "@/components/Typography";
import Headline from "@/components/Headline";

type Props = {
    isOpen: boolean;
    togglePopup: () => void;
    onClickRefInput: () => void;
    onClickRefInputTouchDevice: () => void;
}

const PopupImageUpload: React.FC<Props> = ({
    isOpen,
    togglePopup,
    onClickRefInput,
    onClickRefInputTouchDevice,
}) => {
    return (
        <Popup
            className="PopupImageUpload"
            isPopupActive={isOpen}
            closePopup={togglePopup}
        >
            <div className="PopupImageUpload__Block">
                <Headline className="PopupImageUpload__Title" size="lg" level="h3" theme="dark">
                    Добавить аватар
                </Headline>
                <button
                    className="PopupImageUpload__ButtonTouchDevice"
                    onClick={onClickRefInput}
                >
                    <div className="PopupImageUpload__IconUploadWrap">
                        <IconGallery />
                    </div>
                    <Typography component="span" size="sm" weight="semiBold" color="dark">
                        Галерея
                    </Typography>
                    <div className="PopupImageUpload__IconUploadWrap PopupImageUpload__IconUploadWrap--Arrow">
                        <IconArrow />
                    </div>
                </button>
                <button
                    className="PopupImageUpload__ButtonTouchDevice"
                    onClick={onClickRefInputTouchDevice}
                >
                    <div className="PopupImageUpload__IconUploadWrap">
                        <IconCameraGreen />
                    </div>
                    <Typography component="span" size="sm" weight="semiBold" color="dark">
                        Камера
                    </Typography>
                    <div className="PopupImageUpload__IconUploadWrap PopupImageUpload__IconUploadWrap--Arrow">
                        <IconArrow />
                    </div>
                </button>
            </div>
        </Popup>
    );
};
export default PopupImageUpload;
