import React from "react";
import { SpeakerType } from "types/interfaces";
import SkeletonImg from "@/components/SkeletonImg";
import { useInView } from "react-intersection-observer";
import "./index.scss";

type Props = {
    children?: React.ReactNode;
    data: SpeakerType;
};

const SpeakerPopupContent: React.FC<Props> = ({ children, data }) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div ref={ref} className="SpeakerPopupContent__Wrapper">
            <div className="SpeakerPopupContent__Header">
                <div className="SpeakerPopupContent__ImgContainer">
                    <SkeletonImg
                        src={data.photo_url as string}
                        isInView={inView}
                        alt={data.name as string}
                        hash="L88N%@%gozMx?^9@jbV@?bS2$kV["
                    />
                </div>
                <div>
                    <h2 className="SpeakerPopupContent__Name">{data.fullName}</h2>
                    <p className="SpeakerPopupContent__Text">
                        {data.description}
                    </p>
                </div>
            </div>

            {data.details && (
                <div
                    className="SpeakerPopupContent__Details"
                    dangerouslySetInnerHTML={{ __html: data.details }}
                />
            )}

            {children}
        </div>
    );
};

export default SpeakerPopupContent;
