import React, { createContext, useEffect, useState } from "react";
import { Interval, DateTime } from 'luxon';
import { SessionDataType } from "@/types/interfaces";
import { ACCESS_EVENT } from "@/constants/dates";
import { isSameDay } from "date-fns";
import { toZonedTime } from 'date-fns-tz';

const checkDateIsEvent = () => {
    const currentDateUTC = new Date(); // user's local time
    const eventDateUTC = toZonedTime(new Date(ACCESS_EVENT), 'Europe/Moscow'); // UTC to local
    return isSameDay(currentDateUTC, eventDateUTC);
};

type Props = {
    children: React.ReactNode
}

type CurrentActiveStreamType = {
    streamType: string,
    title: string,
}

type ScheduleContextType = {
    onCampusProgram: SessionDataType[],
    onlineProgram: SessionDataType[],
    currentActiveStream: CurrentActiveStreamType[],
    isDayEvent: boolean,
}

const ScheduleContext = createContext<ScheduleContextType>({
    onCampusProgram: [],
    onlineProgram: [],
    currentActiveStream: [],
    isDayEvent: false,
})

const ScheduleProvider: React.FC<Props> = ({ children }) => {
    const [onCampusProgram, setOnCampusProgram] = useState<SessionDataType[]>([]);
    const [onlineProgram, setOnlineProgram] = useState<SessionDataType[]>([]);
    const [currentActiveStream, setCurrentActiveStream] = useState<CurrentActiveStreamType[]>([]);

    const isDayEvent = checkDateIsEvent();

    useEffect(() => {
        const loadProgram = async () => {
            const { PROGRAM } = await import('@/constants/programSchedule');
            if (PROGRAM) {
                setOnCampusProgram(PROGRAM.onCampus);
                setOnlineProgram(PROGRAM.online);
            }
        };

        loadProgram();
    }, []);

    useEffect(() => {
        if (!onlineProgram.length || !isDayEvent) return;

        const checkSchedule = () => {
            const currentTime = DateTime.local(); // Current time in the user's local timezone

            const streamType: CurrentActiveStreamType[] = [];

            setOnlineProgram(prev => {
                return prev.map(session => {
                    const sessionStartTime = DateTime.fromFormat(session.startTime, 'HH:mm', { zone: 'Europe/Moscow' });
                    const sessionEndTime = DateTime.fromFormat(session.endTime, 'HH:mm', { zone: 'Europe/Moscow' });
                    const sessionInterval = Interval.fromDateTimes(sessionStartTime, sessionEndTime);

                    if (currentTime > sessionEndTime) {
                        // The current time is past the session end time
                        return {
                            ...session,
                            streamingStatus: "finished"
                        }

                    } else if (sessionInterval.contains(currentTime)) {

                        const data: CurrentActiveStreamType = {
                            streamType: session.type,
                            title: session.title[0],
                        }

                        streamType.push(data);

                        // The current time is within the session interval
                        return {
                            ...session,
                            streamingStatus: "active"
                        }
                    } else {
                        // the session not started yet
                        return session;
                    }
                })
            })

            setCurrentActiveStream(streamType)
        };

        // run once when first time opens
        checkSchedule();

        // Run the function every minute 60000
        const interval = setInterval(checkSchedule, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [onlineProgram.length]);

    return (
        <ScheduleContext.Provider value={{ onCampusProgram, onlineProgram, isDayEvent, currentActiveStream }}>
            {children}
        </ScheduleContext.Provider>
    )
}

export { ScheduleContext, ScheduleProvider }
