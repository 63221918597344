import React from "react";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as ExitIcon } from "./assets/exit-icon.svg";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import { ReactComponent as CloseIcon } from "./assets/close-icon.svg";
import { NavLink } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import Avatar from "@/components/Avatar";
import { useUser } from "@/context/UserContext";
import { routesNavigationProfile } from "@/constants/navigations";
import { useSignOut } from "react-auth-kit";
import Headline from "@/components/Headline";
import useMedia from "@/hooks/useMedia";
import Typography from "@/components/Typography";
import "./index.scss";

const ProfileBtn: React.FC = () => {
    const signOut = useSignOut();
    const { isDesktop } = useMedia();
    const { userInfo } = useUser();

    const handleClickLogout = () => {
        signOut();
    };

    const renderMenu = routesNavigationProfile.map((el, index) => {
        return (
                <li key={index} className="ProfileBtn__DropdownItem">
                    <Popover.Close asChild>
                        <NavLink
                            className="ProfileBtn__DropdownLink"
                            to={el.route}
                            end
                        >
                            <el.icon className="ProfileBtn__DropdownIcon"/>
                            {el.label}
                            <span className="ProfileBtn__DropdownArrowRight">
                                <ArrowRight />
                            </span>
                        </NavLink>
                    </Popover.Close>
                </li>
        );
    });

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className="ProfileBtn">
                    <Avatar
                        src={userInfo?.avatar_url}
                        name={userInfo?.name}
                        size={isDesktop ? 52 : 32}
                    />
                    <ArrowDown className="ProfileBtn__TriggerIcon" />
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    className="ProfileBtn__Dropdown"
                    sideOffset={isDesktop ? 8 : 16}
                    collisionPadding={isDesktop ? 16 : 0}
                    align="end"
                >
                    <div className="ProfileBtn__DropdownMobileHeader">
                        <Typography size="xlg" weight="semiBold" color="dark">
                            Личный кабинет
                        </Typography>
                        <Popover.Close>
                            <CloseIcon />
                        </Popover.Close>
                    </div>
                    <div className="ProfileBtn__DropdownHeader">
                        <Avatar
                            className="ProfileBtn__Avatar"
                            src={userInfo?.avatar_url}
                            name={userInfo?.name}
                            size={isDesktop ? 56 : 96}
                        />
                        <Headline
                            className="ProfileBtn__DropdownUsername"
                            level="h3"
                            size="xsm"
                        >
                            {userInfo?.surname} {userInfo?.name}
                        </Headline>
                    </div>
                    <ul className="ProfileBtn__DropdownList">
                        {renderMenu}
                        <div className="ProfileBtn__DropdownDivider"></div>
                        <button
                            className="ProfileBtn__DropdownBtn"
                            onClick={handleClickLogout}
                        >
                            <ExitIcon className="ProfileBtn__DropdownIcon"/>
                            <span className="ProfileBtn__DropdownBtnText">
                                Выйти
                            </span>
                        </button>
                    </ul>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

export default ProfileBtn;
