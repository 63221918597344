import React, { PropsWithChildren } from 'react';
import { ReactComponent as Check } from "./assets/check.svg";
import { ReactComponent as CheckMobile } from "./assets/check-mobile.svg";
import classNames from "classnames";
import useMedia from '@/hooks/useMedia';
import './index.scss';

type Props = {
    name: string;
    id: string;
    value?: string;
    text?: string;
    className?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    checked: boolean;
    isError?: boolean;
    size?: "lg" | "sm";
}

const Checkbox: React.FC<PropsWithChildren<Props>> = ({
    children,
    className,
    name,
    text,
    value,
    id,
    onChange,
    onBlur,
    checked = false,
    isError = false,
    size = 'lg',
}) => {
    const { isDesktop } = useMedia();

    return (
        <div className={classNames('Checkbox', className, {
            ['Checkbox--Error']: isError,
        })}>
            <div className='Checkbox__InputWrapper'>
                <input
                    className={classNames("Checkbox__Input", {
                        [`Checkbox__Input--${size}`]: size,
                    })}
                    type="checkbox"
                    name={name}
                    id={id}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={checked}
                />
                <label className='Checkbox__CheckIcon' htmlFor={id}>
                    {isDesktop ? <Check /> : <CheckMobile />}
                </label>
            </div>
            <label className="Checkbox__Label" htmlFor={id}>
                {text || children}
            </label>
        </div>
    );
};

export default Checkbox;