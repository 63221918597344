import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useIsAuthenticated, useSignOut } from "react-auth-kit";

import { userInformations } from "@/api";
import { UserData } from "@/types/interfaces";
import { toastNotifications } from "@/components/Toastify";
import { MESSAGES } from "@/constants/messages";

type UserContextType = {
    userInfo: UserData | undefined;
    setUserInfo: (data: UserData | undefined) => void;
    isLoading: boolean;
};

export const UserContext = createContext<UserContextType>({
    userInfo: undefined,
    setUserInfo: () => {},
    isLoading: true,
});

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const signOut = useSignOut();
    const authentication = useIsAuthenticated();
    const isAuth = authentication();

    const [userInfo, setUserInfo] = useState<UserData>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const user = await userInformations();

                if (!user.info) {
                    toastNotifications.error(MESSAGES.NO_USER);
                    throw new Error(MESSAGES.NO_USER);
                };

                setUserInfo(user);
            } catch (err) {
                console.error("[UserContext]:", err);
                signOut();
            } finally {
                setIsLoading(false);
            }
        };

        if (isAuth) {
            getUserInfo();
        } else {
            setUserInfo(undefined);
            setIsLoading(false);
        }
    }, [isAuth]);

    return <UserContext.Provider value={{ userInfo, setUserInfo, isLoading }}>{children}</UserContext.Provider>;
};
