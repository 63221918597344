import * as yup from "yup";
import { errors } from "@constants/messages";
import { MESSAGE_REFUND_LENGTH } from "@constants/validations";

export const messageRefundSchema = yup.object({
    userMessageRefund: yup
        .string()
        .required(errors.required)
        .min(MESSAGE_REFUND_LENGTH, `Необходимо ввести минимум ${MESSAGE_REFUND_LENGTH} символов`),
});
