import * as yup from "yup";
import { PASSWORD_REQUIREMENT } from "@constants/validations";
import { errors } from "@constants/messages";

export const changePasswordSchema = yup.object({
    old_password: yup.string().required(errors.required),
    new_password: yup.string().matches(PASSWORD_REQUIREMENT, errors.password).required(errors.required),
    new_password_confirmation: yup
        .string()
        .required(errors.required)
        .oneOf([yup.ref("new_password")], errors.password_confirmation),
});
