import React from "react";
import classNames from "classnames";
import "./index.scss";

const headings = ["h1", "h2", "h3", "h4", "h5", "h6"];

type Props = {
    level?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    size?: "xxlg" | "xlg" | "lg" | "md" | "sm" | "xsm";
    children: React.ReactNode;
    theme?: "light" | "dark";
    isGradient?: boolean;
    isCentered?: boolean;
    className?: string;
};

const Headline: React.FC<Props> = ({
    level = "h2",
    size = "xlg",
    children,
    theme = "dark",
    isGradient = false,
    isCentered = false,
    className,
}) => {
    const Tag = headings.includes(level) ? level : "h2";

    return (
        <Tag
            className={classNames({
                Headline: true,
                [`${className}`]: className,
                "Headline--Gradient": isGradient,
                [`Headline--${theme}`]: theme,
                [`Headline--${size}`]: size,
                [`Typography--Centered`]: isCentered,
            })}
        >
            {children}
        </Tag>
    );
};

export default Headline;
