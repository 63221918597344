import { Schema, TestConfig, ValidationError } from "yup";

export const yupTestScheme = async (schema: Schema, value: any, options: any) => {
    try {
        await schema.validate(value, options);
    } catch (error) {
        if (error instanceof ValidationError) {
            // for some reason the original error's "path" is empty
            throw options.context.createError({ message: error.message });
        }
        throw error;
    }
    return true;
};

export const yupSequence = <T>(name: string, ...schemas: ReadonlyArray<Schema>): TestConfig<T> => {
    return {
        name,
        test: async (value, context) => {
            for (const schema of schemas) {
                await yupTestScheme(schema, value, { context });
            }
            return true;
        },
    };
};
