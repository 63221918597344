import React from 'react';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { ReactComponent as BubbleTailLeft } from './assets/bubble-tail-left.svg';
import { ReactComponent as BubbleTailRight } from './assets/bubble-tail-right.svg';
import { ReactComponent as BubbleTailLeftMobile } from './assets/bubble-tail-left-mobile.svg';
import { ReactComponent as BubbleTailRightMobile } from './assets/bubble-tail-right-mobile.svg';
import { useInView, useAnimation, motion } from "framer-motion";
import classNames from 'classnames';
import useMedia from '@/hooks/useMedia';
import './index.scss';

type Props = {
    className?: string;
    id?: string;
    side?: 'right' | 'left';
    theme?: 'white' | 'dark' | 'green';
    hasTail?: boolean;
    hasGlow?: boolean;
    isWide?: boolean;
    isContentFit?: boolean;
    glowColor?: 'red' | 'green';
}

const ChatBubble: React.FC<PropsWithChildren<Props>> = ({
    className,
    id,
    side = 'left',
    theme = 'dark',
    isWide = false,
    isContentFit = false,
    hasGlow = false,
    hasTail = true,
    glowColor = 'green',
    children
}) => {
    const { isDesktop } = useMedia();
    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, { once: true, amount: 0.3});

    const visibleControls = useAnimation();

    useEffect(() => {
        if (!isInView) return;

        visibleControls.start('visible');
    }, [isInView, visibleControls])

    return(
        <motion.div
            ref={ref}
            id={id}
            variants={{
                hidden: { opacity: 0, translateY: 50},
                visible: { opacity: 1, translateY: 0 },
            }}
            initial="hidden"
            animate={visibleControls}
            transition={{ease: 'easeInOut', duration: 0.8}}
            className={classNames('ChatBubble', {
                [`ChatBubble--${theme}`]: theme,
                [`ChatBubble--${side}`]: side,
                "ChatBubble--Wide": isWide,
                "ChatBubble--Fit": isContentFit,
            })}
        >
            {hasTail && side === 'left' && (
                isDesktop
                ? <BubbleTailLeft className='ChatBubble__Tail ChatBubble__Tail--Left' />
                : <BubbleTailLeftMobile className='ChatBubble__Tail ChatBubble__Tail--Left' />
            )}
            <div
                className={classNames(className, 'ChatBubble__Content', {
                    [`ChatBubble__Content--${glowColor}`]: glowColor && hasGlow,
                })}
            >
                {children}
            </div>
            {hasTail && side === 'right' && (
                isDesktop
                ? <BubbleTailRight className='ChatBubble__Tail ChatBubble__Tail--Right' />
                : <BubbleTailRightMobile className='ChatBubble__Tail ChatBubble__Tail--Right' />
            )}
        </motion.div>
    )
}

export default ChatBubble;