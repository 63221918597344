import React from "react";
import Button from "@/components/Button";
import EmojiSmile from "@/assets/icons/emoji-smile.png";
import { ReactComponent as CloseBtn } from "../assets/close-btn.svg";
import { ReactComponent as SuccessPaymentIcon } from "../assets/success-payment-icon.svg";
import { ReactComponent as FailedPaymentIcon } from "../assets/failed-payment-icon.svg";
import { ReactComponent as WarningIcon } from "../assets/warning-icon.svg";
import classNames from "classnames";
import "./index.scss";

type Props = {
    status: "accepted" | "success" | "failed";
};

const PaymentToast: React.FC<Props> = ({ status }) => {
    const title =
        status === "failed"
            ? "К сожалению, оплата\nне прошла"
            : status === "success"
                ? "Оплата прошла успешно"
                : "Ваша заявка принята";

    const failedText = () => {
        return (
            <p className="PaymentToast__Text">
                Вы по преждему можете участвоват<br/>в онлайн-формате
                <img
                    className="PaymentToast__Emoji"
                    src={EmojiSmile}
                    alt="emoji smile"
                    width={14}
                    height={14}
                />
            </p>
        );
    };

    const successText = () => {
        return (
            <p className="PaymentToast__Text">
                {"Мы отправили вам подтверждение\nна электронную почту"}
            </p>
        );
    };

    const acceptedText = () => {
        return (
            <div className="PaymentToast__TextContainer">
                <p className="PaymentToast__Text">
                    Пока ваша заявка на рассмотрении — вы будете записаны на
                    онлайн-участие. Как только мы рассмотрим заявку — с вами
                    свяжется менеджер для проведения оплаты.
                </p>
            </div>
        );
    };

    return (
        <>
            <div className="PaymentToast">
                <div className={classNames("PaymentToast__IconContainer", {
                    [`PaymentToast__IconContainer--${status}`]: status,
                })}>
                    {status === "failed" ? <FailedPaymentIcon /> : <SuccessPaymentIcon />}
                </div>
                <h2 className="PaymentToast__Title">{title}</h2>

                {status === "accepted"
                    ? acceptedText()
                    : status === "success"
                        ? successText()
                        : failedText()}

                <div className="PaymentToast__Actions">
                    {status === "accepted" && (
                        <span className="PaymentToast__WarningText">
                            <WarningIcon />
                            После оплаты ваш тариф будет изменён
                        </span>
                    )}
                    <Button
                        className="PaymentToast__Btn"
                        tag="button"
                        text="Хорошо"
                        size="lg"
                        type="colored"
                        isWide
                        gradient
                    />
                </div>
            </div>
            <CloseBtn className="Payment__CloseBtn" />
        </>
    );
};

export default PaymentToast;
