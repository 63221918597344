import React from 'react';
import Typography from '@/components/Typography';
import ChatBubble from '@/components/ChatBubble';
import { ReactComponent as ListDecorationGreen } from './assets/list-decoration-green.svg';
import { ReactComponent as ListDecorationRed } from './assets/list-decoration-red.svg';
import { ReactComponent as DocumentIcon} from './assets/li_document.svg';
import SberDeviceIcon from './assets/sber-device.png';
import SberDeviceMobileIcon from './assets/sber-device-mobile.png';
import SberEducationIcon from './assets/sber-education.png';
import SberEducationMobileIcon from './assets/sber-education-mobile.png';
import Headline from '@/components/Headline';
import Link from '@/components/Link';
import { AWARDS_HASH_ID, COMPETITION_FAQ_HASH_ID, NOIMATIONS_HASH_ID, PARTICIPATES_HASH_ID } from '@/constants';
import useMedia from '@/hooks/useMedia';
import classNames from 'classnames';
import './index.scss';

const AWARDS_LIST = [
    'Приглашение на флагманскую конференцию СберУниверситета «Больше чем обучение», которая состоится 25 октября 2024\u00A0года;',
    'Участие в выставке EdTech\u2011решений в рамках конференции;',
    'Признание образовательного сообщества.',
]

const NOT_CONSIDERING = [
    'Физические решения без\u00A0электронной версии, например, настольные игры;',
    'Технические задания, решения без\u00A0реализации, не\u00A0имеющие коммерческих внедрений;',
    'Профили экспертов без\u00A0указания конкретных решений.',
]

const NOMINATION_LIST = [
    {
        title: 'EdTech: шаг в будущее',
        description: 'Рассматривали готовые EdTech-решения, которые помогают создавать или проводить обучение и улучшают образовательный опыт человека.',
        list: [
            'Сервисы для тестирований, совместной работы и\u00A0геймификации',
            'Приложения для формирования личностного профиля студента',
            'Конструкторы для тренажеров и\u00A0симуляций',
            'AR/VR-сервисы',
            'Видеоредакторы и площадки для\u00A0трансляций',
        ],
        links: [
            {
                text: 'Изучить критерии',
                href: 'https://sberuniversity.ru/upload/edtech/EdTech_%20шаг_в_будущее.pdf',
                icon: <DocumentIcon />,
            },
        ],
        label: null,
    },
    {
        title: 'Цифровая платформа возможностей',
        description: 'Рассматривали решения, которые обеспечат создание и поддержку обучения в комплексе: оценка слушателей, мотивация, конструктор курсов, база знаний, система аналитики и управления обучением, посттренинг и др.',
        list: [
            'LMS-платформы',
            'LXP-платформы',
        ],
        links: [
            {
                text: 'Изучить критерии',
                href: 'https://sberuniversity.ru/upload/edtech/Цифровая_платформа_возможностей.pdf',
                icon: <DocumentIcon />,
            },
        ],
        label: null,
    },
    {
        title: 'AI-прорыв',
        description: 'Выбирали лучшее решение, в\u00A0которое интегрирован искусственный интеллект для автоматизации образовательных процессов: проверка и создание заданий, обратная связь, аналитика, персонализированные рекомендации и др.',
        list: [
            'ИИ для оценки навыков и\u00A0профессиональных компетенций',
            'Чат-боты на основе ИИ',
            '«Умные» инструменты для\u00A0сокращения текстов и\u00A0генерации тезисов',
            'ИИ для рекомендаций по\u00A0развитию',
        ],
        links: [
            {
                text: 'Изучить критерии',
                href: 'https://sberuniversity.ru/upload/edtech/AI-прорыв.pdf',
                icon: <DocumentIcon />,
            },
        ],
        label: {
            mobile: SberDeviceMobileIcon,
            desktop: SberDeviceIcon,
        },
    },
    {
        title: 'EduHacking',
        description: 'Выбирали лучшую образовательную программу: рассматривали разработки как для сотрудников одной компании, так и рыночные решения. Важен фокус на образовательный опыт и продуманное использование современных образовательных методик.\nПринимали программы любого формата — онлайн, очные, гибридные.',
        list: [
            'Развитие soft-компетенций',
            'Digital-навыки для не\u2011digital профессионалов',
            'Развитие лидерских и\u00A0управленческих компетенций',
            'Программы по развитию\nHR-компетенций',
        ],
        links: [
            {
                text: 'Изучить критерии',
                href: 'https://sberuniversity.ru/upload/edtech/EduHaсking.pdf',
                icon: <DocumentIcon />,
            },
        ],
        label: {
            mobile: SberEducationMobileIcon,
            desktop: SberEducationIcon,
        },
    },
]

const CompetitionFAQ: React.FC = () => {
    const { isDesktop } = useMedia();

    const renderAwardsList = AWARDS_LIST.map((el, index) => (
        <li key={index} className='CompetitionFAQ__ListItem'>
            <ListDecorationGreen className='CompetitionFAQ__ListItemIcon' />
            <Typography size='lg' weight='regular' color='light' opacity={0.9}>
                {el}
            </Typography>
        </li>
    ));

    const renderNotConsideriongList = NOT_CONSIDERING.map((el, index) => (
        <li key={index} className='CompetitionFAQ__ListItem'>
            <ListDecorationRed className='CompetitionFAQ__ListItemIcon' />
            <Typography size='lg' weight='regular' color='light' opacity={0.9}>
                {el}
            </Typography>
        </li>
    ));

    const renderNominationList = NOMINATION_LIST.map((el, index) => (
        <ChatBubble
            key={index}
            className={classNames('CompetitionFAQ__Nomination',
                {
                    'CompetitionFAQ__Nomination--HasIcon': !!el.label?.mobile
                }
            )}
            side='right'
            glowColor='green'
            hasTail={(NOMINATION_LIST.length - 1) === index}
            hasGlow
            isWide
        >
            {el.label && <img className='CompetitionFAQ__NominationSberDevice' src={isDesktop ? el.label.desktop : el.label.mobile} alt='sber label' />}
            <div className='CompetitionFAQ__NominationHeader'>
                <Typography className='CompetitionFAQ__NominationNum' component='span' size='md' weight='regular' color='light'>
                    Номинация №{index + 1}
                </Typography>
                <Headline className='CompetitionFAQ__NominationTitle' size='md' level='h4' theme='light'>
                    {el.title}
                </Headline>
                <Typography size='md' weight='regular' color='light' opacity={0.4}>
                    {el.description}
                </Typography>
            </div>
            <div className='CompetitionFAQ__NominationList'>
                {el.list.map((il, index) => (
                    <li key={index} className='CompetitionFAQ__ListItem'>
                        <ListDecorationGreen className='CompetitionFAQ__ListItemIcon CompetitionFAQ__ListItemIcon--Small' />
                        <Typography size='md' weight='semiBold' color='light'>
                            {il}
                        </Typography>
                    </li>
                ))}
            </div>
            <div className='CompetitionFAQ__NominationLinks'>
                {el.links.map((link, index) => (
                    <Link key={index} text={link.text} href={link.href} icon={link.icon} />
                ))}
            </div>
        </ChatBubble>
    ));

    return(
        <section className='CompetitionFAQ' id={COMPETITION_FAQ_HASH_ID}>
            {/* question #1 */}
            <ChatBubble theme='white' id={PARTICIPATES_HASH_ID} isContentFit>
                <Typography size='lg' weight='semiBold' color='dark' opacity={0.9}>
                    Кто мог принять участие?
                </Typography>
            </ChatBubble>
            <ChatBubble side='right' glowColor='green' hasGlow>
                <Typography size='lg' weight='regular' color='light' opacity={0.9}>
                    Ежегодно в конкурсе принимают участие как
                    {' '}
                    зрелые технологические компании, так и стартапы.
                </Typography>
            </ChatBubble>

            {/* question #2 */}
            <ChatBubble theme='white' id={AWARDS_HASH_ID} isContentFit>
                <Typography size='lg' weight='semiBold' color='dark' opacity={0.9}>
                    Что получат финалисты{!isDesktop ? <br /> : ' '}и победители?
                </Typography>
            </ChatBubble>
            <div className='CompetitionFAQ__Group'>
                <ChatBubble side='right' glowColor='green' hasTail={false} hasGlow>
                    <ul className='CompetitionFAQ__List'>
                        {renderAwardsList}
                    </ul>
                </ChatBubble>
                <ChatBubble side='right' theme='green'>
                    <Typography size='lg' weight='semiBold' color='light' opacity={0.9}>
                        Победители будут объявлены на&nbsp;торжественной
                        {' '}
                        церемонии награждения в день конференции.
                    </Typography>
                </ChatBubble>
            </div>

            {/* question #3 */}
            <ChatBubble theme='white' id={NOIMATIONS_HASH_ID} isContentFit>
                <Typography size='lg' weight='semiBold' color='dark' opacity={0.9}>
                    Какие номинации конкурса?
                </Typography>
            </ChatBubble>
            <div className='CompetitionFAQ__Group'>
                <ChatBubble side='right' glowColor='green' hasTail={false} hasGlow>
                    <Typography size='lg' weight='regular' color='light' opacity={0.9}>
                        Вы могли подать заявку сразу в несколько номинаций:
                    </Typography>
                </ChatBubble>
                {renderNominationList}
            </div>

            {/* question #4 */}
            <ChatBubble theme='white' isContentFit>
                <Typography size='lg' weight='semiBold' color='dark' opacity={0.9}>
                    Какие решения{!isDesktop ? <br /> : ' '}не рассматривали?
                </Typography>
            </ChatBubble>
            <ChatBubble side='right' glowColor='red' hasGlow>
                <ul className='CompetitionFAQ__List'>
                    {renderNotConsideriongList}
                </ul>
            </ChatBubble>

            {/* question #5 */}
            <ChatBubble theme='white' isContentFit>
                <Typography size='lg' weight='semiBold' color='dark' opacity={0.9}>
                    Кто и как определит{!isDesktop ? <br /> : ' '}победителей?
                </Typography>
            </ChatBubble>
            <ChatBubble side='right' glowColor='green' hasGlow>
                <Typography size='lg' weight='regular' color='light'>
                    Победителей в каждой номинации определяет жюри — эксперты отрасли. Жюри анализирует заявки по критериям, которые указаны в описании номинаций.
                </Typography>
            </ChatBubble>
        </section>
    )
}

export default CompetitionFAQ;