import React from "react";
import Container from "@/components/Container";
import Typography from "@/components/Typography";
import FooterDecoration from "./assets/footer-decoration.png";
import FooterDecorationMobile from "./assets/footer-decoration-mobile.png";
import { ReactComponent as ArrowIcon } from "./assets/arrow-down.svg";
import { useFooter } from "@/hooks/useFooter";
import FooterBlock from "./FooterBlock";
import useShare from "@/hooks/useShare";
import useMedia from "@/hooks/useMedia";
import routes from "@/routes";
import FooterHashlinks from "./FooterHashlinks";
import * as Accordion from "@radix-ui/react-accordion";
import { SUPPORT_EMAIL, SUPPORT_TELEGRAM } from "@/constants/common";
import "./index.scss";

const Footer: React.FC = () => {
    const { isDesktop } = useMedia();
    const { DOC_LINKS, CONFERENCE_LINKS, CONTEST_LINKS } = useFooter();
    const { VISTI_LINKS_FOOTER } = useShare();

    const renderLinks = DOC_LINKS.map((el, index) => (
        <li key={index}>
            <a
                className="Footer__DocLink"
                href={el.link}
                target="_blank"
                rel="noreferrer"
            >
                {el.text}
            </a>
        </li>
    ));

    return (
        <footer className="Footer">
            <Container className="Footer__Inner">
                <div className="Footer__Upper">
                    {isDesktop ? (
                        <>
                            <div className="Footer__UpperAddress">
                                <FooterBlock title="КОНФЕРЕНЦИЯ">
                                    <FooterHashlinks
                                        hashLinks={CONFERENCE_LINKS}
                                        baseUrl={`${routes.conference}`}
                                    />
                                </FooterBlock>
                            </div>
                            <div className="Footer__UpperAddress">
                                <FooterBlock title="КОНКУРС EDTECH-КОМПАНИЙ">
                                    <FooterHashlinks
                                        hashLinks={CONTEST_LINKS}
                                        baseUrl={`${routes.contest}/`}
                                    />
                                </FooterBlock>
                            </div>
                        </>
                    ) : (
                        <Accordion.Root type="single" collapsible>
                            <Accordion.Item value="conferenceHashLinks">
                                <Accordion.Header>
                                    <Accordion.Trigger asChild>
                                        <div className="Footer__AccordionTitle">
                                            КОНФЕРЕНЦИЯ{" "}
                                            <ArrowIcon className="Footer__AccordionIcon" />
                                        </div>
                                    </Accordion.Trigger>
                                </Accordion.Header>
                                <Accordion.Content>
                                    <FooterHashlinks
                                        hashLinks={CONFERENCE_LINKS}
                                        baseUrl={`${routes.conference}`}
                                    />
                                </Accordion.Content>
                            </Accordion.Item>
                            <Accordion.Item value="contestHashLinks">
                                <Accordion.Header>
                                    <Accordion.Trigger asChild>
                                        <div className="Footer__AccordionTitle">
                                            КОНКУРС EDTECH-КОМПАНИЙ{" "}
                                            <ArrowIcon className="Footer__AccordionIcon" />
                                        </div>
                                    </Accordion.Trigger>
                                </Accordion.Header>
                                <Accordion.Content>
                                    <FooterHashlinks
                                        hashLinks={CONTEST_LINKS}
                                        baseUrl={`${routes.contest}/`}
                                    />
                                </Accordion.Content>
                            </Accordion.Item>
                        </Accordion.Root>
                    )}
                    <div className="Footer__UpperAddress">
                        <FooterBlock title="НАШ АДРЕС">
                            {isDesktop
                                ? "143581, Россия, Московская обл., г.о. Истра,\nд. Аносино, ул. Университетская, вл. 11"
                                : "143581, Россия, Московская обл., г.о. Истра, д. Аносино, ул. Университетская, вл. 11"}
                        </FooterBlock>
                        <FooterBlock title="ПО ВСЕМ ВОПРОСАМ:">
                            <div className="Footer__UpperAddressLinks">
                                <div>{SUPPORT_EMAIL}</div>
                                <div>
                                    <a
                                        className="Footer__UpperAddressLink"
                                        href={SUPPORT_TELEGRAM}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        t.me/bcho_24
                                    </a>
                                </div>
                            </div>
                        </FooterBlock>
                        <div className="Footer__UpperShareLinks">
                            {VISTI_LINKS_FOOTER.map((el, index) => (
                                <div
                                    key={index}
                                    className="Footer__UpperShareLink"
                                    onClick={el.action}
                                >
                                    {el.icon}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="Footer__Lower">
                    <Typography
                        className="Footer__LowerText"
                        color="light"
                        size="xs"
                        weight="regular"
                        opacity={0.48}
                    >
                        {isDesktop
                            ? "© 2012–2024 АНО ДПО “Корпоративный университет Сбербанка”\nЛицензия на осуществление образовательной деятельности\nот 2 апреля 2014 года. Регистрационный номер — 034970"
                            : "© 2012–2024 АНО ДПО “Корпоративный\nуниверситет Сбербанка” Лицензия\nна осуществление образовательной\nдеятельности от 2 апреля 2014 года.\nРегистрационный номер — 034970"}
                    </Typography>
                    <ul className="Footer__DocLinks">{renderLinks}</ul>
                </div>
            </Container>
            <picture>
                <source
                    srcSet={FooterDecorationMobile}
                    media="(max-width: 767px)"
                />
                <source srcSet={FooterDecoration} media="(min-width: 768px)" />
                <img
                    className="Footer__Decoration"
                    src={FooterDecoration}
                    alt="Footer Decoration"
                    loading="lazy"
                />
            </picture>
        </footer>
    );
};

export default Footer;
