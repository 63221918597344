import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { useState } from "react"
import { Blurhash } from "react-blurhash";
import { AnimatePresence, motion } from 'framer-motion';
import './index.scss';

type Props = {
    className?: string,
    src: string,
    alt: string,
    hash?: string,
    loading?: "lazy" | "eager"
    isShowChildren?: boolean;
    isInView: boolean;
}

const SkeletonImg: React.FC<PropsWithChildren<Props>> = ({
    children,
    className,
    src,
    alt,
    hash,
    loading = "eager",
    isShowChildren = false,
    isInView = false,
}) => {
    const [isLoading, setIsloading] = useState(true);

    const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { complete } = e.target as HTMLImageElement;

        if (complete) {
            setIsloading(false)
        }
    }

    return (
        <div className="SkeletonImg__Wrapper">
            <AnimatePresence>
                {isLoading && hash && (
                    <motion.div
                        className="SkeletonImg__Overlay"
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Blurhash
                            hash={hash}
                            width="100%"
                            height="100%"
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            {!isShowChildren && isInView && (
                <img
                    className={classNames(className, "SkeletonImg")}
                    src={src}
                    alt={alt}
                    loading={loading}
                    onLoad={handleLoad}
                />
            )}
            {children}
        </div>
    )
}

export default SkeletonImg;