import React from 'react';
import ReactDOM from 'react-dom/client';
import './utils/sentry';
import App from './App';
import 'normalize.css'
import './index.scss';

/**
 * try fix https://rocketsentry.ru/organizations/rsc/issues/189169/?environment=production&project=94&referrer=project-issue-stream
 */
try {
    if (!window.localStorage) {
        window.localStorage = {
            _data: {},
            setItem: function (id, val) {
                this._data[id] = String(val);
            },
            getItem: function (id) {
                return this._data.hasOwnProperty(id) ? this._data[id] : null;
            },
            removeItem: function (id) {
                delete this._data[id];
            },
            clear: function () {
                this._data = {};
            },
        };
    }
} catch(e) {
    console.log(e);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
