import { ControlProps, CSSObjectWithLabel, OptionProps, StylesConfig } from "react-select";

export const selectStyles: StylesConfig = {
    control: (base: CSSObjectWithLabel, props: ControlProps) => ({
        ...base,
        height: "52px",
        width: "100%",
        padding: "0px 24px",
        borderRadius: "16px",
        fontSize: "16px",
        lineHeight: "24px",
        color: "white",
        cursor: "pointer",
        boxShadow: 'none',
        border: props.isFocused ? "1px solid #169B50" : "1px solid transparent",
        background: props.isFocused ? "white" : "#E8EDF3",

        "&:hover": {
            borderColor: "#169B50",
        },

        "@media (min-width: 992px)": {
            fontSize: "18px",
            borderRadius: "20px",
            height: "68px",
        },
    }),
    input: (base: CSSObjectWithLabel) => ({
        ...base,
        color: "black",
        fontSize: "16px",

        "@media (min-width: 992px)": {
            fontSize: "18px",
        },
    }),
    singleValue: (base: CSSObjectWithLabel) => ({
        ...base,
        color: "black",
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
        ...base,
        padding: "unset",
    }),
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
        ...base,
        display: "none",
    }),
    menu: (base: CSSObjectWithLabel) => ({
        ...base,
        backgroundColor: "#FFFFFF",
        padding: "8px",
        borderRadius: "20px",
        boxShadow: "0px 4px 40px 0px #00000033",
        color: "black",
    }),
    menuList: (base: CSSObjectWithLabel) => ({
        ...base,
        padding: "0px",
    }),
    option: (base: CSSObjectWithLabel, props: OptionProps) => ({
        ...base,
        borderRadius: "12px",
        fontSize: "16px",
        color: "black",
        padding: "12px 24px",
        backgroundColor: props.isSelected ? "#E8EDF3" : "transparent",

        "&:hover": {
            background: "#E8EDF3",
        },

        "@media (min-width: 992px)": {
            fontSize: "18px",
        },
    }),
};
