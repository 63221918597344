import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import useMenu from "@/hooks/useMenu";
import { HashLink } from "react-router-hash-link";
import scrollToSection from "@/utils/scrollToSection";
import useMedia from "@/hooks/useMedia";
import { NavContext } from "@/context/NavContext";
import "./index.scss";
import classNames from "classnames";

const NavBar: React.FC = () => {
    const { MENU_NAV_ITEMS } = useMenu();
    const { isMobile } = useMedia();
    const { activeSection } = useContext(NavContext);
    const { isProgram } = activeSection;

    const renderMenu = MENU_NAV_ITEMS.map((el, index) => {
        const hashLinkId = el.route.split("#").pop() as string;

        return (
            <li key={index} className="Nav__Item">
                {el.isHashLink ? (
                    <HashLink
                        key={index}
                        className={classNames("Nav__Link", {
                            active: isProgram,
                        })}
                        to={el.route}
                        scroll={() =>
                            scrollToSection(hashLinkId, isMobile ? 100 : 200)
                        }
                    >
                        {el.text}
                    </HashLink>
                ) : (
                    <NavLink
                        className={({ isActive }) =>
                            isActive && !isProgram ? "Nav__Link active" : "Nav__Link"
                        }
                        to={el.route}
                    >
                        {el.text}
                    </NavLink>
                )}
            </li>
        );
    });

    return (
        <nav className="Nav">
            <ul className="Nav__Items">{renderMenu}</ul>
        </nav>
    );
};

export default NavBar;
