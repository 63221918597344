import { useInfiniteQuery } from '@tanstack/react-query';
import { getExperts } from '@/api';
import { ProgramSpeaker } from '@/types';

export const useJury = () => {
    return useInfiniteQuery({
        queryKey: ['jury'],
        queryFn: ({ pageParam }) => getExperts({ page: pageParam }),
        initialPageParam: 1,
        getNextPageParam: ({ meta }) => {
            if (meta.current_page < meta.last_page) {
                return meta.current_page + 1;
            } else {
                return undefined;
            }
        },
        select: ({ pages }) => {
            return pages.map((page) => page.data).flat();
        },
    })
};

const SPEAKERS_PER_PAGE = 6;

export const useSpeakers = () => {
    return useInfiniteQuery({
        queryKey: ['speakers'],
        queryFn: async ({ pageParam }) => {
            const { SPEAKERS_LIST } = await import('@/constants/speakersBlockList')
            const speakersArray = Object.values(SPEAKERS_LIST) as ProgramSpeaker[];
            const start = (pageParam - 1) * SPEAKERS_PER_PAGE;
            const end = start + SPEAKERS_PER_PAGE;
            const pageData = speakersArray.slice(start, end);

            return {
                data: pageData,
                meta: {
                    current_page: pageParam,
                    last_page: Math.ceil(speakersArray.length / SPEAKERS_PER_PAGE),
                },
            };
        },
        initialPageParam: 1,
        getNextPageParam: ({ meta }) => {
            if (meta.current_page < meta.last_page) {
                return meta.current_page + 1;
            }
            return undefined;
        },
        select: ({ pages }) => {
            return pages.map((page) => page.data).flat();
        },
    });
};