import React, { useState } from "react";
import Container from "@/components/Container";
import Headline from "@/components/Headline";
import WinnerCard from "./components/WinnerCard";
import { winners_nomination_01, winners_nomination_02, winners_nomination_03, winners_nomination_04 } from "./dataWinners";
import ModalWinner from "./components/ModalWinner";
import { Winner } from "./types";
import ChatBubble from "@/components/ChatBubble";
import Typography from "@/components/Typography";
import { WINNERS_HASH_ID } from "@/constants";
import "./index.scss";

const Winners: React.FC = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [currentWinner, setIsCurrentWinner] = useState<Winner | undefined>();
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <section className="Winners" id={WINNERS_HASH_ID}>
            <Container className="Winners__Container">
                <Headline className="Winners__Title" level={"h2"} size="xlg" theme="light" isCentered>Победители</Headline>
                <div className="Winners__ListWrapper">
                    <ChatBubble
                        className="Winners__Nomination"
                        side='right'
                        glowColor='green'
                        hasTail={false}
                        hasGlow
                        isWide
                    >
                        <div className="Winners__NominationHeader">
                            <Typography component="span" size="md" color="gray" weight="regular">Номинация №1</Typography>
                            <Headline level="h3" size="sm" theme="light">EdTech: шаг в будущее</Headline>
                        </div>
                        <div className="Winners__List">
                            {winners_nomination_01?.map((winner) => (
                                <WinnerCard
                                    onClickCardWinner={openModal}
                                    winner={winner}
                                    key={winner.name}
                                    setWinner={setIsCurrentWinner}
                                />
                            ))}
                        </div>
                    </ChatBubble>
                    <ChatBubble
                        className="Winners__Nomination"
                        side='right'
                        glowColor='green'
                        hasTail={false}
                        hasGlow
                        isWide
                    >
                        <div className="Winners__NominationHeader">
                            <Typography component="span" size="md" color="gray" weight="regular">Номинация №2</Typography>
                            <Headline level="h3" size="sm" theme="light">Цифровая платформа возможностей</Headline>
                        </div>
                        <div className="Winners__List">
                            {winners_nomination_02?.map((winner) => (
                                <WinnerCard
                                    onClickCardWinner={openModal}
                                    winner={winner}
                                    key={winner.name}
                                    setWinner={setIsCurrentWinner}
                                />
                            ))}
                        </div>
                    </ChatBubble>
                    <ChatBubble
                        className="Winners__Nomination"
                        side='right'
                        glowColor='green'
                        hasTail={false}
                        hasGlow
                        isWide
                    >
                        <div className="Winners__NominationHeader">
                            <Typography component="span" size="md" color="gray" weight="regular">Номинация №3</Typography>
                            <Headline level="h3" size="sm" theme="light">AI-прорыв</Headline>
                        </div>
                        <div className="Winners__List">
                            {winners_nomination_03?.map((winner) => (
                                <WinnerCard
                                    onClickCardWinner={openModal}
                                    winner={winner}
                                    key={winner.name}
                                    setWinner={setIsCurrentWinner}
                                />
                            ))}
                        </div>
                    </ChatBubble>
                    <ChatBubble
                        className="Winners__Nomination"
                        side='right'
                        glowColor='green'
                        hasTail={false}
                        hasGlow
                        isWide
                    >
                        <div className="Winners__NominationHeader">
                            <Typography component="span" size="md" color="gray" weight="regular">Номинация №4</Typography>
                            <Headline level="h3" size="sm" theme="light">EduHacking</Headline>
                        </div>
                        <div className="Winners__List">
                            {winners_nomination_04?.map((winner) => (
                                <WinnerCard
                                    onClickCardWinner={openModal}
                                    winner={winner}
                                    key={winner.name}
                                    setWinner={setIsCurrentWinner}
                                />
                            ))}
                        </div>
                    </ChatBubble>
                </div>
            </Container>
            {currentWinner && (<ModalWinner isOpen={modalIsOpen} onRequestClose={closeModal} winner={currentWinner} />)}
        </section>
    );
};

export default Winners;
