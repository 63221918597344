import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";
import ByEmail from "./ByEmail";
import LinkSent from "./LinkSent";

const ResetPassword: React.FC = () => {
    const [linkIsSent, setLinkIsSent] = useState<boolean>(false);
    const [userEmail, setuserEmail] = useState<string>('')
    const navigate = useNavigate();

    const handlePrev = () => {
        navigate(-1)
    }

    const handleEmail = (email: string) => {
        setuserEmail(email)
    }

    return (
        <>
            <RegistrationPrevBtn toPrev={handlePrev} />
            {linkIsSent ?
                <LinkSent userEmail={userEmail}/>
                :
                <ByEmail handleLinkSending={() => setLinkIsSent(true)} handleEmail={handleEmail} />
            }
        </>
    )
}

export default ResetPassword;