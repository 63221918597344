import React, { useRef } from "react";
import useMedia from "@/hooks/useMedia";
import { useToggle } from "react-use";
import { toastNotifications } from "@/components/Toastify";
import PopupImageUpload from "./PopupImageUpload";
import { ReactComponent as IconCamera } from "./assets/camera.svg";
import classNames from "classnames";
import Typography from "@/components/Typography";
import "./index.scss";

const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/jpg"];
const MAX_SIZE = 5 * 1024 * 1024;

export type ImageInputProps = {
    className?: string;
    value?: File | null;
    onChange?: (file?: File | null) => void;
};

const ImageInput = ({ className, value, onChange }: ImageInputProps) => {
    const inputFileRef = useRef<HTMLInputElement>(null);
    const inputFileRefTouchDevice = useRef<HTMLInputElement>(null);
    const { isMobile, isTablet } = useMedia();
    const [isOpen, togglePopup] = useToggle(false);

    const onClickRefInput = () => {
        inputFileRef.current?.click();
    };

    const onClickRefInputTouchDevice = () => {
        inputFileRefTouchDevice.current?.click();
    };

    const handlerClickUploadInput = () => {
        if (isMobile || isTablet) {
            togglePopup();
            return;
        }
        onClickRefInput();
    };

    const handlerChangeUploadFile = (
        evt: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const target = evt.target;
        const file = target.files?.item(0);

        if (isMobile || isTablet) {
            togglePopup();
        }

        if (
            file &&
            (!ALLOWED_TYPES.includes(file.type) || file.size > MAX_SIZE)
        ) {
            toastNotifications.error(
                "Ошибка: не тот формат или превышен максимально допустимый размер файла",
            );
            target.value = "";
            return;
        }

        onChange?.(file);
        target.value = "";
    };

    return (
        <div className={classNames(className, "ImageInput")}>
            <div className="ImageInput__Block">
                <button
                    type="button"
                    className="ImageInput__Btn"
                    onClick={handlerClickUploadInput}
                >
                    <div className="ImageInput__CameraIcon">
                        <IconCamera />
                    </div>
                    <Typography
                        component="span"
                        size="sm"
                        weight="semiBold"
                        color="dark"
                    >
                        {value ? "Изменить аватар" : "Загрузить аватар"}
                    </Typography>
                </button>
                <Typography
                    component="span"
                    size="xs"
                    weight="regular"
                    color="gray"
                >
                    Файлы: PNG, JPG не более 5 MB
                </Typography>
            </div>
            <input
                className="ImageInput__InpuUploadHidden"
                id="inputFile"
                ref={inputFileRef}
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handlerChangeUploadFile}
            />
            <input
                className="ImageInput__InpuUploadHidden"
                ref={inputFileRefTouchDevice}
                type="file"
                capture="user"
                accept="image/*"
                onChange={handlerChangeUploadFile}
            />
            {(isMobile || isTablet) && (
                <PopupImageUpload
                    isOpen={isOpen}
                    togglePopup={togglePopup}
                    onClickRefInput={onClickRefInput}
                    onClickRefInputTouchDevice={onClickRefInputTouchDevice}
                />
            )}
        </div>
    );
};

export default ImageInput;
