import React from 'react';
import { ReactComponent as FailedIcon } from "./assets/error-icon.svg";
import { ReactComponent as SuccessIcon } from "./assets/success-icon.svg";
import PaymentToast from './PaymentToast';
import SberToast from './SberToast';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';


const ToastifyContainer = () => {
    return (
        <ToastContainer
            theme="Toastify-Custome"
            autoClose={5000}
            hideProgressBar={true}
            position="bottom-center"
        />
    );
};

export const toastNotifications = {
    error: (message) => toast.error(message, {
        icon: <FailedIcon />
    }),
    success: (message) => toast.success(message, {
        icon: <SuccessIcon />
    }),
    payment: (status) => toast(<PaymentToast status={status} />, {
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "Toastify__toast--Payments",
    }),
    sberAccount: () => toast(<SberToast />, {
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "Toastify__toast--Payments",
    })
}

export default ToastifyContainer;