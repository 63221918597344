import React from "react";
import NotFound from '@/pages/NotFound';
import Header from "@/layouts/Header";
import Footer from "@/layouts/Footer";

const NotFoundLayout: React.FC = () => {
    return (
        <>
            <Header />
            <NotFound />
            <Footer />
        </>
    );
};

export default NotFoundLayout;
