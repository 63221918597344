import React, { useEffect, useState } from 'react';
import {
    arrow as middlewareArrow,
    autoPlacement as middlewareAutoPlacement,
    autoUpdate,
    flip as middlewareFlip,
    hide as middlewareHide,
    inline as middlewareInline,
    offset as middlewareOffset,
    shift as middlewareShift,
    size as middlewareSize,
    useClick,
    useFloating,
    useFocus,
    useHover,
    useDismiss,
    useInteractions,
} from '@floating-ui/react';
import type { TooltipOptions } from './types';

const getOption = <T,>(opt?: T): Exclude<T, boolean> | undefined => {
    if (typeof opt === 'boolean') return undefined;
    return opt as Exclude<T, boolean>;
};

export const useTooltip = (options: TooltipOptions) => {
    const {
        disabled,
        triggers = ['hover', 'focus'],
        placement = 'top',
        offset = 8,
        flip = true,
        shift,
        size,
        autoPlacement,
        hide,
        inline,
        arrow = true,
    } = options;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const arrowRef = React.useRef(null);

    const floating = useFloating({
        open: isOpen,
        placement,
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement && middlewareAutoPlacement(getOption(autoPlacement)),
            middlewareOffset(offset),
            flip && middlewareFlip(getOption(flip)),
            shift && middlewareShift(getOption(shift)),
            size && middlewareSize(getOption(size)),
            hide && middlewareHide(getOption(hide)),
            inline && middlewareInline(getOption(inline)),
            arrow &&
                middlewareArrow({
                    element: arrowRef,
                    ...getOption(arrow),
                }),
        ],
        onOpenChange: setIsOpen,
    });

    const dismiss = useDismiss(floating.context);
    const click = useClick(floating.context, {
        enabled: !disabled && triggers.includes('click'),
    });
    const focus = useFocus(floating.context, {
        enabled: !disabled && triggers.includes('focus'),
    });
    const hover = useHover(floating.context, {
        enabled: !disabled && triggers.includes('hover'),
    });

    const interactions = useInteractions([click, focus, hover, dismiss]);

    useEffect(() => {
        if (disabled || triggers.length === 0) {
            setIsOpen(false);
        }
    }, [triggers, disabled, setIsOpen]);

    return {
        isOpen,
        floating,
        interactions,
        arrowRef,
        setIsOpen,
    };
};
