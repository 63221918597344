

const scrollToSection = (elementId: string, offset?: number, behavior?: ScrollBehavior ) => {
    const element = document.getElementById(elementId);

    if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - (offset || 200);

        window.scrollTo({
            top: offsetPosition,
            behavior: (behavior || 'instant')
        });
    }
}

export default scrollToSection;