import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowLightTheme } from './assets/arrow-for-light.svg';
import { ReactComponent as ArrowDarkTheme } from './assets/arrow-for-dark.svg';
import './index.scss';

type Props = {
    type: "light" | "dark";
    title: string;
    currentPlan?: boolean;
}

const PlanHeader: React.FC<Props> = ({
    type = "light",
    title,
    currentPlan = false,
}) => {
    return (
        <div className='PlanHeader'>
            <h3 className={classNames({
                'PlanHeader__Title': true,
                'PlanHeader__Title--dark': type === "light"? false : true,
            })}>{title}</h3>
            <span className={classNames({
                'PlanHeader__Badge': true,
                'PlanHeader__Badge--active': currentPlan
            })}>
                ваш тариф
            </span>

            <div
                className={classNames({
                    'PlanHeader__Open-btn': true,
                })}
            >
                {
                    type === "light" ?
                        <ArrowLightTheme className='PlanHeader__Open-icon' />
                        :
                        <ArrowDarkTheme className='PlanHeader__Open-icon' />
                }
            </div>
        </div>
    )
}

export default PlanHeader;