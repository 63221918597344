/* eslint-disable react/prop-types */
import React from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import { TextSize, TextWeight } from "@/types";
import { BeatLoader } from 'react-spinners';
import "./index.scss";

type Props = {
    className?: string;
    onClick?: () => void;
    tag?: "a" | "button";
    BtnType?: "button" | "submit";
    text?: string;
    href?: string;
    TextSize?: TextSize;
    TextWeight?: TextWeight;
    target?: "_blank" | "_parent" | "_self" | "_top";
    iconLeft?: JSX.Element;
    iconRight?: JSX.Element;
    size?: "lg" | "sm";
    type?: "white" | "colored" | "transparent" | "bordered" | "colored-borders" | "gradiant-borders";
    isWide?: boolean;
    gradient?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    children?: React.ReactNode;
    spinnerSize?: number;
};

const Button: React.FC<Props> = forwardRef<any, Props>(
    (
        {
            className,
            onClick,
            tag,
            BtnType,
            text,
            href,
            target,
            iconLeft,
            iconRight,
            TextSize = "md",
            TextWeight = "regular",
            size = "lg",
            type = "white",
            isWide = false,
            isLoading = false,
            gradient = false,
            disabled = false,
            spinnerSize = 14,
            children,
        },
        ref,
    ) => {
        const Tag = tag === "button" ? "button" : "a";

        return (
            <Tag
                className={classNames(className, {
                    Button: true,
                    [`Button--btn-${type}`]: type,
                    [`Button--btn-${size}`]: size,
                    [`Button--btn-gradient`]: gradient,
                    [`Button--btn-disabled`]: disabled,
                    [`Button--btn-loading`]: isLoading,
                    [`Button--size-${TextSize}`]: TextSize,
                    [`Button--weight-${TextWeight}`]: TextWeight,
                    [`Button--isWide`]: isWide,
                })}
                onClick={onClick}
                href={href}
                target={target}
                type={BtnType}
                disabled={disabled || isLoading}
                ref={ref}
            >
            {isLoading ? (
                <BeatLoader
                    size={spinnerSize}
                    color="white"
                />
            ) : (
                <>
                {iconLeft && <span className="Button__Icon">{iconLeft}</span>}
                {!!text && <span className="Button__Text">{text}</span>}
                {children}
                {iconRight && <span className="Button__Icon">{iconRight}</span>}
                </>
            )}
            </Tag>
        );
    },
);

export default Button;

Button.displayName = "Button"
