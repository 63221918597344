import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';
import { Tooltip } from '@/components/Tooltip';
import FormErrorMessage from '@/components/FormErrorMessage';
import FormPasswordRules from '@/components/FormPasswordRules';
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import { useFormik } from 'formik';
import { newPasswordSchema } from '@/Schema/resetPasswordSchema';
import { toastNotifications } from '@/components/Toastify';
import { changePassword } from '@/api';
import { FormInput } from '@/components/Inputs';
import routes from '@/routes';
import './index.scss';

type Props = {
    recoveryToken: string;
}

const NewPassword: React.FC<Props> = ({ recoveryToken }) => {
    const navigate = useNavigate();

    const { values, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: newPasswordSchema,
        onSubmit: async (values, actions) => {
            try {
                const data = {
                    recovery_token: recoveryToken,
                    password: values.newPassword
                }

                await changePassword(data)

                actions.resetForm();
                toastNotifications.success("Пароль изменён");
                navigate(routes.conference)
            } catch {
                toastNotifications.error("Ошибка. Попробуйте ещё раз");
            }
        }
    })

    const nextStepIsAllowed = () => {
        if (
            touched.newPassword && !errors.newPassword
            && touched.confirmPassword && !errors.confirmPassword
        ) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <AuthorizationFormHeader
                className={"ByEmail__Header"}
                title={"Укажите новый пароль \nдля вашего аккаунта"}
            />
            <form className='NewPassword' onSubmit={handleSubmit} autoComplete='off'>
                <FormPasswordRules>
                    <FormInput
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        placeholder='Введите новый пароль'
                        label="Новый пароль"
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched.newPassword && errors.newPassword)}
                    />
                    {touched.newPassword && errors.newPassword && <FormErrorMessage text="Пароль не соответствует требованиям" />}
                </FormPasswordRules>


                <FormInput
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder='Новый пароль ещё раз'
                    label="Повторите пароль, чтобы не ошибиться"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(touched.confirmPassword && errors.confirmPassword)}
                />
                {touched.confirmPassword && errors.confirmPassword && <FormErrorMessage text="Пароли не совпадают" />}


                <Tooltip
                    className="NewPassword__Tooltip"
                    tooltip={"Необходимо заполнить все поля"}
                    disabled={nextStepIsAllowed()}
                >
                    <Button
                        className="NewPassword__Btn"
                        tag="button"
                        BtnType="submit"
                        text="Готово"
                        size='lg'
                        type='colored'
                        isWide
                        gradient
                        disabled={!nextStepIsAllowed()}
                    />
                </Tooltip>
            </form>
        </>
    )
}

export default NewPassword;