/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import { GroupBase, SelectComponentsConfig } from "react-select";
import AsyncCreatableSelect, {
    AsyncCreatableProps,
} from "react-select/async-creatable";
import classNames from "classnames";
import { selectStyles } from "./customStyle";
import "./index.scss";

type SelectInputProps = AsyncCreatableProps<
    unknown,
    boolean,
    GroupBase<unknown>
> & {
    className?: string;
    name?: string;
    id?: string;
    label?: string;
    withDropdownIcon?: boolean;
    width?: string;
    isError?: boolean;
    isAsync?: boolean;
    customComponents?: Partial<
        SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>
    >;
};

export const AsyncSelectInput: React.FC<SelectInputProps> = forwardRef<any, SelectInputProps>(
    (
        {
            className,
            name,
            id,
            label,
            isError = false,
            noOptionsMessage = () => "нет вариантов",
            ...restProps
        },
        ref,
    ) => {
    return (
        <div ref={ref} className={classNames(className, "RegistrationSelect")}>
            {label && (
                <label className="RegistrationSelect__Label" htmlFor={id}>
                    {label}
                </label>
            )}
            <AsyncCreatableSelect
                className={classNames({
                    "RegistrationSelectAsync--Error": isError,
                })}
                classNamePrefix="RegistrationSelectAsync"
                id={id}
                name={name}
                cacheOptions
                isSearchable
                allowCreateWhileLoading={false}
                loadingMessage={() => "загрузка..."}
                noOptionsMessage={noOptionsMessage}
                formatCreateLabel={(inputValue) => `выбрать "${inputValue}"`}
                styles={selectStyles}
                {...restProps}
            />
        </div>
    );
});

AsyncSelectInput.displayName = "AsyncSelectInput"
