import { clientApi } from "@/api/client";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: "pusher",
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    ...(process.env.NODE_ENV === "production"
        ? {
              wsHost: process.env.REACT_APP_PUSHER_HOST,
          }
        : {
              forceTLS: false,
              wsHost: "localhost",
              wsPort: "3000",
          }),
    disableStats: true,
    encrypted: true,
    cluster: "",
    enabledTransports: ["ws", "xhr_streaming"],
    disabledTransports: ["xhr_streaming"],
    authorizer: (channel) => {
        return {
            authorize: (socketId, callback) => {
                clientApi
                    .post("/broadcasting/auth", {
                        socket_id: socketId,
                        channel_name: channel.name,
                    })
                    .then((response) => {
                        callback(null, response.data);
                    })
                    .catch((error) => {
                        callback(error);
                    });
            },
        };
    },
});

export default echo;
