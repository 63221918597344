import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Typography from "@/components/Typography";
import Headline from "@/components/Headline";
import useShare from "@/hooks/useShare";
import useMenu from "@/hooks/useMenu";
import { HashLink } from "react-router-hash-link";
import { NavContext } from "@/context/NavContext";
import scrollToSection from "@/utils/scrollToSection";
import useMedia from "@/hooks/useMedia";
import classNames from "classnames";
import "./index.scss";

const MenuMobile: React.FC = () => {
    const { VISTI_LINKS_HEADER } = useShare();
    const { MENU_NAV_ITEMS, LinkIcon } = useMenu();
    const { activeSection } = useContext(NavContext);
    const { isProgram } = activeSection;
    const { isMobile } = useMedia();

    const renderMenu = MENU_NAV_ITEMS.map((el, index) => {
        const hashLinkId = el.route.split("#").pop() as string;

        if (el.isHashLink) {
            return (
                <HashLink
                    key={index}
                    className={classNames("MenuMobile__NavItem", {
                        active: isProgram,
                    })}
                    to={el.route}
                    scroll={() =>
                        scrollToSection(hashLinkId, isMobile ? 100 : 200)
                    }
                >
                    <Typography
                        className="MenuMobile__NavBody"
                        component="span"
                        size="md"
                        weight="semiBold"
                        color="inherit"
                    >
                        <span className="MenuMobile__NavIcon">{el.icon}</span>
                        {el.text}
                    </Typography>
                    <LinkIcon className="MenuMobile__NavLinkIcon" />
                </HashLink>
            );
        }

        return (
            <NavLink
                key={index}
                className={({ isActive }) =>
                    isActive && !isProgram ? "MenuMobile__NavItem active" : "MenuMobile__NavItem"
                }
                to={el.route}
                end
            >
                <Typography
                    className="MenuMobile__NavBody"
                    component="span"
                    size="md"
                    weight="semiBold"
                    color="inherit"
                >
                    <span className="MenuMobile__NavIcon">{el.icon}</span>
                    {el.text}
                </Typography>
                <LinkIcon className="MenuMobile__NavLinkIcon" />
            </NavLink>
        );
    });

    const renderItem = VISTI_LINKS_HEADER.map((el, index) => (
        <div
            key={index}
            role="button"
            className="MenuMobile__SocialItem"
            onClick={el.action}
        >
            {el.icon}
            <div className="MenuMobile__SocialItemInner">
                <Typography
                    className="MenuMobile__SocialItemText"
                    size="xs"
                    weight="regular"
                    color="inherit"
                >
                    {el.text}
                </Typography>
                <Typography
                    className="MenuMobile__SocialItemLink"
                    size="xs"
                    weight="regular"
                    color="inherit"
                >
                    {el.link}
                </Typography>
            </div>
        </div>
    ));

    return (
        <div className="MenuMobile">
            <Headline
                className="MenuMobile__Title"
                level="h2"
                size="lg"
                theme="light"
            >
                Меню
            </Headline>
            <div className="MenuMobile__NavItems">{renderMenu}</div>
            <div className="MenuMobile__Social">
                <Headline
                    className="MenuMobile__SocialTitle"
                    level="h3"
                    size="xsm"
                    theme="light"
                >
                    Мы в соцсетях
                </Headline>
                <div className="MenuMobile__SocialItems">{renderItem}</div>
            </div>
        </div>
    );
};

export default MenuMobile;
