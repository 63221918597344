/* eslint-disable react/prop-types */
import React from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import {ReactComponent as BtnGlow} from './assets/glow.svg';
import "./index.scss";

type Props = {
    className?: string;
    text: string;
    iconLeft?: JSX.Element;
    iconRight?: JSX.Element;
    size?: 'lg' | 'sm';
    theme?: 'light' | 'green';
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const ActionBtn: React.FC<Props> = forwardRef<any, Props>(
    ({ className, onClick, iconLeft, iconRight, size="lg", text, theme = 'light' }, ref) => {
        return (
            <button
                ref={ref}
                className={classNames(className, {
                    ActionBtn: true,
                    [`ActionBtn--${size}`]: size,
                    [`ActionBtn--${theme}`]: theme,
                })}
                onClick={onClick}
            >
                {iconLeft && <span className="ActionBtn__Icon">{iconLeft}</span>}
                {text}
                {iconRight && <span className="ActionBtn__Icon">{iconRight}</span>}
                <BtnGlow className="ActionBtn__Glow" />
            </button>
        );
    },
);

export default ActionBtn;

ActionBtn.displayName = "ActionBtn";
