import React from "react";
import classNames from "classnames";
import { ReactComponent as StepPassedIcon } from "./assets/step-passed.svg";
import "./index.scss";

export type FormStepperProps = {
    className?: string;
    dividerSize?: string;
    steps: number;
    startFrom: number;
    currentStep: number;
};

const FormStepper: React.FC<FormStepperProps> = ({ className, steps, dividerSize, startFrom, currentStep }) => {
    const renderSteps = new Array(steps).fill(0).map((el, index) => {
        const stepNum = index + 1;
        const isLastStep = steps === stepNum;

        return (
            <div key={stepNum} className="FormStepper__StepWrapper">
                <span
                    className={classNames("FormStepper__step", {
                        "FormStepper__step--active": stepNum === currentStep,
                    })}
                >
                    {stepNum < currentStep ? <StepPassedIcon className="FormStepper__icon" /> : stepNum}
                </span>
                {!isLastStep && <span className="FormStepper__Divider" style={{width: dividerSize}}></span>}
            </div>
        );
    });
    return <>{currentStep >= startFrom && <div className={classNames(className, "FormStepper")}>{renderSteps}</div>}</>;
};

export default FormStepper;
