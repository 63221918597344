import React, { PropsWithChildren } from "react";
import { StreamingStatusType } from "@/types/interfaces";
import "./index.scss";
import classNames from "classnames";

type Props = {
    title: string;
    icon?: React.ReactNode;
    streamingStatus?: StreamingStatusType;
}

const SessionContainer: React.FC<PropsWithChildren<Props>> = ({ children, title, icon, streamingStatus }) => {
    return (
        <div className={classNames("SessionContainer", {
            "SessionContainer--isFinished": streamingStatus === 'finished',
        })}>
            <div className="SessionContainer__Title">
            {streamingStatus === 'active'
                ? 'В эфире'
                : streamingStatus === 'finished'
                ? 'Трансляция завершена'
                : title}

                {streamingStatus !== 'finished' && icon}
            </div>
            {children}
        </div>
    )
}

export default SessionContainer;