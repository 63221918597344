import React from "react";
import TariffAccommodationInfo from "@/components/TariffAccommodationInfo";
import PlanDescription from "../common/PlanDescription";
import PlanFeatures from "../common/PlanFeatures";
import PlanPrice from "../common/PlanPrice";
import { useUser } from "@/context/UserContext";
import { TariffType } from "@/types/registration";
import { FullTimePlanInfo } from "./FullTimePlanInfo";
import "./index.scss";
import classNames from "classnames";

const features = [
    {
        title: "общение",
        description: "Нетворкинг и погружение в\nпрофессиональное\nсообщество",
    },
    {
        title: "общение",
        description:
            "Посещение выставки EdTech-продуктов, знакомство с сервисами Сбера и книжная выставка-ярмарка от Библиотеки Сбера",
    },
    {
        title: "комфорт",
        description: "Обед и кофе-брейки",
    },
    {
        title: "комфорт",
        description: "Трансфер до и от кампуса,\nпарковка",
    },
    {
        title: "комфорт",
        description: "Прогулки и экскурсии по кампусу",
    },
];

const InfoTexts: { [key in TariffType]: string } = {
    from_sber: "Вы зарегистрированы на тариф «Я из Сбера».",
    from_education: "Вы зарегистрированы на тариф «Я из образования».",
    common: "Вы зарегистрированы на общий тариф.",
    free: "Вы зарегистрированы на онлайн-участие.",
    vip: "Вы зарегистрированы как VIP.",
};

type Props = {
    showRegistrationInfo?: boolean;
};

const FullTimeParticipationPlan: React.FC<Props> = ({
    showRegistrationInfo = true,
}) => {
    const { userInfo } = useUser();
    return (
        <div className="FullTimeParticipationPlan">
            <PlanDescription
                text={
                    "Все бонусы и атмосфера насыщенного мероприятия — знакомства,\nобмен опытом, подарки и масса новых впечатлений."
                }
                theme="dark"
            />

            {userInfo?.info.format === "offline" && showRegistrationInfo && (
                <FullTimePlanInfo
                    className="FullTimeParticipationPlan__Info"
                    isVip={userInfo.info.tariff === "vip"}
                >
                    {InfoTexts[userInfo.info.tariff]}
                </FullTimePlanInfo>
            )}

            <PlanFeatures
                features={features}
                theme="dark"
                swiperId="darkSwiper"
            />

            <TariffAccommodationInfo
                className={classNames(
                    "FullTimeParticipationPlan__Accommodation",
                    {
                        "FullTimeParticipationPlan__Accommodation--CurrentPlan":
                            userInfo?.info.format === "offline",
                    },
                )}
            />

            {userInfo?.info.format === "online" && (
                <PlanPrice
                    className="FullTimeParticipationPlan__Price"
                    participationFormat="offline"
                />
            )}
        </div>
    );
};

export default FullTimeParticipationPlan;
