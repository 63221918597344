import React from "react";
import Typography from "@/components/Typography";
import "./index.scss";

type Props = {
    seconds: number;
    minutes: number;
    isCompleted: boolean;
    isDisabled?: boolean;
    onResend: () => void;
}

const Timer: React.FC<Props> = ({
    seconds,
    minutes,
    isCompleted,
    isDisabled = false,
    onResend,
}) => {
    if (isCompleted) {
        return (
            <div
                className="Timer"
                onClick={() => {
                    if (isDisabled) return;
                    onResend();
                }}
            >
                <Typography
                    className="Timer__Text Timer__Text--Clickable"
                    component="span"
                    size="xs"
                    weight="semiBold"
                    color="gray"
                >
                    Отправить повторно
                </Typography>
            </div>
        );
    } else {
        const formattedSeconds = seconds.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        return (
            <div className="Timer">
                <Typography
                    className="Timer__Text"
                    component="span"
                    size="xs"
                    weight="semiBold"
                    color="gray"
                >
                    Отправить повторно
                </Typography>
                <Typography
                    className="Timer__Numbers"
                    component="span"
                    size="xs"
                    weight="semiBold"
                    color="gray"
                >
                    {formattedMinutes}:{formattedSeconds}
                </Typography>
            </div>
        );
    }
};

export default Timer;