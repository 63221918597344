import * as yup from "yup";
import { TARIFF_TYPES } from "@/constants/validations";
import {
    AccommodationSchema,
    activitySchema,
    basicSchema,
    legalScheme,
    locationSchema,
    passwordsScheme,
    phoneScheme,
    promocodeScheme,
    transportSchema,
} from "@/Schema/signUpSchema";
import { profileEmail } from "@/Schema/profileSchema";

export const changeTariffSchema = yup.object({
    tariff: yup.string().oneOf(TARIFF_TYPES).required(),
});

const basicTariffSchema = (initialValues: any) => ({
    ...basicSchema,
    email: profileEmail(initialValues),
});

export const changeTariffCommon = (initialValues: any) =>
    yup.object({
        ...basicTariffSchema(initialValues),
        ...phoneScheme,
        ...legalScheme,
        ...locationSchema,
        ...activitySchema,
        ...transportSchema,
        ...AccommodationSchema,
    });

export const changeTariffEducation = (initialValues: any) =>
    yup.object({
        ...promocodeScheme,
        ...basicTariffSchema(initialValues),
        ...legalScheme,
        ...locationSchema,
        ...activitySchema,
        ...transportSchema,
        ...AccommodationSchema,
    });

export const changeTariffSber = (initialValues: any) =>
    yup.object({
        ...basicTariffSchema(initialValues),
        ...phoneScheme,
        ...locationSchema,
        ...activitySchema,
    });
