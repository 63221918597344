import { ReactComponent as LinkIcon } from "./assets/link-icon.svg";
import Typography from "@/components/Typography";
import './index.scss';

type Props = {
    href: string;
    text: string;
    icon?: React.ReactNode;
}

const Link: React.FC<Props> = ({ href, text, icon }) => {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
    }

    return (
        <a
            className="Link"
            href={href}
            onClick={handleClick}
            target="_blank"
            rel="noreferrer">
                <Typography size="md" weight="semiBold" gradient="green">
                    {text}
                </Typography>
                <span className="Link__Icon">
                    {icon || <LinkIcon />}
                </span>
        </a>
    )
}

export default Link;