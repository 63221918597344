import React from 'react';
import { useNavigate } from 'react-router-dom';
import ConfrenceBg from "./assets/bg_start_conf.png";
import ConfrenceBgMobile from "./assets/bg_start_conf_mobile.png";
import GridBg from "./assets/grid.png";
import GridBgMobile from "./assets/grid-mobile.png";
import { ReactComponent as CloseIcon } from './assets/close-icon.svg';
import { PropsWithChildren } from 'react';
import routes from '@/routes';
import './index.scss';

type Props = {
    onCloseNavigateTo: string;
}

const AccessGateway: React.FC<PropsWithChildren<Props>> = ({ children, onCloseNavigateTo = routes.conference }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        navigate(onCloseNavigateTo)
    }

    return (
        <main className="AccessGateway">
            <picture>
                <source srcSet={ConfrenceBgMobile} media="(max-width: 575px)" />
                <source srcSet={ConfrenceBg} media="(min-width: 576px)" />
                <img src={ConfrenceBg} className="AccessGateway__Bg" alt="decoration light" />
            </picture>
            <picture>
                <source srcSet={GridBgMobile} media="(max-width: 575px)" />
                <source srcSet={GridBg} media="(min-width: 576px)" />
                <img src={GridBg} className="AccessGateway__BgGrid" alt="decoration light" />
            </picture>

            <div className='AccessGateway__Box'>
                <button
                    className='AccessGateway__CloseBtn'
                    onClick={handleClose}
                >
                    <CloseIcon />
                </button>
                {children}
            </div>
        </main>
    )
}

export default AccessGateway;