import React from 'react';
import Popup from '@/components/Popup';
import MenuMobile from './MenuMobile';
import { useToggle } from 'react-use';
import { ReactComponent as BurgerIcon } from './assets/mobile-icon.svg';
import classNames from "classnames";
import './index.scss';

type Props = {
    className?: string;
}

const MobileNav: React.FC<Props> = ({ className }) => {
    const [isOpen, toggle] = useToggle(false);

    return (
        <div className={classNames(className, 'MobileNav')}>
            <button
                className="MobileNav__Trigger" 
                type="button"
                onClick={toggle}
            >
                <BurgerIcon />
            </button>
            <Popup isPopupActive={isOpen} closePopup={toggle} >
                <MenuMobile />
            </Popup>
        </div>
    );
};

export default MobileNav;