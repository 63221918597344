import {
    ABOUT_CONFERENCE_HASH_ID,
    ABOUT_CONTEST_HASH_ID,
    AWARDS_HASH_ID,
    FAQ_HASH_ID,
    JURY_HASH_ID,
    NOIMATIONS_HASH_ID,
    PARTNERS_HASH_ID,
    PROGRAM_HASH_ID,
    WINNERS_HASH_ID,
    CONFERENCE_THEME_HASH_ID,
    CONFERENCE_FOCUS_HASH_ID,
    PHOTO_ARCHIVE_HASH_ID,
    VIDEO_ARCHIVE_HASH_ID
} from "@/constants";
import { CONF_POLICY_PDF, OFFER_PDF } from "@/constants/links";

export const useFooter = () => {
    const DOC_LINKS = [
        {
            text: "Политика конфиденциальности",
            link: CONF_POLICY_PDF,
        },
        {
            text: "Оферта",
            link: OFFER_PDF,
        },
    ];

    const CONFERENCE_LINKS = [
        {
            text: "О конференции",
            link: ABOUT_CONFERENCE_HASH_ID,
        },
        {
            text: "Тема этого года",
            link: CONFERENCE_THEME_HASH_ID,
        },
        {
            text: "Фокус конференции",
            link: CONFERENCE_FOCUS_HASH_ID,
        },
        {
            text: "Запись конференции",
            link: PROGRAM_HASH_ID,
        },
        {
            text: "Как это было",
            link: VIDEO_ARCHIVE_HASH_ID,
        },
        {
            text: "Атмосфера мероприятия",
            link: PHOTO_ARCHIVE_HASH_ID,
        },
        // disabled in stage #4
        // {
        //     text: "Онлайн-трансляция",
        //     link: STREAM_HASH_ID,
        // },
        // {
        //     text: "Формат участия",
        //     link: TARIFFS_HASH_ID,
        // },
        // {
        //     text: "Спикеры",
        //     link: SPEAKERS_HASH_ID,
        // },
        {
            text: "FAQ",
            link: FAQ_HASH_ID,
        },
        {
            text: "Партнеры",
            link: PARTNERS_HASH_ID,
        },
    ];

    const CONTEST_LINKS = [
        {
            text: "О конкурсе",
            link: ABOUT_CONTEST_HASH_ID,
        },
        {
            text: "Победители конкурса",
            link: WINNERS_HASH_ID,
        },
        // disabled in stage 3
        // {
        //     text: "Кто участвует",
        //     link: PARTICIPATES_HASH_ID,
        // },
        {
            text: "Призы победителям",
            link: AWARDS_HASH_ID,
        },
        {
            text: "Номинации",
            link: NOIMATIONS_HASH_ID,
        },
        {
            text: "Жюри конкурса",
            link: JURY_HASH_ID,
        },
        // disabled in stage 3
        // {
        //     text: "Этапы конкурса",
        //     link: STAGES_HASH_ID,
        // },
    ];

    return {
        DOC_LINKS,
        CONFERENCE_LINKS,
        CONTEST_LINKS,
    };
};
