import React, { useCallback, useEffect, useState } from 'react';
import Button from "@/components/Button";
import Cookies from 'js-cookie';
import Typography from '@/components/Typography';
import './index.scss'

const CookiesName = 'is-accept-privacy';
const CONF_POLICY_LINK = `${process.env.PUBLIC_URL}/assets/conf_policy.pdf`;

const CookieNotificator: React.FC = () => {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        const isAcceptedCookies = Cookies.get(CookiesName);

        if (!isAcceptedCookies || isAcceptedCookies === 'false') {
            setShowNotification(true);
        }
    }, []);

    const handleClick = () => {
        Cookies.set(CookiesName, "true");
        setShowNotification(false);
    }

    const renderNotification = useCallback(() => {
        if (!showNotification) return null;
        return (
            <div className="CookieNotificator">
                <Typography size="xs" weight="regular" color="light" opacity={0.6}>
                    СберУниверситет использует файлы cookie для улучшения сайта. Пользуясь сайтом, вы соглашаетесь с этим и нашей&nbsp;
                    <a
                        href={CONF_POLICY_LINK}
                        className="CookieNotificator__Policy"
                        target="_blank"
                        rel="noreferrer"> 
                            Политикой обработки данных
                    </a>
                </Typography>
                <Button
                    className='CookieNotificator__Btn'
                    tag='button'
                    BtnType='button'
                    text="Понятно"
                    size="sm"
                    TextSize='xs'
                    TextWeight='regular'
                    type="colored-borders"
                    isWide
                    onClick={handleClick}
                />
            </div>
        )
    }, [showNotification])

    return (
        <>
            {renderNotification()}
        </>
    );
};

export default CookieNotificator;