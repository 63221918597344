import { useQuery } from '@tanstack/react-query';
import { getFaq } from "@/api";

export const useFaq = () => {
    return useQuery({
        queryFn: getFaq,
        queryKey: ['faq'],
        select: ({ data }) => {
            return data.data;
        },
    });
};
