import React from 'react';
import { Outlet } from 'react-router-dom';
import AccessGateway from '@/components/AccessGateway';
import { Helmet } from 'react-helmet';
import routes from '@/routes';

const SignIn: React.FC = () => {

    return (
        <>
            <Helmet key="SignIn">
                <title>Войдите в профиль | СберУниверситет</title>
                <meta name="description" content="Войти в личный кабинет" />
            </Helmet>
            <AccessGateway onCloseNavigateTo={routes.conference}>
                <Outlet />
            </AccessGateway>
        </>

    )
}

export default SignIn;