import React from "react";
import CupIcon from "../assets/ic-cup.png";
import FlagIcon from "../assets/ic-flag.png";
import PetardIcon from "../assets/ic-petard.png";
import {ReactComponent as LiveStreamIcon} from "../assets/ic-live-stream.svg";
import "./index.scss";

type Icons = "start" | "finish" | "break" | "liveStream";
type IconWithoutSvgs = Exclude<Icons, "liveStream">;

type Props = {
    type: Icons;
};

const ICONS: Record<IconWithoutSvgs, string> = {
    start: PetardIcon,
    finish: FlagIcon,
    break: CupIcon,
};

const ProgramIcons: React.FC<Props> = ({ type }) => {

    if (type === "liveStream") {
        return <LiveStreamIcon />
    }

    return (
        <div className="ProgramIcons">
            <img className="ProgramIcons__Img" src={ICONS[type]} alt={type} />
        </div>
    );
}

export default ProgramIcons;
