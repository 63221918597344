import { useEffect, useRef } from "react";
import { isAxiosError } from "axios";
import { Schema } from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { makePayment, signUp } from "@/api";
import { toastNotifications } from "@/components/Toastify";
import { delay } from "@/utils/promise";
import { RegistrationData } from "@/types/registration";
import { FormikData } from "@/types/formik";

export const openPaymentAcquire = async () => {
    try {
        const payment = await makePayment();
        const externalLink = payment.data.data.acquiring_form_url;
        window.location.replace(externalLink);
        // бесконечный промис чтоб заблокировать кнопку
        await new Promise(() => {});
    } catch (e) {
        toastNotifications.error("Что-то пошло не так, попробуйте ещё раз");
    }
};

export const useActivityControl = (formik: FormikData<any>) => {
    const prevActivity = useRef(formik.values.activity);
    useEffect(() => {
        if (!formik.values.activity.includes("work")) {
            formik.setFieldValue("place_of_work", "");
            formik.setFieldValue("position", "");
        }
        if (!formik.values.activity.includes("study")) {
            formik.setFieldValue("place_of_study", "");
            formik.setFieldValue("speciality", "");
        }
        if (formik.values.activity.includes("no_activity") && !prevActivity.current.includes("no_activity")) {
            formik.setFieldValue("activity", ["no_activity"]);
        } else if (
            formik.values.activity.includes("no_activity") &&
            prevActivity.current.length < formik.values.activity.length
        ) {
            formik.setFieldValue(
                "activity",
                formik.values.activity.filter((item: any) => item !== "no_activity"),
            );
        }
        prevActivity.current = formik.values.activity;
    }, [JSON.stringify(formik.values.activity)]);
};

export const useRegistrations = <T extends Partial<RegistrationData> = Partial<RegistrationData>>(params: {
    values: T;
    validationScheme: Schema;
    onRegistered?: (values: RegistrationData, formikHelpers: FormikHelpers<RegistrationData>) => any;
}) => {
    const { values, validationScheme, onRegistered } = params;
    const signIn = useSignIn();
    const isAuthenticated = useIsAuthenticated();

    const formik = useFormik<RegistrationData>({
        initialValues: {
            format: "offline",
            tariff: "common",
            legal_type: "individual",
            name: "",
            surname: "",
            email: "",
            phone: "",
            agree_processing_of_personal_data: false,
            agree_receive_promotional_and_informational_mailings: false,
            country_id: "",
            city: "",
            place_of_study: "",
            speciality: "",
            place_of_work: "",
            position: "",
            transport: "",
            car_brand: "",
            car_state_number: "",
            is_planning_to_stay_at_the_hotel: "",
            password: "",
            password_confirmation: "",
            activity: [],
            ...values,
        },
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: validationScheme,
        onSubmit: async (values, formikHelpers) => {
            try {
                if (!isAuthenticated()) {
                    const { activity, ...restValues } = values;
                    const response = await signUp(restValues);

                    const isRegistered = signIn({
                        token: response.token,
                        tokenType: "Bearer",
                        expiresIn: 3600,
                        authState: {},
                    });

                    if (isRegistered) {
                        await delay(300);
                        await onRegistered?.(values, formikHelpers);
                    } else {
                        throw "Не удалось авторизоваться";
                    }
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    if (e.response?.data?.errors) {
                        formikHelpers.setErrors(e.response.data.errors);
                    }
                    const errorMsg =
                        e.response?.data?.error ||
                        e.response?.data?.message ||
                        "Что-то пошло не так, попробуйте ещё раз";
                    toastNotifications.error(errorMsg);
                } else {
                    toastNotifications.error("Что-то пошло не так, попробуйте ещё раз");
                }
            }
        },
    });

    useActivityControl(formik);

    return formik;
};
