import { ReactComponent as IconProfile } from "@/assets/icons/profile.svg";
import { ReactComponent as IconTariff } from "@/assets/icons/tariff.svg";
import { ReactComponent as IconCertificates } from "@/assets/icons/certificates.svg";
import { ReactComponent as IconMoney } from "@/assets/icons/money.svg";
import { ReactComponent as IconKey } from "@/assets/icons/key.svg";
import { RoutesNavigationProfileType } from "@/types/interfaces";
import routes from "@/routes";

export const routesNavigationProfile: RoutesNavigationProfileType[] = [
    {
        id: 1,
        route: routes.profile,
        label: "Мои данные",
        labelDeviceTouch: "Мои данные",
        icon: IconProfile,
        navType: "profile",
    },
    {
        id: 2,
        route: routes.profileTariffs,
        label: "Выбранный тариф",
        labelDeviceTouch: "Тариф",
        icon: IconTariff,
        navType: "tariff",
    },
    {
        id: 3,
        route: routes.profileCertificates,
        label: "Сертификат участника",
        labelDeviceTouch: "Сертификат",
        icon: IconCertificates,
        navType: "certificates",
    },
    {
        id: 4,
        route: routes.profileRefund,
        label: "Вернуть денежные средства",
        labelDeviceTouch: "Возврат",
        icon: IconMoney,
        navType: "money",
    },
    {
        id: 5,
        route: routes.profileChangePassword,
        label: "Сменить пароль",
        labelDeviceTouch: "Пароль",
        icon: IconKey,
        navType: "key",
    },
];