import React from "react";
import FeatureCard from "../FeatureCard";
import classNames from "classnames";
import { ReactComponent as ArrowRightLight } from "./assets/arrow-right-white.svg";
import { ReactComponent as ArrowRightDark } from "./assets/arrow-right-black.svg";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import useMedia from "@/hooks/useMedia";
import "swiper/css";
import "swiper/css/navigation";
import "./index.scss";

type Props = {
    features: {
        title: string;
        description: string;
    }[];
    theme: "light" | "dark";
    swiperId: string;
};

const swiperParams: SwiperProps = {
    spaceBetween: 8,
    slidesPerView: "auto",
    grabCursor: true,
    modules: [Navigation],
};

const PlanFeatures: React.FC<Props> = ({
    features,
    theme = "light",
    swiperId,
}) => {
    const { isMobile } = useMedia();

    const renderFeatures = features?.map((el, index) => {
        return (
            <SwiperSlide key={index} className="PlanFeatures__swiper-slide">
                <FeatureCard
                    title={el.title}
                    description={el.description}
                    theme={theme}
                />
            </SwiperSlide>
        );
    });

    return (
        <div
            className={classNames({
                PlanFeatures: true,
                "PlanFeatures--light": theme === "light",
                "PlanFeatures--dark": theme === "dark",
            })}
        >
            <p className="PlanFeatures__Title">Что входит в тариф</p>
            <div className="PlanFeatures__Slider-Wrapper">
                <Swiper
                    id={swiperId}
                    slidesOffsetBefore={isMobile ? 16 : 20}
                    slidesOffsetAfter={isMobile ? 16 : 20}
                    navigation={{
                        prevEl: `.to-Prev-${swiperId}`,
                        nextEl: `.to-Next-${swiperId}`,
                    }}
                    {...swiperParams}
                >
                    {renderFeatures}
                </Swiper>
                <div
                className={`PlanFeatures__Slider-prev to-Prev-${swiperId}`}>
                    {theme === "light" ? (
                        <ArrowRightDark />
                    ) : (
                        <ArrowRightLight />
                    )}
                </div>
                <div
                className={`PlanFeatures__Slider-next to-Next-${swiperId}`}>
                    {theme === "light" ? (
                        <ArrowRightDark />
                    ) : (
                        <ArrowRightLight />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PlanFeatures;
