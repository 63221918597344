import React from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { NavigateProps } from "react-router";
import { Navigate, Outlet } from "react-router-dom";

export type RequireNotAuthProps = React.PropsWithChildren<{
    redirectTo: NavigateProps["to"];
}>;

export const RequireNotAuth: React.FC<RequireNotAuthProps> = ({ redirectTo, children }) => {
    const isAuth = useIsAuthenticated();

    if (isAuth()) {
        return <Navigate to={redirectTo} />;
    }

    return children || <Outlet />;
};
