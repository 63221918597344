import React from "react";
import classNames from "classnames";
import { ReactComponent as SberIconGray } from "./assets/sber-icon-gray.svg";
import "./index.scss";

type Props = {
    title: string;
    description: string;
    theme: "light" | "dark";
};

const FeatureCard: React.FC<Props> = ({
    title,
    description,
    theme = "light",
}) => {
    return (
        <div
            className={classNames({
                FeatureCard: true,
                "FeatureCard--light": theme === "light",
                "FeatureCard--dark": theme === "dark",
            })}
        >
            <div
                className={classNames("FeatureCard__Header", {
                    "FeatureCard__Header--light": theme === "light",
                    "FeatureCard__Header--dark": theme === "dark",
                })}
            >
                <SberIconGray />
                <h4 className="FeatureCard__Title">{title}</h4>
            </div>
            <p className="FeatureCard__Text">{description}</p>
        </div>
    );
};

export default FeatureCard;
