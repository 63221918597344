import React, { useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { toastNotifications } from "@/components/Toastify";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import { recoverPasswordByEmail } from "@/api";
import Timer from "@/components/Timer";
import { ReactComponent as SuccessIcon } from "./assets/success-icon.svg";
import { MESSAGES } from "@/constants/messages";
import "./index.scss";

type Props = {
    userEmail: string;
};

const LinkSent: React.FC<Props> = ({ userEmail }) => {
    const [reset, setReset] = useState<boolean>(false);

    const handleClick = async () => {
        try {
            const data = {
                email: userEmail,
            };

            await recoverPasswordByEmail(data);
            toastNotifications.success("Письмо повторно отправлено");
            setReset((prev) => !prev);
        } catch {
            toastNotifications.error(MESSAGES.ERROR);
        }
    };

    return (
        <div className="LinkSent">
            <AuthorizationFormHeader
                className="ByEmail__Header"
                title={"Письмо отправлено"}
                subtitle={`Мы отправили вам письмо на ${userEmail}\nсо ссылкой для подтверждения`}
                icon={<SuccessIcon />}
            />

            <Countdown
                key={`${reset}`}
                date={Date.now() + 10000}
                renderer={(props: CountdownRenderProps) => (
                    <Timer
                        isCompleted={props.completed}
                        minutes={props.minutes}
                        seconds={props.seconds}
                        onResend={handleClick}
                    />
                )}
            />
        </div>
    );
};

export default LinkSent;
