import React from "react";
import useDownloadCertificate from "@/hooks/useDownloadCertificate";
import { ReactComponent as DownloadIcon } from "./assets/download-icon.svg";
import classNames from "classnames";
import Typography from "@/components/Typography";
import { BeatLoader } from "react-spinners";
import "./index.scss";

const DownloadCertificate: React.FC = () => {
    const { downloadCertificate, isDownloading } = useDownloadCertificate();

    return (
        <div className="DownloadCertificate">
            <button
                className={classNames({
                    "DownloadCertificate__Btn": true,
                    "DownloadCertificate__Btn--disabled": isDownloading,
                })}
                onClick={downloadCertificate}
                disabled={isDownloading}
            >
                {isDownloading ? <BeatLoader size={12} color="white"/> : <DownloadIcon className="DownloadCertificate__BtnIcon" width={40} height={40}/>}
            </button>
            <Typography className="DownloadCertificate__Text" component="span" size="sm" weight="semiBold" color="dark" isCentered>
                Получить именной<br />сертификат участника
            </Typography>
        </div>
    )
}

export default DownloadCertificate;