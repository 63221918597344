import React, { useContext } from "react";
import ProgramContainer from "../common/ProgramContainer";
import ProgramSlider from "../common/ProgramSlider";
import { ScheduleContext } from "@/context/ScheduleContext";
import Loader from "@/components/Loader";
import SessionContainer from "@/components/SessionCard/SessionContainer";
import ProgramIcons from "../ProgramIcons";
import './index.scss';

const OnCampusProgram: React.FC = () => {
    const { onCampusProgram } = useContext(ScheduleContext);

    if (onCampusProgram.length === 0 || !onCampusProgram) {
        return <Loader />
    }

    return (
        <div className="OnCampusProgram">
            {/* opening  */}
            <SessionContainer title={onCampusProgram[0].TimeString} icon={<ProgramIcons type="start"/>}>
                <ProgramContainer
                    data={onCampusProgram[0]}
                />
            </SessionContainer>

            {/* discussion  */}
            <SessionContainer title={onCampusProgram[1].TimeString}>
                <ProgramContainer
                    data={onCampusProgram[1]}
                    mainStage
                    withLink
                />
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer title={onCampusProgram[2].TimeString} icon={<ProgramIcons type="break"/>}>
                <ProgramContainer
                    data={onCampusProgram[2]}
                    cardColor="gray"
                />
            </SessionContainer>

            {/* multiple_sessions 01  */}
            <SessionContainer title={onCampusProgram[3].TimeString}>
                <div className="OnCampusProgram__Multiple_sessions">
                    <ProgramSlider swiperId="multiple_sessions_01" data={[onCampusProgram[3], onCampusProgram[4], onCampusProgram[5]]} />
                </div>
            </SessionContainer>

            <SessionContainer title={onCampusProgram[6].TimeString}>
                <ProgramContainer
                    data={onCampusProgram[6]}
                />
            </SessionContainer>

            {/* multiple_sessions 02  */}
            <SessionContainer title={onCampusProgram[7].TimeString}>
                <div className="OnCampusProgram__Multiple_sessions">
                    <ProgramSlider swiperId="multiple_sessions_02" data={[onCampusProgram[7], onCampusProgram[8], onCampusProgram[9]]} />

                    {/* отключено, пока нет информации о сеансе */}
                    {/* <ProgramContainer
                        className="OnCampusProgram__Practice"
                        data={onCampusProgram[10]}
                        withLink
                        gradientSubtitle
                    /> */}
                </div>
            </SessionContainer>

            {/* break  */}
            <SessionContainer title={onCampusProgram[11].TimeString} icon={<ProgramIcons type="break"/>}>
                <ProgramContainer
                    data={onCampusProgram[11]}
                    cardColor="gray"
                />
            </SessionContainer>

            {/* multiple_sessions 03  */}
            <SessionContainer title={onCampusProgram[12].TimeString}>
                <div className="OnCampusProgram__Multiple_sessions">
                    <ProgramSlider swiperId="multiple_sessions_03" data={[onCampusProgram[12], onCampusProgram[13], onCampusProgram[14]]} />

                    {/* отключено, пока нет информации о сеансе */}
                    {/* <ProgramContainer
                        className="OnCampusProgram__Practice"
                        data={onCampusProgram[15]}
                        withLink
                        gradientSubtitle
                    /> */}
                </div>
            </SessionContainer>

            {/* networking  */}
            <SessionContainer title={onCampusProgram[16].TimeString}>
                <ProgramContainer
                    data={onCampusProgram[16]}
                />
            </SessionContainer>

            {/* finish  */}
            <SessionContainer title={onCampusProgram[17].TimeString} icon={<ProgramIcons type="finish"/>}>
                <ProgramContainer
                    data={onCampusProgram[17]}
                    bgColor="white"
                    cardColor="none"
                />
            </SessionContainer>
        </div>
    )
}

export default OnCampusProgram;