import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import { ReactComponent as ShareIcon } from "./assets/union.svg";
import Typography from "@/components/Typography";
import useShare from "@/hooks/useShare";
import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string;
};

const ShareBtn: React.FC<Props> = ({ className }) => {
    const { VISTI_LINKS_HEADER } = useShare();

    const renderItem = VISTI_LINKS_HEADER.map((el, index) => (
        <div key={index} role="button" className="MenuItem" onClick={el.action}>
            {el.icon}
            <div className="MenuItem__Inner">
                <Typography className='MenuItem__Text' size="xs" weight="regular" color="inherit">
                    {el.text}
                </Typography>
                <Typography className='MenuItem__Link' size="xs" weight="regular" color="inherit">
                    {el.link}
                </Typography>
            </div>
        </div>
    ));

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className={classNames(className, "ShareBtn__Trigger")} type="button">
                    <ShareIcon className="ShareBtn__Icon" />
                    <span className="ShareBtn__Text">Мы в соцсетях</span>
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content className="ShareBtn__Content" sideOffset={8} collisionPadding={16}>
                    {renderItem}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
};

export default ShareBtn;
