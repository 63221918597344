import React from 'react';
import { NavLink } from 'react-router-dom';
import routes from '@/routes';
import './index.scss';

const ToSignIn: React.FC = () => {
    return (
        <div className='ToSignIn'>
            <span className='ToSignIn__Text'>У вас уже есть профиль?</span>
            <NavLink
                className='ToSignIn__Link'
                to={routes.signin}
            >
                Войти
            </NavLink>
        </div>
    )
}

export default ToSignIn;