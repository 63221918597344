import React from "react";
import Button from "@/components/Button";
import Container from "@/components/Container";
import { Helmet } from 'react-helmet';
import routes from "@/routes";
import "./index.scss";

const NotFound: React.FC = () => {
    return (
        <div className="NotFound">
            <Helmet key="NotFound">
                <title>Ошибка 404</title>
                <meta
                    name="description"
                    content="Страница не существует"
                />
            </Helmet>
            <Container className="NotFound__Container">
                <h1 className="NotFound__Title">Ошибка 404</h1>
                <p className="NotFound__Text">
                    Извините, такой страницы не существует
                </p>
                <Button
                    className="NotFound__Button"
                    BtnType="button"
                    size="lg"
                    tag="a"
                    type="colored"
                    text="Перейти на главную"
                    href={routes.conference}
                    gradient
                    isWide
                ></Button>
            </Container>
        </div>
    );
};

export default NotFound;
