import React from "react";
import classNames from "classnames";
import { ReactComponent as ArrowLeft } from "./assets/arrow-left.svg";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import "./index.scss";

type Props = {
    arrowDiraction: "right" | "left",
    className: string
}

const SliderBtn: React.FC<Props> = ({
    arrowDiraction,
    className
}) => {

    return (
        <button className={classNames(className, "SliderBtn")} type="button">
            {arrowDiraction === "left" ? <ArrowLeft /> : <ArrowRight />}
        </button>
    )
}

export default SliderBtn;