import classNames from 'classnames';
import './index.scss';

type Props = {
    children: React.ReactNode, 
    className?: string,
}

const Container: React.FC<Props> = ({ children, className }) => {
    return (
        <div className={
            classNames(className, {
                'Container': true,
            })
        }>
            {children}
        </div>
    );
};

export default Container;
