import React, { useState } from "react";
import Headline from "@/components/Headline";
import SliderBtn from '@/components/SliderBtn';
import useMedia from "@/hooks/useMedia";
import classNames from "classnames";
import { PICTURES_ARCHIVE } from "@/constants/picturesArchive";
import SkeletonImg from "@/components/SkeletonImg";
import PhotoCard from "./PhotoCard";
import { useInView } from "react-intersection-observer";
import { PHOTO_ARCHIVE_HASH_ID } from "@/constants";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, FreeMode } from 'swiper/modules';
import Popup from "@/components/Popup";
import Counter from "../Counter";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import "./index.scss";

const PhotoArchive: React.FC = () => {
    const { isMobile, isDesktop } = useMedia();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isShowPopup, setShowPopup] = useState(false);
    const [selectIndexPopup, setSelectIndexPopup] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true
    });

    const renderSlides = PICTURES_ARCHIVE?.map((el, index) => {
        return (
            <SwiperSlide key={index} className='PhotoArchive__SlideCustom'>
                <PhotoCard
                    srcJpg={isMobile ? el.src_mobile : el.src_original}
                    srcWebp={el.src_original_webp}
                    onClick={() => {
                        setSelectIndexPopup(index);
                        setShowPopup(true);
                    }}
                />
            </SwiperSlide>
        )
    });

    const renderSlidesPopup = PICTURES_ARCHIVE?.map((el, index) => {
        return (
            <SwiperSlide key={index} className='PhotoArchive__SlideCustom'>
                <PhotoCard
                    srcJpg={el.src_original_webp}
                    srcWebp={el.src_original}
                />
            </SwiperSlide>
        )
    });

    const renderSlidesThumbs = PICTURES_ARCHIVE?.map((el, index) => {
        return (
            <SwiperSlide key={index} className="PhotoArchive__SlideThumb">
                <SkeletonImg
                    src={el.src_thumb}
                    hash="L84fKvS]SijZ.lScWFjax]WUafju"
                    alt="Атмосфера мероприятия"
                    loading="lazy"
                    isInView={inView}
                />
            </SwiperSlide>
        )
    })

    const swiperParams = {
        slidesPerView: 1,
        modules: [Navigation, Thumbs, FreeMode],
        navigation: {
            prevEl: '.PhotoArchive__Slider--Prev',
            nextEl: '.PhotoArchive__Slider--Next',
        },
        thumbs: { swiper: thumbsSwiper },
    };

    const swiperParamsPopup = {
        slidesPerView: 1,
        modules: [Navigation],
        navigation: {
            prevEl: '.PhotoArchive__Slider--Prev',
            nextEl: '.PhotoArchive__Slider--Next',
        },
    };

    return (
        <section id={PHOTO_ARCHIVE_HASH_ID} className="PhotoArchive">
            <div ref={ref} className="PhotoArchive__Container">
                <Headline className="PhotoArchive__Header" level="h2" size="xlg" theme="light" isCentered>
                    Атмосфера мероприятия
                </Headline>

                <div className="PhotoArchive__SliderContainer">
                    <SliderBtn
                        className={classNames('PhotoArchive__Slider PhotoArchive__Slider--Prev',{
                            'PhotoArchive__Slider--Hide': !isDesktop,
                        })}
                        arrowDiraction='left'
                    />
                    <Swiper
                        className='PhotoArchive__Swiper'
                        onActiveIndexChange={swiper => setCurrentIndex(swiper.activeIndex)}
                        {...swiperParams}
                    >
                        {renderSlides}
                    </Swiper>
                    <SliderBtn
                        className={classNames('PhotoArchive__Slider PhotoArchive__Slider--Next', {
                            'PhotoArchive__Slider--Hide': !isDesktop,
                        })}
                        arrowDiraction='right'
                    />
                    <Counter className="PhotoArchive__Counter" total={PICTURES_ARCHIVE.length} current={currentIndex + 1} />
                </div>
                <Swiper
                    className='PhotoArchive__SwiperThumbs'
                    // @ts-ignore
                    onSwiper={setThumbsSwiper}
                    spaceBetween={isMobile ? 4 : 12}
                    slidesPerView={6}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                >
                    {renderSlidesThumbs}
                </Swiper>
            </div>


            <Popup
                className="PhotoArchive__Popup"
                isPopupActive={isShowPopup && isDesktop}
                closePopup={() => setShowPopup(false)}
                position="center"
                withCloseBtn
                withBodyScroll
            >
                <div className="PhotoArchive__PopupSliderContainer">
                    <SliderBtn
                        className={classNames('PhotoArchive__Slider PhotoArchive__Slider--Prev',{
                            'PhotoArchive__Slider--Hide': !isDesktop,
                        })}
                        arrowDiraction='left'
                    />
                    <Swiper
                        className='PhotoArchive__Swiper'
                        onActiveIndexChange={swiper => setCurrentIndex(swiper.activeIndex)}
                        {...swiperParamsPopup}
                        initialSlide={selectIndexPopup}
                    >
                        {renderSlidesPopup}
                    </Swiper>
                    <SliderBtn
                        className={classNames('PhotoArchive__Slider PhotoArchive__Slider--Next', {
                            'PhotoArchive__Slider--Hide': !isDesktop,
                        })}
                        arrowDiraction='right'
                    />
                    <Counter className="PhotoArchive__Counter" total={PICTURES_ARCHIVE.length} current={currentIndex + 1} />
                </div>
            </Popup>

        </section>
    )
}

export default PhotoArchive;