import React from 'react';
import SignInForm from './SignInForm';
import ToSignUp from './ToSignUp';
import AuthorizationFormHeader from '@/components/AuthorizationFormHeader';

const SignInBox: React.FC = () => {
    return (
        <>
            <AuthorizationFormHeader
                title="Войдите в профиль"
                subtitle="Добрый день!"
                isPreTitle
            />
            <SignInForm />
            <ToSignUp />
        </>
    )
}

export default SignInBox;
