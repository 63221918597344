import React from "react";
import Label from "@/components/Label";
import Container from "@/components/Container";
import CoreIconDekstop from "./assets/il_conference_desktop.png";
import CoreIconMobile from "./assets/il_conference_mobile.png";
import ConfrenceBg from "./assets/bg_start_conf.png";
import ConfrenceBgMobile from "./assets/bg_start_conf_mobile.png";
import GridBg from "./assets/grid.png";
import GridBgMobile from "./assets/grid-mobile.png";
import ActionBtn from "@/components/ActionBtn";
import { format, parse } from "date-fns";
import { ru } from 'date-fns/locale';
import { START_DATE } from "@/constants/dates";
import { PROGRAM_HASH_ID } from "@/constants";
import Typography from "@/components/Typography";
import scrollToSection from "@/utils/scrollToSection";
import useMedia from "@/hooks/useMedia";
import Headline from "@/components/Headline";
import useBanner from "@/hooks/useBanner";
import classNames from "classnames";
import "./index.scss";

const ConferenceHero: React.FC = () => {
    const { isMobile } = useMedia();
    const { isBannerActive } = useBanner();

    // const parsedDate = parse(START_DATE, 'dd.MM.yyyy', new Date());
    // const startDate = format(parsedDate, 'dd MMMM yyyy', {locale: ru})
    return (
        <section className={classNames("ConferenceHero", {
            "ConferenceHero--isBannerActive": isBannerActive,
        })}>
            <picture>
                <source srcSet={ConfrenceBgMobile} media="(max-width: 575px)" />
                <source srcSet={ConfrenceBg} media="(min-width: 576px)" />
                <img src={ConfrenceBg} className="ConferenceHero__Bg" alt="decoration light" />
            </picture>
            <picture>
                <source srcSet={GridBgMobile} media="(max-width: 575px)" />
                <source srcSet={GridBg} media="(min-width: 576px)" />
                <img src={GridBg} className="ConferenceHero__BgGrid" alt="decoration light" />
            </picture>
            <Container className="ConferenceHero__Wrapper">
                <picture>
                    <source srcSet={CoreIconMobile} media="(max-width: 575px)" />
                    <source srcSet={CoreIconDekstop} media="(min-width: 576px)" />
                    <img src={CoreIconDekstop} className="ConferenceHero__Icon" alt="Сбер университет" />
                </picture>
                <div className="ConferenceHero__Inner">
                    <div className="ConferenceHero__Content">
                        {/* disabled in stage #4 */}
                        {/* <div className="ConferenceHero__Labels">
                            <Label Text={startDate} hasDot />
                            <Label Text="Очно и онлайн" />
                        </div> */}
                        <Headline className="ConferenceHero__Title" level="h1" size="xxlg" theme="light">
                            <div className="ConferenceHero__Gradient ConferenceHero__OffsetTop">Больше</div>
                            <div className="ConferenceHero__OffsetBottom">чем&nbsp;обучение</div>
                        </Headline>
                        <Typography className="ConferenceHero__Description" size="md" weight="semiBold" color="light">
                            VIII конференция СберУниверситета
                        </Typography>
                        <ActionBtn className="ConferenceHero__Button" text="Смотреть в записи" onClick={() => scrollToSection(PROGRAM_HASH_ID, isMobile ? 100 : 200)} />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default ConferenceHero;
