import { VideosArchiveType } from "@/types/interfaces";

export const VIDEO_ARCHIVE: VideosArchiveType[] = [
    {
        videoLink: 'https://vk.com/video_ext.php?oid=-184379939&id=456239652&hash=4fb060950e8cfa9f',
    },
    {
        videoLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239651&hash=4f885b8d3e415b8d",
    },
    {
        videoLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239649&hash=d3e26b82e02eb47e",
    },
]