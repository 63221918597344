import { parseISO } from 'date-fns';

function compareDatesAsc(a: any, b: any) {
    // @ts-ignore
    return parseISO(a.created_at) - parseISO(b.created_at);
}
function compareDatesDesc(a: any, b: any) {
    // @ts-ignore
    return parseISO(b.created_at) - parseISO(a.created_at);
}

const sortMessages = (data: any[], order: "descending" | "ascending") => {
    if (order === "ascending") {
        // To sort in ascending order (oldest to newest)
        const sortedAscending = [...data].sort(compareDatesAsc);
        return sortedAscending;

    } else {
        // To sort in descending order (newest to oldest)
        const sortedDescending = [...data].sort(compareDatesDesc);
        return sortedDescending;
    }
}
export default sortMessages;