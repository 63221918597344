import React, { ReactNode } from "react";
import { ReactComponent as LogoIcon } from "./assets/sign-form-logo.svg";
import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string;
    title: ReactNode;
    subtitle?: ReactNode;
    isPreTitle?: boolean;
    icon?: ReactNode;
}

const AuthorizationFormHeader: React.FC<Props> = ({
    title,
    subtitle,
    isPreTitle,
    className,
    icon,
}) => {
    return (
        <div className={classNames(className, "AuthorizationFormHeader")}>
            <div className="AuthorizationFormHeader__Logo">
                <span className="AuthorizationFormHeader__LogoIcon">{icon ? icon : <LogoIcon />}</span>
            </div>
            <h2 className="AuthorizationFormHeader__Title">{title}</h2>
            <p
                className={classNames({
                    AuthorizationFormHeader__Subtitle: true,
                    "AuthorizationFormHeader__Subtitle--top": isPreTitle,
                })}
            >
                {subtitle}
            </p>
        </div>
    );
};

export default AuthorizationFormHeader;
