import React from "react";
import Typography from "components/Typography";
import SessionSpeaker from "../SessionSpeaker";
import classNames from "classnames";
import { SpeakerType } from "types/interfaces";
import './index.scss';


type Props = {
    speakers: SpeakerType[],
    vertical?: boolean,
    showSpeakerdescription?: boolean,
    speakersTitle?: string,
    className?: string,
    avatarSize?: "big" | "small"
}

const SessionSpeakers: React.FC<Props> = ({
    speakers,
    className,
    vertical,
    speakersTitle = "Спикеры",
    showSpeakerdescription,
    avatarSize
}) => {

    const renderSpeakers = speakers?.map((el, index) => {
        return (
            <SessionSpeaker
                key={index}
                showSpeakerdescription={showSpeakerdescription}
                data={el}
                avatarSize={avatarSize}
            />
        )
    })

    return (
        <div className={classNames("SessionSpeakers", className, {
            "SessionSpeakers--vertical": vertical,
        })}>
            <Typography
                className="SessionSpeakers__Title"
                color="gray"
                component="span"
                size="sm"
                weight="bold"
            >
                {speakersTitle}
            </Typography>

            <div className="SessionSpeakers__wapper">
                {renderSpeakers}
            </div>
        </div>
    )
}

export default SessionSpeakers;