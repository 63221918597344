import React, { useMemo } from "react";
import { FormInput } from "@/components/Inputs";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import FormPasswordRules from "@/components/FormPasswordRules";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { FormikData } from "@/types/formik";
import "./index.scss";

const CreatePassword = ({ formik, nextStep }: { formik: FormikData<any>; nextStep?: () => void }) => {
    const isValid = useMemo(() => {
        return !(hasError(formik, "password", false) || hasError(formik, "password_confirmation", false));
    }, [formik]);

    return (
        <>
            <div className="CreatePassword__Inputs">
                <FormPasswordRules>
                    <FormikErrorWrap formik={formik} name="password">
                        <FormInput
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Введите новый пароль"
                            label="Придумайте пароль"
                            value={formik.values.password}
                            error={hasError(formik, "password")}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FormikErrorWrap>
                </FormPasswordRules>

                <FormikErrorWrap formik={formik} name="password_confirmation">
                    <FormInput
                        type="password"
                        id="password_confirmation"
                        name="password_confirmation"
                        placeholder="Новый пароль ещё раз"
                        label="Повторите пароль, чтобы не ошибиться"
                        value={formik.values.password_confirmation}
                        error={hasError(formik, "password_confirmation")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </FormikErrorWrap>
            </div>

            <Tooltip
                className="CreatePassword__Tooltip"
                tooltip={"Необходимо заполнить все поля"}
                disabled={isValid}
            >
                <Button
                    className="CreatePassword__Btn"
                    tag="button"
                    BtnType={nextStep ? "button" : "submit"}
                    text={nextStep ? "Далее" : "Готово"}
                    onClick={nextStep}
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                    isLoading={formik.isSubmitting}
                />
            </Tooltip>
        </>
    );
};

export default CreatePassword;
