import React from "react";
import classNames from "classnames";
import "./index.scss";

export type RadioProps = {
    name?: string;
    id?: string;
    className?: string;
    checked?: boolean;
    value?: any;
    text?: string;
    error?: boolean;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => void;
};

const Radio: React.FC<RadioProps> = ({ name, id, className, onChange, onBlur, checked, value, text, error }) => {
    return (
        <div
            className={classNames(className, "Radio", {
                "Radio--Error": error,
            })}
        >
            <label
                className={classNames("Radio__Box", {
                    "Radio__Box--active": checked,
                })}
                htmlFor={id}
            ></label>
            <input
                className="Radio__Input"
                type="radio"
                name={name}
                id={id}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                checked={checked}
            />
            <label
                className={classNames("Radio__Description", {
                    "Radio__Description--active": checked,
                })}
                htmlFor={id}
            >
                {text}
            </label>
        </div>
    );
};

export default Radio;
