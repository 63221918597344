import React from "react";
import Button from "@/components/Button";
import RefreshPasswordImage from "../assets/rocket_icon.png";
import Typography from "@/components/Typography";
import "./index.scss";

type Props = {
    disabled: boolean;
    isLoading?: boolean;
    onRecoveryPassword: () => void;
};

const RecoverPasword: React.FC<Props> = ({
    disabled,
    isLoading,
    onRecoveryPassword,
}) => {
    return (
        <div className="RecoverPasword">
            <div className="RecoverPasword__Image">
                <img src={RefreshPasswordImage} alt="Восстановление" />
            </div>
            <Typography
                className="RecoverPasword__Title"
                component="span"
                size="lg"
                weight="semiBold"
                color="dark"
            >
                Восстановление
            </Typography>
            <Typography
                className="RecoverPasword__Text"
                component="p"
                size="xs"
                weight="regular"
                color="dark"
                isCentered
            >
                На вашу почту будет отправлено письмо со ссылкой
                <br /> для восстановления доступа
            </Typography>
            <Button
                className="RecoverPasword__Btn"
                tag="button"
                gradient
                type="colored"
                size="lg"
                TextSize="sm"
                TextWeight="semiBold"
                text="Отправить письмо"
                disabled={disabled}
                isLoading={isLoading}
                onClick={onRecoveryPassword}
            />
        </div>
    );
};

export default RecoverPasword;
