import React from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { TIME_COUNTDOWN } from "../constans";
import RefreshSuccessPasswordImage from "./../assets/send-succes-icon.png";
import Typography from "@/components/Typography";
import Timer from "@/components/Timer";
import "./index.scss";

type Props = {
    disabled: boolean;
    userEmail: string;
    onResend: () => void;
    now: number;
};

const RecoverPaswordTimer: React.FC<Props> = ({
    disabled,
    userEmail,
    onResend,
    now,
}) => {
    return (
        <div className="RecoverPasword">
            <div className="RecoverPasword__Image">
                <img
                    src={RefreshSuccessPasswordImage}
                    alt="Письмо отправлено"
                />
            </div>
            <Typography
                className="RecoverPasword__Title"
                component="span"
                size="lg"
                weight="semiBold"
                color="dark"
            >
                Письмо отправлено
            </Typography>
            <Typography
                className="RecoverPasword__Text"
                component="p"
                size="xs"
                weight="regular"
                color="dark"
                isCentered
            >
                Мы отправили вам письмо на <b>{userEmail}</b>
                <br />со ссылкой для подтверждения смены пароля
            </Typography>
            <Countdown
                date={now + TIME_COUNTDOWN * 1000}
                renderer={(props: CountdownRenderProps) => (
                    <Timer
                        isDisabled={disabled}
                        isCompleted={props.completed}
                        minutes={props.minutes}
                        seconds={props.seconds}
                        onResend={onResend}
                    />
                )}
            />
        </div>
    );
};

export default RecoverPaswordTimer;
