import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { ReactComponent as IconWait } from "./icon-wait.svg";
import { ReactComponent as IconReject } from "./icon-reject.svg";
import "./index.scss";

export const TariffStatus: React.FC<
    PropsWithChildren<{
        className?: string;
        status: "wait" | "reject";
    }>
> = (props) => {
    const { className, status, children } = props;
    return (
        <div className={classNames(className, "TariffStatus", {
            [`TariffStatus--${status}`] : status,
        })}>
            <div className="TariffStatus__Icon">
                {status === "wait" && <IconWait />}
                {status === "reject" && <IconReject />}
            </div>
            <div className="TariffStatus__Content">
                <div className="TariffStatus__ContentInner">{children}</div>
            </div>
        </div>
    );
};
