import { useEffect, useState } from "react";

import { FormikData } from "@/types/formik";
import { useDebounceValue } from "@/hooks/useDebounceValue";

export const useFormikDebounceEmail = (formik: FormikData<any>) => {
    const [email, setEmail] = useState(formik.values.email ?? "");
    const debouncedEmail = useDebounceValue(email, 300);
    useEffect(() => {
        formik.setFieldValue("email", debouncedEmail);
    }, [debouncedEmail]);
    useEffect(() => {
        setEmail(formik.values.email);
    }, [formik.values.email]);
    return [email, setEmail] as const;
};
