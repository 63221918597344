import React, { createContext, useState, PropsWithChildren } from "react";

type BannerContextType = {
    isBannerActive: boolean,
    setIsBannerActive: (bannerStatus: boolean) => void,
}

const BannerContext = createContext<BannerContextType>({
    isBannerActive: false,
    setIsBannerActive: (bannerStatus) => { },
})

const BannerProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isBannerActive, setIsBannerActive] = useState<boolean>(false);

    return (
        <BannerContext.Provider value={{ isBannerActive, setIsBannerActive }}>
            {children}
        </BannerContext.Provider>
    )
}

export { BannerContext, BannerProvider }