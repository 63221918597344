import React, { useEffect, useRef, useState } from "react";
import transformObjectsList from "@/utils/transformObjectsList";
import { AsyncSelectInput } from "@/components/RegistrationSelect";
import { searchCities } from "@/api";
import { Tooltip } from "@/components/Tooltip";
import { debouncePromise } from "@/utils/promise";
import axios from "axios";

const keyMapping = {
    id: "value",
    title: "label",
};

const debouncedSearchCities = debouncePromise(searchCities, 300);

export type Props = {
    className?: string;
    name?: string;
    id?: string;
    label?: string;
    value?: any;
    placeholder?: string;
    error?: boolean;
    countryId?: any;
    onSelect?: (city: any) => void;
    onBlur?: () => void;
};

const InputSelectCity: React.FC<Props> = ({
    className,
    name,
    id,
    label,
    value,
    placeholder,
    onSelect,
    onBlur,
    error,
    countryId,
}) => {
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const cancelingRef = useRef<any>(null);
    const loadOptions = async (inputValue: string) => {
        if (countryId) {
            try {
                cancelingRef.current?.cancel();
                cancelingRef.current = axios.CancelToken.source();
                const response = await debouncedSearchCities(
                    countryId,
                    inputValue,
                    cancelingRef.current.token,
                );
                const cities = response.data.data;
                const formattedList = transformObjectsList(cities, keyMapping);
                return formattedList;
            } catch (err: any) {
                console.log(err.message);
                return [];
            }
        }
        return [];
    };

    const handleChangeCity = (option: any) => {
        onSelect?.(option.label);
        setSelectedValue(option);
    };

    useEffect(() => {
        if (value) {
            setSelectedValue({
                label: value,
                value,
            });
            onSelect?.(value);
        }
    }, [value]);

    return (
        <Tooltip disabled={!!countryId} tooltip="Выберите сначала страну">
            <AsyncSelectInput
                className={className}
                loadOptions={loadOptions}
                isAsync
                id={id}
                name={name}
                isDisabled={!countryId}
                placeholder={placeholder}
                label={label}
                value={selectedValue}
                isError={error}
                noOptionsMessage={({ inputValue }: any) =>
                    inputValue
                        ? "Ничего не найдено"
                        : "Начните вводить название города"
                }
                onBlur={onBlur}
                onChange={handleChangeCity}
            />
        </Tooltip>
    );
};

export default InputSelectCity;
