import React from "react";
import Select, {
    GroupBase,
    SelectComponentsConfig,
    type Props,
} from "react-select";
import classNames from "classnames";
import { selectStyles } from "./customStyle";
import "./index.scss";

type SelectInputProps = Props & {
    className?: string;
    name?: string;
    id?: string;
    label?: string;
    withDropdownIcon?: boolean;
    width?: string;
    isError?: boolean;
    customComponents?: Partial<
        SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>
    >;
};

export const SelectInput: React.FC<SelectInputProps> = ({
    className,
    name,
    id,
    label,
    isError = false,
    ...restProps
}) => {
    return (
        <div className={classNames(className, "RegistrationSelect")}>
            {label && (
                <label className="RegistrationSelect__Label" htmlFor={id}>
                    {label}
                </label>
            )}
            <Select
                className={classNames({ "RegistrationSelect--Error": isError })}
                classNamePrefix="RegistrationSelect"
                id={id}
                name={name}
                styles={selectStyles}
                {...restProps}
            />
        </div>
    );
};
