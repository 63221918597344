import React from "react";
import { PresentationLinksType } from "@/types/interfaces";
import { ReactComponent as PDFIcon } from "./assets/pdf_icon.svg";
import { ReactComponent as PresentationIcon } from "./assets/ic_outlined_tray_down.svg";
import * as Popover from "@radix-ui/react-popover";
import "./index.scss";

type Props = {
    presentationData: PresentationLinksType[],
}

const SessionPresentation: React.FC<Props> = ({ presentationData }) => {
    const renderElement = presentationData?.map((el, index) => {
        return (
            <li key={index}>
                <a className="SessionPresentation__Link" href={el.link} target="_blank" rel="noreferrer">
                    <PDFIcon />
                    <span>{el.text}</span>
                </a>
            </li>
        )
    });

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className="SessionPresentation__Trigger" type="button">
                    <PresentationIcon />Скачать презентацию
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content className="SessionPresentation__Content" sideOffset={12} hideWhenDetached>
                    <div className="SessionPresentation__ContentInner">
                        <h4 className="SessionPresentation__ContentTitle">Скачать</h4>
                        <ul className="SessionPresentation__ContentList">
                            {renderElement}
                        </ul>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}

export default SessionPresentation;