import React from "react";
import "./index.scss";

type Props = {
    icon: JSX.Element;
    onClick: () => void;
};

const ShareItem: React.FC<Props> = ({ icon, onClick }) => {
    return (
        <button className="ShareItem" onClick={onClick}>
            {icon}
        </button>
    );
};

export default ShareItem;
