import React from 'react';
import { useInView } from 'react-intersection-observer';
import SkeletonImg from '@/components/SkeletonImg';
import Typography from '@/components/Typography';
import './index.scss';

type Props = {
    photo_url: string;
    name: string;
    surname: string;
    description: string;
}

const ParticipantCard: React.FC<Props> = ({ photo_url, name, surname, description }) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true
    });

    return (
        <>
            <div className='ParticipantCard' ref={ref}>
                <div className='ParticipantCard__ImgContainer'>
                    <SkeletonImg
                        className='ParticipantCard__Img'
                        isInView={inView}
                        src={photo_url}
                        alt={name}
                        hash="L84fKvS]SijZ.lScWFjax]WUafju"
                    />
                </div>
                <div className='ParticipantCard__Body'>
                    <Typography className='ParticipantCard__BodyTitle' component='span' size='lg' weight='semiBold' color='light'>
                        {name}
                        <br />
                        {surname}
                    </Typography>
                    <Typography size='sm' color='light' opacity={0.4} component='p' weight='regular'>
                        {description}
                    </Typography>
                </div>
            </div>
        </>
    )
}

export default ParticipantCard;