import React from 'react';
import { ReactComponent as ConferenceIcon } from '@/assets/icons/conference-icon-mobile.svg';
import { ReactComponent as CompetitionIcon } from '@/assets/icons/competition-icon-mobile.svg';
import { ReactComponent as ProgramsIcon } from '@/assets/icons/programs-icon-mobile.svg';
import { ReactComponent as LinkIcon } from '@/assets/icons/menu-link-icon-mobile.svg';
import routes from '@/routes';

const useMenu = () => {
    const MENU_NAV_ITEMS: {
        text: string,
        icon: React.ReactNode,
        route: string,
        isHashLink: boolean,
    }[] = [
        {
            text: 'О конференции',
            icon: <ConferenceIcon />,
            route: routes.conference,
            isHashLink: false,
        },
        {
            text: 'Программа',
            icon: <ProgramsIcon />,
            route: routes.programAnchor,
            isHashLink: true,
        },
        {
            text: 'Конкурс',
            icon: <CompetitionIcon />,
            route: routes.contest,
            isHashLink: false,
        },
    ]

    return {
        MENU_NAV_ITEMS,
        LinkIcon,
    }
}

export default useMenu;