import React from 'react';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import scrollToSection from "@/utils/scrollToSection";
import routes from '@/routes';
import useMedia from '@/hooks/useMedia';
import { FAQ_HASH_ID } from '@/constants';
import './index.scss';

type Props = {
    className?: string;
}

const TariffAccommodationInfo: React.FC<Props> = ({ className }) => {
    const { isMobile } = useMedia();

    return (
        <div className={classNames(className, "TariffAccommodationInfo__Wrapper")}>
            <p className="TariffAccommodationInfo__Text">{'Вы также сможете остаться в\u00A0кампусе на ночь\nили приехать к\u00A0нам за день до конференции.'}</p>
            <div className="TariffAccommodationInfo__Subtext">
                <p>*Проживание в кампусе оплачивается дополнительно</p>
                < HashLink
                    className="TariffAccommodationInfo__Link"
                    to={routes.FAQAnchor}
                    scroll={() => scrollToSection(FAQ_HASH_ID, isMobile ? 100 : 200)}
                >
                    Подробнее
                </HashLink >
            </div>
        </div>
    )
}

export default TariffAccommodationInfo;