import React from "react";
import ParticipantsList from "@/components/ParticipantsList";
import Container from '@/components/Container';
import Headline from "@/components/Headline";
import { useJury } from "@/hooks/useParticipants";
import { JURY_HASH_ID } from "@/constants";
import './index.scss';

const CompetitionJury: React.FC = () => {
    const queryResults = useJury();

    return (
        <section className="CompetitionJury" id={JURY_HASH_ID}>
            <Container className="CompetitionJury__Container">
                <Headline className="CompetitionJury__Header" level="h2" size="xlg" theme="light" isCentered>
                    Жюри конкурса
                </Headline>
                <ParticipantsList swiperId="CompetitionJury" slidesOffsetBefore={16} {...queryResults}/>
            </Container>
        </section>
    )
}

export default CompetitionJury;