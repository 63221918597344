import React from "react";
import { HashLink } from "react-router-hash-link";
import routes from "@/routes";
import "./index.scss";

const ToSignUp: React.FC = () => {
    return (
        <div className="ToSignUp">
            <span className="ToSignUp__Text">Нет аккаунта?</span>
            <HashLink
                className="ToSignUp__Link"
                to={routes.signupAnchor}
                scroll={(el) => {
                    setTimeout(() => {
                        el.scrollIntoView({
                            block: "end",
                            behavior: "instant",
                        });
                    }, 500);
                }}
            >
                Зарегистрироваться
            </HashLink>
        </div>
    );
};

export default ToSignUp;
