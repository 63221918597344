export type FormatType = "online" | "offline";
export type LegalType = "individual" | "legal";
export type ActivityType = "work" | "study" | "no_activity";
export enum ActivityTypeEnum  {
    Work = "work",
    Study = "study",
    NoActivity = "no_activity",
}
export type TariffType = "common" | "free" | "vip" | "from_sber" | "from_education";
export type StatusType = "under_consideration" | "confirm" | "refused";
export type RegistrationData = {
    name: string;
    surname: string;
    email: string;
    password: string;
    password_confirmation: string;
    agree_processing_of_personal_data: boolean;
    agree_receive_promotional_and_informational_mailings: boolean;
    format: FormatType;
    tariff: TariffType;
    country_id: number;
    city: string;
    legal_type?: LegalType;
    phone?: string;
    place_of_study?: string;
    speciality?: string;
    place_of_work?: string;
    position?: string;
    transport?: string;
    car_brand?: string;
    car_state_number?: string;
    is_planning_to_stay_at_the_hotel?: boolean;
    promo_code_id?: number;
    activity: Array<ActivityType>;
};
