import React from "react";
import useMedia from "@/hooks/useMedia";
import * as Popover from "@radix-ui/react-popover";
import Button from "@/components/Button";
import { ReactComponent as SignInIcon } from "./assets/sign-in-icon.svg";
import routes from "@/routes";
import { NavLink } from "react-router-dom";
import Typography from "@/components/Typography";
import { HashLink } from "react-router-hash-link";
import "./index.scss";

const MENU = [
    {
        text: "Войти в аккаунт",
        route: routes.signin,
        isHashLink: false,
    },
    {
        text: "Зарегистрироваться",
        route: routes.signupAnchor,
        isHashLink: true,
    },
];

const AuthorizationBtn: React.FC = () => {
    const { isDesktop } = useMedia();

    const renderItem = MENU.map((el, index) => {
        return el.isHashLink ?
                    <HashLink
                        key={index}
                        className="AuthorizationBtn__Link"
                        to={el.route}
                        scroll={(el) => {
                            setTimeout(() => {
                                el.scrollIntoView({
                                    block: "end",
                                    behavior: "instant",
                                });
                            }, 500);
                        }}
                    >
                        <Typography size="sm" weight="regular" color="dark">
                            {el.text}
                        </Typography>
                    </HashLink>
                :
                    <NavLink
                        key={index}
                        className="AuthorizationBtn__Link"
                        to={el.route}
                    >
                        <Typography size="sm" weight="regular" color="dark">
                            {el.text}
                        </Typography>
                    </NavLink>
        });

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                {isDesktop ? (
                    <Button
                        className="AuthorizationBtn__DesktopBtn"
                        tag="button"
                        text="Войти"
                        size="sm"
                        TextSize="sm"
                        TextWeight="regular"
                    />
                ) : (
                    <button className="AuthorizationBtn__MobileBtn">
                        <SignInIcon />
                    </button>
                )}
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    className="AuthorizationBtn__Content"
                    sideOffset={8}
                    collisionPadding={16}
                >
                    {renderItem}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

export default AuthorizationBtn;
