import React, { useEffect, useRef, useState } from "react";
import Avatar from "@/components/Avatar";
import { ReactComponent as IconReset } from "./assets/reset-avatar.svg";
import { ReactComponent as IconClose } from "./assets/ic_outlined_cross_large.svg";
import ImageInput from "@/components/ImageInput";
import classNames from "classnames";
import "./index.scss";
import useMedia from "@/hooks/useMedia";
import { useClickAway } from "react-use";

export type AvatarInputProps = {
    src?: string;
    userName?: string;
    onChange?: (file?: File | null) => void;
};

export const AvatarInput: React.FC<AvatarInputProps> = ({
    src,
    userName,
    onChange,
}) => {
    const { isDesktop } = useMedia();

    const [file, setFile] = useState<File | null>();
    const [fileSrc, setFileSrc] = useState<string>();
    const [isShowAbortBtn, setIsShowAbortBtn] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    useClickAway(ref, () => {
        setIsShowAbortBtn(false);
    });

    useEffect(() => {
        setFileSrc(undefined);
        if (file) {
            const newFileSrc = URL.createObjectURL(file);
            setFileSrc((prevFileSrc) => {
                if (prevFileSrc) {
                    URL.revokeObjectURL(prevFileSrc);
                }
                return newFileSrc;
            });
        } else {
            setFile(undefined);
            setFileSrc(undefined);
        }
    }, [file]);

    const handleChangeImage = (newFile?: File | null) => {
        setFile(newFile);
        onChange?.(newFile);
    };

    const handleClickResetFile = () => {
        handleChangeImage(null);
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDesktop || !file) return;
        e.stopPropagation();

        setIsShowAbortBtn((prev) => !prev);
    };

    return (
        <div className="AvatarInput">
            <div
                ref={ref}
                className={classNames("AvatarInput__PreviewWrap", {
                    "AvatarInput__PreviewWrap--ShowActions": isShowAbortBtn,
                })}
                onClick={handleClick}
            >
                <Avatar
                    className={classNames("AvatarInput__Preview", {
                        "AvatarInput__Preview--HasFile": file,
                    })}
                    src={fileSrc || src}
                    name={userName}
                />
                {file && (
                    <div
                        className="AvatarInput__Reset"
                        onClick={handleClickResetFile}
                    >
                        <IconReset />
                    </div>
                )}
                {!isDesktop && isShowAbortBtn && file && (
                    <div className="AvatarInput__Close" onClick={handleClick}>
                        <IconClose />
                    </div>
                )}
            </div>
            <ImageInput value={file} onChange={handleChangeImage} />
        </div>
    );
};
