const routes = {
    conference: "/",
    contest: "/contest",

    // Auth
    signin: "/signin",
    forgetPassword: "/signin/forget_password",
    resetPassword: "/reset_password/:recovery_token",

    signup: "/signup",
    signupAnchor: "/#choose_tariff",
    onlineSignup: "/signup/online",
    onCampusSignup: "/signup/on_campus",
    vipSignup: "/signup/vip",
    fromEducationSignup: "/signup/from_education",
    fromSberSignup: "/signup/from_sber",

    // User Profile
    profile: "/profile",
    profileTariffs: "/profile/tariffs",
    profileCertificates: "/profile/certificates",
    profileRefund: "/profile/refund",
    profileChangePassword: "/profile/change-password",
    profileChangeTariffCommon: "/profile/tariffs/change-common",
    profileChangeTariffEducation: "/profile/tariffs/change-education",
    profileChangeTariffSber: "/profile/tariffs/change-sber",

    // Programs
    programAnchor: "/#program",

    // FAQ
    FAQAnchor: "/#faq",

    // chat
    chatModeration: "/chat-moderation",
    questionsModeration: "/questions-moderation",
    moderatedQuestions: "/moderated-questions-for-stage",

    // broadcast
    broadcast: "/broadcast",
} as const;

export default routes;
