import mapObject from "map-obj";

const transformObjectsList = (objectsList, keyMapping) => {
    return objectsList.map((obj) => {
        const { country_id, ...rest } = obj;

        return mapObject(rest, (key, value) => [keyMapping[key] || key, value]);
    });
};

export default transformObjectsList;
