import React from "react";
import Headline from "@/components/Headline";
import VideoPlayer from "@/components/VideoPlayer";
import { VIDEO_ARCHIVE } from "@/constants/videoArchive";
import { ReactComponent as SliderNavBtn } from "@/assets/icons/arrow-right.svg";
import { VIDEO_ARCHIVE_HASH_ID } from "@/constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./index.scss";

const VideoArchive: React.FC = () => {
    const renderSlides = VIDEO_ARCHIVE?.map((el, index) => {
        return (
            <SwiperSlide key={index} className="VideoArchive__SlideCustom">
                <VideoPlayer videoLink={el.videoLink} />
            </SwiperSlide>
        );
    });

    const swiperParams = {
        slidesPerView: 1,
        loop: true,
        modules: [Navigation, Pagination],
        pagination: {
            el: ".VideoArchive__SliderPagination",
            clickable: true,
        },
        navigation: {
            prevEl: ".VideoArchive__Slider-to-Prev",
            nextEl: ".VideoArchive__Slider-to-Next",
        },
    };

    return (
        <section id={VIDEO_ARCHIVE_HASH_ID} className="VideoArchive">
            <Headline
                className="VideoArchive__Headline"
                level="h2"
                size="xlg"
                theme="light"
                isCentered
            >
                Как это было
            </Headline>

            <Swiper className="VideoArchive__Swiper" {...swiperParams}>
                {renderSlides}
            </Swiper>
            <div className="VideoArchive__SliderPagination"></div>
            <div className="VideoArchive__SliderBtns">
                <div className="VideoArchive__SliderPrev VideoArchive__Slider-to-Prev">
                    <SliderNavBtn />
                </div>
                <div className="VideoArchive__SliderNext VideoArchive__Slider-to-Next">
                    <SliderNavBtn />
                </div>
            </div>
        </section>
    );
};

export default VideoArchive;
