import React from "react";
import { toastNotifications } from "@/components/Toastify";
import { MESSAGES } from "@/constants/messages";
import { ReactComponent as VkIcon } from '@/assets/icons/vk.svg';
import { ReactComponent as TelegramIcon } from '@/assets/icons/telegram.svg';
import { ReactComponent as VkFooterIcon } from '@/assets/icons/vk-footer.svg';
import { ReactComponent as TelegramFooterIcon } from '@/assets/icons/telegram-footer.svg';

const useShare = () => {
    const link = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('VIII конференция СберУниверситета\n**Больше чем обучение**\n25 октября 2024 года');

    const shareVK = () =>
        window.open(`https://vk.com/share.php?url=${link}/&title=${text}`, "_blank");
    const shareTG = () =>
        window.open(`https://t.me/share/url?url=${link}&text=${text}`, "_blank");

    const openEdTech = () =>
        window.open(`https://t.me/edutech_club`, "_blank");

    const openVK = () =>
        window.open(`https://vk.com/sber_university`, "_blank");

    const copyURL = () => {
        navigator.clipboard.writeText(window.location.href);
        toastNotifications.success(MESSAGES.LINK_COPIED);
    };

    const VISTI_LINKS_HEADER = [
        {
            text: 'СберУниверситет в Telegram',
            link: "https://t.me/edutech_club",
            icon: <TelegramIcon />,
            action: openEdTech,
        },
        {
            text: 'СберУниверситет в ВКонтакте',
            link: "https://vk.com/sber_university",
            icon: <VkIcon />,
            action: openVK,
        },
    ]

    const VISTI_LINKS_FOOTER = [
        {
            text: 'СберУниверситет в Telegram',
            link: "https://t.me/edutech_club",
            icon: <TelegramFooterIcon />,
            action: openEdTech,
        },
        {
            text: 'СберУниверситет в ВКонтакте',
            link: "https://vk.com/sber_university",
            icon: <VkFooterIcon />,
            action: openVK,
        },
    ]

    return {
        shareVK,
        shareTG,
        copyURL,
        VISTI_LINKS_HEADER,
        VISTI_LINKS_FOOTER,
    }
}

export default useShare;
