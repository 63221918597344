import * as yup from "yup";
import { PASSWORD_REQUIREMENT } from "@constants/validations";
import { errors } from "@/constants/messages";

export const resetPasswordSchema = yup.object({
    userEmail: yup.string().email().required(),
});

export const newPasswordSchema = yup.object({
    newPassword: yup.string().matches(PASSWORD_REQUIREMENT, errors.password).required(),
    confirmPassword: yup
        .string()
        .required()
        .oneOf([yup.ref("newPassword")], errors.password_confirmation),
});
