import React from 'react';
import Button from "@/components/Button";
import useMedia from "@/hooks/useMedia";
import ParticipantCard from './ParticipantCard';
import {ReactComponent as PlusIcon} from './assets/plus-icon.svg';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import './index.scss';

const swiperParams = {
    spaceBetween: 16,
    centeredSlides: true,
    centeredSlidesBounds: true,
};

type Props = UseInfiniteQueryResult<any[], Error> & {
    swiperId: string;
    className?: string;
    slidesOffsetBefore?: number;
    slidesPerView?: number;
}

const ParticipantsList: React.FC<Props> = ({
    swiperId,
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    className,
    slidesOffsetBefore,
    slidesPerView = 1.4,
}) => {
    const {isMobile} = useMedia();

    const renderCards = data?.map((el, index) => {
        const card = (
            <ParticipantCard
                key={index}
                photo_url={el.photo_url}
                name={el.name}
                surname={el.surname}
                description={el.description}
            />
        )
        if (isMobile) {
            return (
                <SwiperSlide key={index}>
                    {card}
                </SwiperSlide>
            )
        }
        return card
    });

    return (
        <section className={classNames("ParticipantsList", className)}>
            {isMobile ?
                <>{!isLoading &&
                    <Swiper
                        id={swiperId}
                        slidesOffsetBefore={slidesOffsetBefore}
                        slidesPerView={slidesPerView}
                        {...swiperParams}
                        onSlideChange={(swiper) => {
                            if (data && swiper.realIndex > data.length - 3 && hasNextPage && !isFetchingNextPage) {
                                fetchNextPage();
                            }
                        }}
                    >
                        {renderCards}
                        {isFetchingNextPage && (
                            <SwiperSlide>
                                <LoadingSpinner className='ParticipantsList__LoadingSpinner'/>
                            </SwiperSlide>
                        )}
                    </Swiper>}
                </>
                :
                <>
                    <div className='ParticipantsList__cards'>
                        {renderCards}
                    </div>
                    {hasNextPage && (
                        <Button
                            className="ParticipantsList__Button"
                            text="Показать еще"
                            iconRight={<PlusIcon />}
                            size='sm'
                            TextSize='sm'
                            TextWeight='regular'
                            BtnType='button'
                            type='bordered'
                            tag='button'
                            onClick={fetchNextPage}
                            isLoading={isFetchingNextPage}
                        />
                    )}
                </>
            }
        </section>
    )
};

export default ParticipantsList;